import IconDiv from 'components/Common/IconDiv';
import { Col, Div, Row, Text } from 'differoute-styles';
import Pagination from 'components/Pagination';
import React, { useState } from 'react';

function FaqPresenter({ list, pages, filters, setFilters }) {
  const [isOpen, setIsOpen] = useState('');

  const onClick = (item, index) => {
    const currentEl = document.getElementsByClassName('faq')[index];
    const faqList = Array.from(document.getElementsByClassName('faq'));
    faqList.forEach((h) => {
      if (h) {
        h.style.height = `${currentEl.children[0].offsetHeight + 60}px`;
      }
    });
    if (item.id !== isOpen) {
      setIsOpen(item.id);
      currentEl.style.height = `${currentEl.scrollHeight}px`;
    } else {
      setIsOpen('');
      currentEl.style.height = `${currentEl.children[0].offsetHeight + 60}px`;
    }
  };

  return (
    <Div pt={20}>
      <Col gap={20}>
        {list &&
          list.map((item, index) => (
            <Div
              key={item.id}
              borderRadius={10}
              padding="30px"
              height={95}
              mHeight={84}
              border={{ color: 'g200' }}
              cursor="pointer"
              onClick={() => onClick(item, index)}
              className="faq"
              style={{ overflow: 'hidden' }}
              transition="0.3s linear !important"
            >
              <Row justifyContent="space-between" alignItems="center">
                <Text type="h5" mType="h6">
                  {item.question}
                </Text>
                <IconDiv
                  padding="0"
                  mWidth={8}
                  name="defaultArrow"
                  pathFill="primary"
                  transition="0.3s linear !important"
                  transform={
                    isOpen === item.id ? 'rotate(-90deg)' : 'rotate(90deg)'
                  }
                />
              </Row>
              <Div
                backgroundColor={isOpen === item.id ? 'g200' : 'transparent'}
                width="100%"
                height={1}
                margin="20px 0"
              />
              <Div open={isOpen === item.id}>
                <Div dangerouslySetInnerHTML={{ __html: item.answer }} />
              </Div>
            </Div>
          ))}
        <Pagination
          mt={40}
          pages={pages}
          filters={filters}
          setFilters={setFilters}
        />
      </Col>
    </Div>
  );
}

export default FaqPresenter;
