import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { adminRoutes } from 'data/admin';
import { Div, hexToRGB, ImageComponent, Text } from 'differoute-styles';
import logo from 'assets/icon/logo_black.svg';
import axios from 'axios';

function SideNavigation(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!sessionStorage.getItem('adminTokenKey')) {
      navigate('/admin/login');
    } else if (
      sessionStorage.getItem('adminTokenKey') &&
      window.location.pathname.includes('admin')
    ) {
      axios.defaults.headers.common.Authorization = `Bearer ${sessionStorage.getItem(
        'adminTokenKey',
      )}`;
      setLoading(false);
    }
  }, []);

  return (
    <Div
      padding="60px 0"
      boxShadow={{ color: hexToRGB('g700', 0.04), y: 4, r: 16 }}
      width={180}
      position="fixed"
      height="100vh"
      backgroundColor="white"
      zIndex={10}
    >
      <ImageComponent src={logo} height={70} margin="auto" />
      <Div mt={40}>
        {adminRoutes.map((admin, index) => (
          <Div key={`admin_${index}`}>
            <Div padding="0 10px 0 20px">
              <Text color="g400" padding="6px 20px" type="b3" mType="b3">
                {admin.name}
              </Text>
            </Div>
            {admin.subRoutes.map((sub, idx) => (
              <Div
                key={`sub_${idx}`}
                padding="0 10px 0 20px"
                cursor="pointer"
                onClick={() => navigate(`${sub.pathname}`)}
                hover
                borderRight={{
                  color: 'primary',
                  width:
                    location.pathname.split('/')[2] ===
                    sub.pathname.split('/')[0]
                      ? 2
                      : 0,
                }}
              >
                <Text
                  type={
                    (location.pathname.split('/')[2] ===
                      sub.pathname.split('/')[0] &&
                      'h8') ||
                    'b2'
                  }
                  mType={
                    (location.pathname.split('/')[2] ===
                      sub.pathname.split('/')[0] &&
                      'h8') ||
                    'b2'
                  }
                  color={
                    (location.pathname.split('/')[2] ===
                      sub.pathname.split('/')[0] &&
                      'primary') ||
                    'g600'
                  }
                  padding="12px 30px"
                  borderRadius={6}
                  backgroundColor={
                    (location.pathname.split('/')[2] ===
                      sub.pathname.split('/')[0] &&
                      'rgba(0, 181, 239, 0.1)') ||
                    null
                  }
                >
                  {sub.name}
                </Text>
              </Div>
            ))}
          </Div>
        ))}
      </Div>
    </Div>
  );
}

export default SideNavigation;
