import { Div, ImageComponent, Row, Span, Text } from 'differoute-styles';
import React from 'react';
import jact from 'assets/graphic/footer_jact.png';
import { useCookies } from 'react-cookie';
import footerLang from 'data/lang/footer';
import IconDiv from 'components/Common/IconDiv';

function Footer(props) {
  const [cookies] = useCookies();

  return (
    <Div
      width="100vw"
      transform="translateX(-50%)"
      mTransform="unset"
      ml="50%"
      mMl="unset"
      backgroundColor="primaryOpacity40"
      padding="80px 20px 40px"
      mPadding="60px 20px 40px"
    >
      <Div maxWidth={1240} mWidth="100%" margin="auto">
        <Row gap={40} mGap={16} justifyContent="center">
          <IconDiv name="logo" padding="0" mHeight={24} />
          <ImageComponent src={jact} height={40} mHeight={24} />
          <IconDiv name="ppi" padding="0" mHeight={24} />
        </Row>
        <Div mt={80}>
          <Text type="h6" mType="h8" textAlign="center">
            {footerLang[cookies.language].name}
          </Text>
          <Text type="b2" mType="b2" textAlign="center" mt={4}>
            {footerLang[cookies.language].address}
          </Text>
          <Row mt={20} gap={10} justifyContent="center" mFlexDirection="column">
            <Row justifyContent="center" alignItems="center" gap={10}>
              <Text type="b2" mType="b2">
                <Span type="h8">TEL&nbsp;</Span>
                {footerLang[cookies.language].tel}
              </Text>
              <Span height={16} width={1} backgroundColor="g300" />
              <Text type="b2" mType="b2">
                <Span type="h8">FAX&nbsp;</Span>
                {footerLang[cookies.language].fax}
              </Text>
            </Row>
            <Span
              height={16}
              width={1}
              backgroundColor="g300"
              mDisplay="none"
            />
            <Row justifyContent="center">
              <Text type="b2" mType="b2">
                <Span type="h8">E-MAIL&nbsp;</Span>
                <a
                  href="mailto:shoppinglounge@tothepp.com"
                  style={{ color: '#3EB9FF', textDecoration: 'underline' }}
                >
                  shoppinglounge@tothepp.com
                </a>
              </Text>
            </Row>
          </Row>
          <Row justifyContent="center" mt={10}>
            <Text type="b2" mType="b2">
              <Span type="h8">Business Hours&nbsp;</Span>
              {footerLang[cookies.language].hours}
            </Text>
          </Row>
          <Div width="100%" height={1} backgroundColor="g300" margin="40px 0" />
          <Text textAlign="center">
            ⓒ Premium Pass International Co., Ltd. All rights reserved.
          </Text>
        </Div>
      </Div>
    </Div>
  );
}

export default Footer;
