import { Div, hexToRGB } from 'differoute-styles';
import React from 'react';

function AdminCard({ children, ...props }) {
  return (
    <Div
      border={{ color: 'g100' }}
      borderRadius={12}
      boxShadow={{ color: hexToRGB('g700', 0.06), y: 6, r: 20 }}
      padding="30px"
      {...props}
    >
      {children}
    </Div>
  );
}

export default AdminCard;
