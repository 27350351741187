/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { Div } from 'differoute-styles';
import { ReactComponent as CheckIcon } from 'assets/icon/check.svg';
import CheckboxStyles from 'styles/CheckboxStyles';

function AdminCheckbox({
  checked,
  onChange,
  name,
  value,
  children,
  disabled,
  invalid,
  checkboxType,
  ...props
}) {
  const onChangeHandler = (event) => {
    const { value, name, checked } = event.target;
    onChange(value, name, checked, event);
  };

  return (
    <label>
      <CheckboxStyles
        checkboxType={checkboxType}
        display="flex"
        alignItems="center"
        cursor="pointer"
        gap={6}
        invalid={invalid}
        checked={checked}
        disabled={disabled}
        {...props}
      >
        <input
          className="hidden-checkbox"
          type="checkbox"
          checked={checked}
          onChange={onChangeHandler}
          disabled={disabled}
          name={name}
          value={value}
        />
        <Div className="styled-checkbox" checked={checked}>
          {checked && <CheckIcon />}
        </Div>
        {children}
      </CheckboxStyles>
    </label>
  );
}

export default AdminCheckbox;
