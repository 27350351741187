const goods = {
  ko: {
    community_title: '커뮤니티',
    community_notice: '공지사항',
    community_reference: '보도자료',
    question_title: '문의',
    question_qna: 'Q&A',
    question_faq: '자주하는 질문',
    question_qna_modal_title: '문의하기',
    question_qna_modal_title_placeholder: '제목을 입력하세요',
    question_qna_modal_content_placeholder: '내용을 입력하세요',
    question_qna_modal_confirm_label: '확인',
    question_qna_modal_cancel_label: '취소',
    question_qna_modal_alarm: '알림',
    question_qna_modal_recheck_label: '작성한 내용을 등록하시겠습니까?',
    question_qna_wait_label: '답변 대기',
    question_qna_complete_label: '답변 완료',
    question_qna_list_label: '목록',
  },
  en: {
    community_title: 'Community',
    community_notice: 'Notice',
    community_reference: 'News release',
    question_title: 'Inquiry',
    question_qna: 'Q&A',
    question_faq: 'FAQ',
    question_qna_modal_title: 'Inquiry',
    question_qna_modal_title_placeholder: 'Please enter the title',
    question_qna_modal_content_placeholder: 'Please enter the content',
    question_qna_modal_confirm_label: 'Upload',
    question_qna_modal_cancel_label: 'Cancel',
    question_qna_modal_alarm: 'Notification',
    question_qna_modal_recheck_label: 'Would you like to register an inquiry?',
    question_qna_wait_label: 'Waiting',
    question_qna_complete_label: 'Replied',
    question_qna_list_label: 'List',
  },
  cn: {
    community_title: '信息公开',
    community_notice: '公告事项',
    community_reference: '报道资料',
    question_title: '咨询',
    question_qna: '问与答',
    question_faq: '常见问题',
    question_qna_modal_title: '咨询',
    question_qna_modal_title_placeholder: '请输入您要咨询的题',
    question_qna_modal_content_placeholder: '请输入您想咨询的内容',
    question_qna_modal_confirm_label: '确认',
    question_qna_modal_cancel_label: '取消',
    question_qna_modal_alarm: '通知',
    question_qna_modal_recheck_label: '是否提交?',
    question_qna_wait_label: '待回答',
    question_qna_complete_label: '回答',
    question_qna_list_label: '目录',
  },
  jp: {
    community_title: 'コミュニティ',
    community_notice: 'お知らせ',
    community_reference: '報道資料',
    question_title: 'お問い合わせ',
    question_qna: 'Q&A',
    question_faq: 'よくあるご質問',
    question_qna_modal_title: 'お問い合わせ',
    question_qna_modal_title_placeholder: 'タイトルを入力してください',
    question_qna_modal_content_placeholder: '内容を入力して下さい',
    question_qna_modal_confirm_label: '確認',
    question_qna_modal_cancel_label: 'キャンセル',
    question_qna_modal_alarm: 'お知らせ',
    question_qna_modal_recheck_label: '作成した内容を登録しますか?',
    question_qna_wait_label: '返事待ち',
    question_qna_complete_label: '回答完了',
    question_qna_list_label: 'リスト',
  },
};

export default goods;
