function useMobile(props) {
  const user = navigator.userAgent;
  let isCheck = false;

  if (
    user.indexOf('iPhone') > -1 ||
    user.indexOf('Android') > -1 ||
    user.indexOf('iPad') > -1 ||
    user.indexOf('iPod') > -1
  ) {
    isCheck = true;
  }
  return isCheck;
}

export default useMobile;
