import aboutImg from 'assets/graphic/about/about.png';

const about = {
  img: aboutImg,
  ko: {
    title: `트래블라운지 소개`,
    subTitle: `전북쇼핑트래블라운지란?`,
    desc: [
      {
        text: `전북쇼핑트래블라운지는 일반적인 관광안내 뿐만 아니라
        컨시어지 서비스, 사무지원 서비스, 여행자 라운지, 포토존(이벤트존),
        지역 소상공인과 연계한 기념품·굿즈 판매 등 다양한 서비스를 제공합니다.`,
        textPoint: ``,
      },
      {
        text: `관광객들은 전북특별자치도 관광안내를 받으며, 다채로운 혜택을
        누릴 수 있습니다.`,
      },
      {
        text: `여행 중 언제라도 편안하게 들릴 수 있는 휴식의 공간이 되기를 바랍니다.`,
      },
      {
        point: ``,
      },
    ],
  },
  en: {
    title: `About Travel Lounge`,
    subTitle: `What is Jeonbuk Shopping Travel Lounge?`,
    desc: [
      {
        text: `Jeonbuk Shopping Travel Lounge provides tourism information 
        to the tourists and also provide various services such as tourism concierge service(luggage storage), office support service, 
        traveler’s lounge, photo zone (event zone), and sales of various 
        Jeonbuk State tourism souvenirs.`,
        textPoint: ``,
      },
      {
        text: `Tourists can get tourist information related to each city in
        Jeonbuk State and enjoy various benefits.`,
      },
      {
        text: `We hope it will be a place of relaxation where you can stop by
        anytime during your trip.`,
      },
      {
        point: ``,
      },
    ],
  },
  cn: {
    title: `购物旅游休息室简介`,
    subTitle: `全北购物旅游休息室是指?`,
    desc: [
      {
        text: `不仅为游客提供一般旅游信息, 还提供礼宾服务、商务中心服务、
        旅行者休息室、拍照区(体验活动区)、当地旅游纪念品销售等
        各种服务。`,
        textPoint: ``,
      },
      {
        text: `除此以外游客们可尽情享受全北特别自治道旅游信息及多种优惠等。尽力为游客们提供更舒适的休息空间, 希望大家今后一如既往地给予关心。`,
      },
      {
        text: ``,
      },
      {
        point: ``,
      },
    ],
  },
  jp: {
    title: `トラベルラウンジの紹介`,
    subTitle: `全北ショッピングトラベルラウンジとは?`,
    desc: [
      {
        text: `全北ショッピングトラベルラウンジは
一般的な観光案内だけではなくコンシェルジュサービス、
事務支援のサービス、旅行者ラウンジ、
フォトゾーン(イベントゾーン)、地域の小商工人と
連携した記念品やグッズの販売など
様々なサービスを提供しています。
全北特別自治道の観光案内を受け、
多彩な特典が楽しめます。
旅行中、いつでもリラックスできる
休息の空間になることを願っています。`,
        textPoint: ``,
      },
      {
        text: ``,
      },
      {
        text: ``,
      },
      {
        point: ``,
      },
    ],
  },
};
export default about;
