/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';
import styled from 'styled-components';

function BorderImage({
  src,
  objectFit,
  mObjectFit,
  mAspectRatio,
  aspectRatio,
}) {
  return (
    <Image
      alt="image"
      src={src}
      objectFit={objectFit}
      mObjectFit={mObjectFit}
      mAspectRatio={mAspectRatio}
      aspectRatio={aspectRatio}
    />
  );
}

const Image = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: ${({ objectFit }) => (objectFit && objectFit) || 'cover'};
  ${({ aspectRatio }) => aspectRatio && `aspect-ratio:${aspectRatio}`}

  @media ${({ theme }) => theme.device.mobile} {
    object-fit: ${({ objectFit, mObjectFit }) =>
      objectFit || mObjectFit || 'cover'};
    ${({ aspectRatio, mAspectRatio }) =>
      `aspect-ratio:${aspectRatio || mAspectRatio || null}`}
  }
`;

export default BorderImage;
