import React from 'react';
import { useTheme } from 'styled-components';
import { Text, Row } from 'differoute-styles';
import Empty from './Empty';

function AdminTable({
  keys,
  mSkipKeys,
  flex,
  mFlex,
  width,
  minWidth,
  mWidth,
  mMinWidth,
  maxWidth,
  list,
  headers,
  headerStyles,
  headerElementStyles,
  headerTextStyles,
  rowStyles,
  rowElementStyles,
  rowTextStyles,
  customElements,
  onClick,
  sortingList,
  sorting,
  ...props
}) {
  const theme = useTheme();
  return (
    <>
      {((list?.length === 0 || !list) && <Empty />) || (
        <>
          <Row
            className="table-header"
            {...(headerStyles || theme.table.headerStyles)}
          >
            {keys?.map(
              (headerKey) =>
                headers[headerKey] && (
                  <Row
                    className="table-header-cols"
                    key={`table-${headerKey}`}
                    mDisplay={(mSkipKeys?.includes(headerKey) && 'none') || ''}
                    height="100%"
                    alignItems="center"
                    justifyContent="center"
                    flex={(flex && flex[headerKey]) || 1}
                    mFlex={
                      (mFlex && mFlex[headerKey]) ||
                      (flex && flex[headerKey]) ||
                      1
                    }
                    maxWidth={(maxWidth && maxWidth[headerKey]) || '100%'}
                    minWidth={(minWidth && minWidth[headerKey]) || 40}
                    mMinWidth={(mMinWidth && mMinWidth[headerKey]) || 40}
                    width={(width && width[headerKey]) || '100%'}
                    mWidth={(mWidth && mWidth[headerKey]) || '100%'}
                    padding="0 10px"
                    {...((headerElementStyles &&
                      headerElementStyles[headerKey]) ||
                      {})}
                  >
                    <Text
                      type="h8"
                      mType="h8"
                      color="g600"
                      textAlign="center"
                      whiteSpace="nowrap"
                      {...((headerTextStyles && headerTextStyles[headerKey]) ||
                        {})}
                    >
                      {headers[headerKey]}
                    </Text>
                  </Row>
                ),
            )}
          </Row>
          {list?.map((item, index) => (
            <Row
              hover
              className="table-rows"
              key={`table${index}`}
              onClick={() => {
                if (onClick) {
                  onClick(item);
                }
              }}
              cursor={(onClick && 'pointer') || ''}
              position="relative"
              {...((rowStyles && rowStyles(index)) || theme.table.rowStyles)}
            >
              {keys.map((itemKey, keyIndex) => (
                <Row
                  className="table-cols"
                  key={`table${index}-item-${itemKey}`}
                  mDisplay={(mSkipKeys?.includes(itemKey) && 'none') || ''}
                  alignItems="center"
                  justifyContent="center"
                  flex={(flex && flex[itemKey]) || 1}
                  mFlex={
                    (mFlex && mFlex[itemKey]) || (flex && flex[itemKey]) || 1
                  }
                  minWidth={(minWidth && minWidth[itemKey]) || 40}
                  mMinWidth={(mMinWidth && mMinWidth[itemKey]) || 120}
                  width={(width && width[itemKey]) || '100%'}
                  mWidth={(mWidth && mWidth[itemKey]) || '100%'}
                  maxWidth={(maxWidth && maxWidth[itemKey]) || '100%'}
                  padding="0 10px"
                  position="relative"
                  borderBottom={{ color: 'g100' }}
                  {...((rowElementStyles && rowElementStyles[itemKey]) || {})}
                >
                  {(customElements &&
                    customElements[itemKey] &&
                    customElements[itemKey](item, index)) || (
                    <Text
                      type="b2"
                      mType="b3"
                      color="g600"
                      maxLine={1}
                      {...((rowTextStyles && rowTextStyles[itemKey]) || {})}
                    >
                      {item[itemKey]}
                    </Text>
                  )}
                </Row>
              ))}
            </Row>
          ))}
        </>
      )}
    </>
  );
}

export default AdminTable;
