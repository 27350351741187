import { Button, Div, Row, Text } from 'differoute-styles';
import AdminContainer from 'layouts/AdminContainer';
import React from 'react';
import AdminWrap from 'components/Admin/AdminWrap';
import AdminTable from 'components/Admin/AdminTable';
import moment from 'moment';
import AdminDropDown from 'components/Admin/AdminDropdown';
import AdminPagination from 'components/Admin/AdminPagination';

function AnnouncementPresenter({
  navigate,
  list,
  pages,
  filters,
  setFilters,
  languageList,
  goDetail,
}) {
  return (
    <AdminContainer title="공지사항">
      <Row justifyContent="space-between" alignItems="center">
        <AdminDropDown
          name="lang"
          list={languageList}
          customItem={(el) => el?.label}
          value={languageList?.find((el) => el?.value === filters.lang)}
          inputType="underline"
          onChange={(value) => {
            setFilters({
              ...filters,
              perPage: 10,
              page: 1,
              lastRowNum: 0,
              lang: value.value,
            });
          }}
        />
        <Button
          label="새 글 등록"
          onClick={() => navigate('write', { state: 'announcement' })}
          buttonType="capsule"
          buttonColor="lightGreen"
          width={105}
          height={37}
          active
        />
      </Row>
      <AdminWrap view={pages.totalCount} mt={10}>
        <AdminTable
          list={list}
          keys={['rowNum', 'title', 'isMarked', 'createdDt']}
          onClick={(item) => goDetail(item)}
          headers={{
            rowNum: '번호',
            title: '제목',
            isMarked: '상단고정',
            createdDt: '등록일',
          }}
          headerStyles={{
            height: '45px',
            backgroundColor: 'rgba(0, 181, 239, 0.04)',
          }}
          headerTextStyles={{
            rowNum: { color: 'g600' },
            title: { color: 'g600' },
            isMarked: { color: 'g600' },
            createdDt: { color: 'g600' },
          }}
          rowStyles={(index) => ({
            height: '45px',
            backgroundColor: (index % 2 === 1 && 'g100') || 'white',
            cursor: 'pointer',
          })}
          flex={{
            rowNum: 0.645,
            title: 7.56,
            isMarked: 0.8,
            createdDt: 0.99,
          }}
          customElements={{
            title: (item) => (
              <Text type="b2" color="g600" width="100%">
                {item.title}
              </Text>
            ),
            isMarked: (item) => (
              <Text type="b2" color="g600">
                {(item.isMarked === 1 && '예') || '-'}
              </Text>
            ),
            createdDt: (item) => (
              <Text type="b2" color="g600">
                {moment(item.createdDt).format('YYYY-MM-DD')}
              </Text>
            ),
          }}
        />
        {pages.totalCount !== 0 && (
          <Div margin="40px auto 0" maxWidth={200}>
            <AdminPagination
              pages={pages}
              filters={filters}
              setFilters={setFilters}
            />
          </Div>
        )}
      </AdminWrap>
    </AdminContainer>
  );
}

export default AnnouncementPresenter;
