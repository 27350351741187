import React, { useEffect, useState } from 'react';
import { adminApi } from 'api';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { languageList, goodsCategory } from 'data/admin';
import { Loading } from 'differoute-styles';
import GoodsPresenter from './GoodsPresenter';

function GoodsContainer(props) {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [category, setCategory] = useState();
  // init setting
  const [filters, setFilters] = useState({
    perPage: 10,
    page: params.get('page') || 1,
    lastRowNum: params.get('lastRowNum') || 0,
    lang: params.get('lang') || 'ko',
  });
  const [pages, setPages] = useState({
    currentCount: 0,
    currentPage: 1,
    perPage: 10,
    totalPages: 0,
    totalCount: 0,
  });

  // getList api
  const [list, setList] = useState();
  const getList = async () => {
    if (category) {
      const [info, error] = await adminApi.ProductApi.goods.list(
        category.value,
        filters,
      );
      if (info && info.resCode === 200) {
        const { result } = info;
        setPages({
          currentCount: result.currentCount,
          currentPage: result.currentPage,
          perPage: result.perPage,
          totalPages: result.totalPages,
          totalCount: result.totalCount,
        });
        setList(result.list);
        setLoading(false);
      } else if (error.resCode === 401) {
        sessionStorage.removeItem('adminTokenKey');
        setLoading(false);
        navigate('/admin/login');
      } else {
        setLoading(false);
        console.log('error');
      }
    }
  };

  // init settings
  useEffect(() => {
    if (window.location.pathname.split('/')[3] === 'etc') {
      setCategory({ label: '기타', value: 'etc' });
    } else {
      setCategory({ label: '전북', value: 'jb' });
    }
  }, []);

  useEffect(() => {
    if (filters) {
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set('page', `${filters.page}`);
      searchParams.set('lastRowNum', `${filters.lastRowNum}`);
      searchParams.set('perPage', 10);
      searchParams.set('lang', `${filters.lang}`);
      const goUrl = `?${searchParams.toString()}`;
      navigate(`${goUrl}`);
      getList();
    }

    return () => setLoading(true);
  }, [filters, category]);

  const goDetail = (item) => {
    const searchParams = new URLSearchParams(window.location.search);
    const goUrl = `?${searchParams.toString()}`;
    navigate(`/admin/goods/${category.value}/${String(item.id)}${goUrl}`, {
      replace: true,
    });
  };

  const goCategory = (val) => {
    setCategory(val);
    const searchParams = new URLSearchParams(window.location.search);
    const goUrl = `?${searchParams.toString()}`;
    navigate(`/admin/goods/${val.value}${goUrl}`, {
      replace: true,
    });
  };
  return (
    <>
      <GoodsPresenter
        list={list}
        pages={pages}
        filters={filters}
        setFilters={setFilters}
        languageList={languageList}
        goodsCategory={goodsCategory}
        category={category}
        goDetail={goDetail}
        goCategory={goCategory}
        navigate={navigate}
      />
      {loading && <Loading loading={loading} />}
    </>
  );
}

export default GoodsContainer;
