import { Div, Loading, Row, Text, Button, Col } from 'differoute-styles';
import React, { useState, useEffect } from 'react';
import { Line } from 'styles/CustomStyles';
import useMobile from 'hooks/useMobile';
import MainContainer from 'layouts/MainContainer';
import tourLang from 'data/lang/main/tour';
import { userApi } from 'api';
import { useCookies } from 'react-cookie';
import TourSlider from './Sliders/TourSlider';
import MSlider from './Sliders/MSlider';

function Tour({ currentIndex }) {
  const [cookies] = useCookies();
  const [loading, setLoading] = useState(true);

  const [filters, setFilters] = useState({
    category: tourLang[cookies.language].category1.type,
    categoryLabel: tourLang[cookies.language].category1.name,
    lang: cookies.language,
  });
  const mobile = useMobile();

  // list api
  const [list, setList] = useState();
  const getList = async () => {
    const [info] = await userApi.tourApi.tour(filters.category, filters.lang);
    if (info && info.resCode === 200) {
      const { result } = info;
      setList(result.list);
      setLoading(false);
    } else {
      setLoading(false);
      console.log('error');
    }
  };
  useEffect(() => {
    if (filters) {
      getList();
    }

    return () => setLoading(true);
  }, [filters]);

  const [currentSlide, setCurrentSlide] = useState(0);
  return (
    <>
      <Div backgroundColor="primaryOpacity40" width="100vw">
        <MainContainer maxWidth={1300} mMaxWidth="100%">
          <Text color="primary" type="h4" mType="h6" textAlign="center">
            {tourLang[cookies.language].title}
          </Text>
          {/* mobile */}
          <Div width="100%" overflowX="hidden">
            <Row
              display="none"
              mDisplay="flex"
              mGap={60}
              padding="14px 0"
              transition="0.3s ease-in"
              mTransform={
                (filters.category === 'daytrip' && 'translateX(38%)') ||
                'trnaslateX(-8%)'
              }
            >
              <Div
                mMinWidth="fit-content"
                onClick={() => {
                  setFilters({
                    ...filters,
                    category: tourLang[cookies.language].category1.type,
                    categoryLabel: tourLang[cookies.language].category1.name,
                  });
                }}
              >
                <Text
                  type="h3"
                  mType="h4"
                  textAlign="center"
                  transition="0.3s ease-in"
                  color={(filters.category === 'daytrip' && 'g800') || 'g300'}
                >
                  {tourLang[cookies.language].category1.name}
                </Text>
              </Div>
              <Div
                mMinWidth="fit-content"
                onClick={() => {
                  setFilters({
                    ...filters,
                    category: tourLang[cookies.language].category2.type,
                    categoryLabel: tourLang[cookies.language].category2.name,
                  });
                }}
              >
                <Text
                  type="h3"
                  mType="h4"
                  textAlign="left"
                  transition="0.3s ease-in"
                  color={(filters.category === 'lodge' && 'g800') || 'g300'}
                >
                  {tourLang[cookies.language].category2.name}
                </Text>
              </Div>
            </Row>
          </Div>
          <Line display="none" mDisplay="block" mb={40} />
          <Div mDisplay="none">
            <TourSlider
              mobile={mobile}
              cookies={cookies}
              list={list}
              filters={filters}
              setFilters={setFilters}
              currentIndex={currentIndex}
            />
          </Div>
          {/* mobile  */}
          <Div display="none" mDisplay="block">
            <MSlider
              type="tour"
              color="primary"
              cookies={cookies}
              list={list}
              category={filters && filters.category}
              setFilters={setFilters}
              currentSlide={currentSlide}
              setCurrentSlide={setCurrentSlide}
            />
          </Div>
          <Button
            label={tourLang[cookies.language].buttonLabel}
            buttonType="capsule"
            type="outline"
            buttonColor="primary"
            active
            width={100}
            mWidth={100}
            margin="40px auto 0"
            backgroundColor="transparent"
            onClick={() => window.open('https://www.travelday.kr/#/main')}
          />
        </MainContainer>
      </Div>
      <Loading loading={loading} />
    </>
  );
}

export default Tour;
