/* eslint-disable no-plusplus */
import React, { useEffect, useRef, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import Header from 'components/Common/Header';
import MHeader from 'components/Common/MHeader';
import Footer from 'components/Common/Footer';
import useMobile from 'hooks/useMobile';
import styled from 'styled-components';

function UserLayout(props) {
  const mobile = useMobile();
  const navigate = useNavigate();
  const sliderRef = useRef();
  const [currentPage, setCurrentPage] = useState({
    positionY: 0,
    index:
      (window.location.pathname === '/' && 1) ||
      (window.location.pathname.split('/')[1] === 'community' && 8) ||
      9,
  });
  const goToMenu = (menu, idx) => {
    const searchParams = new URLSearchParams(window.location.search);
    const lang = searchParams.get('lang');
    const goUrl = `${menu}?lang=${lang}`;
    setCurrentPage({ ...currentPage, index: idx });
    navigate(goUrl);
  };

  let isScrolling = false;
  useEffect(() => {
    const totalCount = sliderRef.current.childNodes.length;
    const wheelHandler = (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (isScrolling) return;
      isScrolling = true;
      const { deltaY } = e;
      const { scrollTop } = sliderRef.current;
      setTimeout(() => {
        if (deltaY > 0) {
          for (let i = 1; i <= totalCount; i++) {
            if (i === currentPage.index) {
              let top = window.innerHeight * i;
              if (i === totalCount) {
                top = scrollTop + 463;
              }
              sliderRef.current.scrollTo({
                top,
                behavior: 'smooth',
                block: 'nearest',
              });
              setCurrentPage({
                ...currentPage,
                index:
                  currentPage.index + 1 > totalCount
                    ? totalCount
                    : currentPage.index + 1,
                positionY: window.innerHeight * i,
              });
            }
          }
        } else if (deltaY < 0) {
          for (let i = totalCount; i > 0; i--) {
            if (i === currentPage.index) {
              let top = window.innerHeight * (i - 2);
              if (i === totalCount) {
                top = scrollTop - 463;
              }
              sliderRef.current.scrollTo({
                top,
                behavior: 'smooth',
                block: 'nearest',
              });
              setCurrentPage({
                ...currentPage,
                index: currentPage.index - 1 < 1 ? 1 : currentPage.index - 1,
                positionY: top,
              });
            }
          }
        }
      }, 400);
    };
    const sliderRefCurrent = sliderRef.current;
    if (window.location.pathname === '/' && !mobile) {
      sliderRefCurrent.addEventListener('wheel', wheelHandler, {
        passive: false,
      });
      return () => {
        sliderRefCurrent.removeEventListener('wheel', wheelHandler);
      };
    }
  });

  // mobile header blur css
  const [scrollPosition, setScrollPosition] = useState(0);
  useEffect(() => {
    if (mobile) {
      const scroll = () => {
        setScrollPosition(window.scrollY);
      };
      window.addEventListener('scroll', scroll);
      return () => {
        window.removeEventListener('scroll', scroll);
      };
    }
  });

  return (
    <>
      <Header
        setRef={sliderRef}
        goToMenu={goToMenu}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
      <MHeader
        scrollPosition={scrollPosition}
        setRef={sliderRef}
        goToMenu={goToMenu}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
      <Outer ref={sliderRef} path={window.location.pathname} mobile={mobile}>
        <Outlet
          context={{
            currentPage,
          }}
        />
      </Outer>
      {window.location.pathname !== '/' && <Footer />}
    </>
  );
}

const Outer = styled.div`
  overflow-y: auto;
  width: 100vw;
  transition-timing-function: ease-in-out;
  height: ${({ path }) => (path !== '/' && '100% !important') || '100dvh'};

  @media ${({ theme }) => theme.device.mobile} {
    height: fit-content;
  }
`;

export default UserLayout;
