import { Button, Div, HTMLWrap, Loading, Text } from 'differoute-styles';
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { userApi } from 'api';
import moment from 'moment';

function AnnouncementDetail(props) {
  const { id } = useParams();

  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  // get detail id
  const [item, setItem] = useState();

  const getList = async () => {
    const [info] = await userApi.announcementApi.detail(id);
    if (info.resCode === 200) {
      setItem(info.result);
      setLoading(false);
    }
  };

  useEffect(() => {
    getList();
    setLoading(false);
  }, []);

  return (
    <>
      <Div maxWidth={1024} margin="auto" minHeight="calc(100vh - 583px)">
        <Div pb={20} borderBottom={{ color: 'g200' }}>
          <Text type="h5" mType="h5" mMaxLine={2}>
            {item?.title}
          </Text>
          <Text type="b1" mType="b1" color="g400" mt={10}>
            {moment(item?.createdDt).format('YYYY-MM-DD')}
          </Text>
        </Div>
        <Div padding="20px 0">
          {item?.content && <HTMLWrap width="100%" content={item.content} />}
        </Div>
        <Button
          label="목록으로"
          onClick={() => navigate(-1)}
          type="outline"
          buttonType="capsule"
          minWidth={100}
          height={40}
          mHeight={40}
          mt={20}
          active
        />
      </Div>
      <Loading loading={loading} />
    </>
  );
}

export default AnnouncementDetail;
