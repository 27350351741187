import React from 'react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import axios from 'axios';
import './writer.css';

function Writer({ state, value, name, onChange, imagePath }) {
  return (
    <>
      {state === 'announcement' && (
        <CKEditor
          editor={Editor}
          config={{
            fontSize: {
              options: [
                14,
                15,
                16,
                17,
                18,
                19,
                'default',
                21,
                22,
                23,
                24,
                25,
                26,
                27,
                28,
                29,
                30,
              ],
            },
            simpleUpload: {
              headers: {
                Authorization:
                  (axios.defaults.headers.common.Authorization = `Bearer ${sessionStorage.getItem(
                    'adminTokenKey',
                  )}`),
              },
              uploadUrl: `${process.env.REACT_APP_API_HOST}/${imagePath}`,
            },
          }}
          data={value}
          onChange={(event, editor) => {
            const data = editor.getData();
            onChange(data, name);
          }}
        />
      )}
      {state !== 'announcement' && (
        <CKEditor
          editor={Editor}
          config={{
            fontSize: {
              options: [
                14,
                15,
                16,
                17,
                18,
                19,
                'default',
                21,
                22,
                23,
                24,
                25,
                26,
                27,
                28,
                29,
                30,
              ],
            },
            simpleUpload: {
              headers: {
                Authorization:
                  (axios.defaults.headers.common.Authorization = `Bearer ${sessionStorage.getItem(
                    'adminTokenKey',
                  )}`),
              },
              uploadUrl: `${process.env.REACT_APP_API_HOST}/${imagePath}`,
            },
            removePlugins: [
              'Image',
              'ImageCaption',
              'ImageStyle',
              'ImageToolbar',
            ],
          }}
          data={value}
          onChange={(event, editor) => {
            const data = editor.getData();
            onChange(data, name);
          }}
        />
      )}
    </>
  );
}

export default Writer;
