import AdminCard from 'components/Admin/AdminCard';
import {
  Input,
  Text,
  Row,
  Button,
  Div,
  InputFile,
  Loading,
} from 'differoute-styles';
import AdminContainer from 'layouts/AdminContainer';
import Writer from 'components/Editor/Writer';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { adminApi } from 'api';
import AdminCheckbox from 'components/Admin/AdminCheckbox';
import AdminAlert from 'components/Admin/AdminAlert';
import { isEmptyObjItems } from 'utils/checkData';

function EditorEdit(props) {
  const { id } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [values, setValues] = useState();
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState();
  const [category, setCategory] = useState();
  // init values setting
  useEffect(() => {
    if (state && state.type === 'announcement') {
      setValues({
        lang: state.item.lang,
        title: state.item.title,
        isMarked: state.item.isMarked,
        content: state.item.content,
        attachmentFile: state.item.attachmentFile,
        attachmentFileName: state.item.attachmentFileName,
      });
    } else if (state.type === 'faq') {
      setValues({
        question: state.item.question,
        answer: state.item.answer,
      });
    } else if (state.type === 'goods') {
      setValues({
        title: state.item.title,
        price: state.item.price,
        category: state.item.category,
        content: state.item.content,
        attachmentFile: state.item.attachmentFile,
        attachmentFileName: state.item.attachmentFileName,
      });
      setCategory(state.item.category);
    }
  }, []);

  const onChange = (value, name, fileName) => {
    if (fileName) {
      setValues({
        ...values,
        [name]: value.fileUrl,
        attachmentFileName: fileName,
      });
    } else {
      setValues({ ...values, [name]: value });
    }
  };

  const submit = async () => {
    setLoading(true);
    if (state.type === 'announcement') {
      // 공지사항
      const [info, error] = await adminApi.communityApi.notice.edit(id, values);
      if (info && info.resCode === 200) {
        setLoading(false);
        setAlert({
          title: '알림',
          content: '수정 완료되었습니다.',
          customButton: {
            buttonColor: 'lightGreen',
            borderRadius: '50rem',
            color: 'white',
            backgroundColor: 'lightGreen',
            label: '확인',
            onClick: () => navigate(-1),
          },
        });
      } else if (error.resCode === 401) {
        sessionStorage.removeItem('adminTokenKey');
        setLoading(false);
        navigate('/admin/login');
      } else {
        setLoading(false);
        console.log('error');
      }
    } else if (state.type === 'faq') {
      // 자주 묻는 질문
      const [info, error] = await adminApi.questionApi.faq.edit(id, values);
      if (info && info.resCode === 200) {
        setLoading(false);
        setAlert({
          title: '알림',
          content: '수정 완료되었습니다.',
          customButton: {
            buttonColor: 'lightGreen',
            borderRadius: '50rem',
            color: 'white',
            backgroundColor: 'lightGreen',
            label: '확인',
            onClick: () => navigate(-1),
          },
        });
      } else if (error.resCode === 401) {
        sessionStorage.removeItem('adminTokenKey');
        setLoading(false);
        navigate('/admin/login');
      } else {
        setLoading(false);
        console.log('error');
      }
    } else if (state.type === 'goods') {
      // 상품
      const [info, error] = await adminApi.ProductApi.goods.edit(
        category,
        id,
        values,
      );
      if (info && info.resCode === 200) {
        setLoading(false);
        setAlert({
          title: '알림',
          content: '수정 완료되었습니다.',
          customButton: {
            buttonColor: 'lightGreen',
            borderRadius: '50rem',
            color: 'white',
            backgroundColor: 'lightGreen',
            label: '확인',
            onClick: () => navigate(-1),
          },
        });
      } else if (error.resCode === 401) {
        sessionStorage.removeItem('adminTokenKey');
        setLoading(false);
        navigate('/admin/login');
      } else {
        setLoading(false);
        console.log('error');
      }
    }
  };

  const editItem = () => {
    setAlert({
      title: '알림',
      content: '수정하시겠습니까?',
      customButton: {
        buttonColor: 'lightGreen',
        borderRadius: '50rem',
        color: 'white',
        backgroundColor: 'lightGreen',
        label: '확인',
        onClick: submit,
      },
      cancel: { onClick: () => setAlert('') },
    });
  };

  // button active
  const [submitActive, setSubmitActive] = useState(false);
  useEffect(() => {
    if (state.type === 'announcement' && values) {
      if (values.attachmentFile === 'null') {
        delete values.attachmentFile;
        delete values.attachmentFileName;
      }
      setSubmitActive(
        !isEmptyObjItems({
          ...values,
          isMarked: String(values.isMarked),
        }),
      );
    } else {
      setSubmitActive(!isEmptyObjItems({ ...values }));
    }
  }, [values]);

  return (
    values && (
      <>
        <AdminContainer title="수정하기">
          <AdminCard>
            <Input
              label="제목"
              name={(state.type === 'faq' && 'question') || 'title'}
              value={
                (state.type === 'faq' ? values.question : values.title) || ''
              }
              onChange={onChange}
              inputType="underline"
            />
            {state.type === 'announcement' && (
              <Div width={77} margin="20px 0">
                <AdminCheckbox
                  checked={values.isMarked === 1 || false}
                  name="isMarked"
                  value={values.isMarked}
                  onChange={(value, name) => {
                    onChange((value === '0' && 1) || 0, name);
                  }}
                >
                  <Text type="h8" color="g500">
                    상단고정
                  </Text>
                </AdminCheckbox>
              </Div>
            )}
            {state.type === 'goods' && (
              <>
                <Input
                  label="가격"
                  name="price"
                  mt={20}
                  value={values?.price}
                  onChange={onChange}
                  inputType="underline"
                />
                <InputFile
                  label="썸네일"
                  name="url"
                  mt={20}
                  value={values?.attachmentFile}
                  inputType="underline"
                />
              </>
            )}
            <Text
              type="b2"
              color="g900"
              mt={(state.type !== 'announcement' && 20) || null}
            >
              내용
            </Text>
            <Writer
              imagePath={
                (state.type === 'announcement' &&
                  'admin/announcement/upload/editor/file') ||
                null
              }
              state={(state.type === 'announcement' && 'announcement') || ''}
              name={(state.type === 'faq' && 'answer') || 'content'}
              value={(state.type === 'faq' && values.answer) || values.content}
              onChange={onChange}
            />
            <Row mt={10} justifyContent="space-between">
              <Button
                label="목록으로"
                onClick={() =>
                  navigate(
                    state.type === 'faq'
                      ? '/admin/question/faq'
                      : state.type === 'announcement'
                      ? '/admin/announcement'
                      : state.type === 'goods' &&
                        `/admin/product/goods/${values.category}`,
                  )
                }
                buttonType="capsule"
                type="outline"
                buttonColor="adminBlue"
                width={105}
                active
              />
              <Row gap={10}>
                <Button
                  label="취소"
                  onClick={() => navigate(-1)}
                  buttonType="capsule"
                  buttonColor="g500"
                  type="outline"
                  border={{ width: 0 }}
                  width={105}
                  active
                />
                <Button
                  label="확인"
                  disabled={!submitActive}
                  onClick={editItem}
                  buttonType="capsule"
                  buttonColor="lightGreen"
                  width={105}
                  active={submitActive}
                />
              </Row>
            </Row>
          </AdminCard>
        </AdminContainer>
        {alert && <AdminAlert alert={alert} />}
        {loading && <Loading loading={loading} />}
      </>
    )
  );
}

export default EditorEdit;
