import React, { useState } from 'react';
import { adminApi } from 'api';
import {
  Loading,
  Button,
  Col,
  Div,
  ImageComponent,
  Input,
} from 'differoute-styles';
import { useNavigate } from 'react-router-dom';
import logo from 'assets/icon/logo_black.svg';

function Login(props) {
  const navigate = useNavigate();
  const [values, setValues] = useState({ id: '', password: '' });
  const [loading, setLoading] = useState(false);
  const onChange = (value, name) => {
    setValues({ ...values, [name]: value });
  };

  const login = async () => {
    setLoading(true);
    const [info] = await adminApi.loginApi.login(values);
    if (info) {
      sessionStorage.setItem('adminTokenKey', info.result.token);
      setLoading(false);
      navigate('/admin/announcement');
    } else {
      console.log('error');
      setLoading(false);
    }
  };
  return (
    <>
      <Col
        width="calc(100vw - 80px)"
        height="100dvh"
        alignItems="center"
        justifyContent="center"
      >
        <Div
          width={460}
          padding="30px"
          borderRadius={12}
          border={{ color: 'g100' }}
          boxShadow={{ color: 'rgba(68, 73, 77, 0.06)', y: 6, r: 20 }}
        >
          <Div padding="25px 30px 40px">
            <ImageComponent src={logo} height={70} margin="auto" />
            <Input
              name="id"
              value={values.id}
              onChange={onChange}
              placeholder="ID"
              inputType="underline"
              mt={17}
            />
            <Input
              name="password"
              value={values.password}
              onChange={onChange}
              type="password"
              placeholder="PW"
              inputType="underline"
              mt={40}
            />
            <Button
              label="로그인"
              width="100%"
              mWidth="100%"
              buttonColor="lightGreen"
              onClick={login}
              active
              mt={40}
            />
          </Div>
        </Div>
      </Col>
      {loading && <Loading loading={loading} />}
    </>
  );
}

export default Login;
