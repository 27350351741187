import React, { useEffect, useRef } from 'react';
import { Row, Div, Text } from 'differoute-styles';
import IconDiv from 'components/Common/IconDiv';
import { HoverImage } from 'styles/CustomStyles';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

function GoodsSlider({
  list,
  goDetail,
  filters,
  currentSlide,
  setCurrentSlide,
}) {
  const swiperRef = useRef();

  useEffect(() => {
    if (filters.category && swiperRef.current) {
      swiperRef.current.swiper.slideTo(0);
    }
  }, [filters.category]);

  return (
    list && (
      <Swiper
        ref={swiperRef}
        effect="fade"
        speed={1000}
        modules={[Navigation]}
        className="mySwiper"
        onSlideChange={(e) => setCurrentSlide(e.realIndex)}
        spaceBetween={20}
        centeredSlides
        slidesPerView={1.4}
        navigation={{
          prevEl: '.prev',
          nextEl: '.next',
        }}
      >
        {list.map((item, index) => (
          <SwiperSlide key={`item_${index}`}>
            <Div cursor="pointer" onClick={() => goDetail(item)}>
              <HoverImage
                src={item.attachmentFile}
                width={560}
                borderRadius={10}
                aspectRatio="28 / 23"
              />
              <Text mt={20} type="h7" mType="h6" maxLine={1} textAlign="center">
                {item.title}
              </Text>
              <Text
                margin="10px auto 0"
                type="b1"
                mType="b2"
                color="g500"
                lineHeight="24px"
                maxWidth={500}
                maxLine={1}
                textAlign="center"
              >
                {item.price}
              </Text>
            </Div>
          </SwiperSlide>
        ))}
        <Row mt={5} gap={10} justifyContent="flex-end">
          <IconDiv
            className="prev"
            name="arrow"
            pathFill={(currentSlide === 0 && 'g200') || 'lightGreen'}
            hover
          />
          <IconDiv
            className="next"
            name="arrow"
            pathFill={
              (currentSlide + 1 === list.length && 'g200') || 'lightGreen'
            }
            transform="rotate(180deg)"
            hover
          />
        </Row>
      </Swiper>
    )
  );
}

export default GoodsSlider;
