import { Col, Text } from 'differoute-styles';
import React from 'react';

function Empty(props) {
  return (
    <Col alignItems="center" justifyContent="center" width="100%" height={285}>
      <Text color="g300">등록된 항목이 없습니다.</Text>
    </Col>
  );
}

export default Empty;
