const footer = {
  ko: {
    name: '전북쇼핑트래블라운지',
    address: '전북특별자치도 전주시 완산구 충경로 25, 1층 전북쇼핑트래블라운지',
    tel: '063)283-8880',
    fax: '063)283-8881',
    hours: '10:00-19:00, 연중무휴',
  },
  en: {
    name: 'Jeonbuk Shopping Travel Lounge',
    address:
      '1st floor Jeonbuk Shopping Travel Lounge, 25, Chunggyeong-ro, Wansan-gu, Jeonju-si, Jeonbuk State',
    tel: '063)283-8880',
    fax: '063)283-8881',
    hours: '10:00-19:00, all year round',
  },
  cn: {
    name: '全北购物旅游休息室',
    address: '全北特别自治道 全州市 完山区 忠景路 25, 1楼 全北购物旅游休息室',
    tel: '063)283-8880',
    fax: '063)283-8881',
    hours: '10:00-19:00, 全年无休',
  },
  jp: {
    name: '全北ショッピングトラベルラウンジ',
    address:
      '全北特別自治道 全州市 完山区 忠景路 25, 1階 全北ショッピングトラベルラウンジ',
    tel: '063)283-8880',
    fax: '063)283-8881',
    hours: '10:00-19:00, 年中無休',
  },
};

export default footer;
