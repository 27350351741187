import {
  Input,
  PopUp,
  Text,
  Div,
  Col,
  Button,
  Textarea,
} from 'differoute-styles';
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { userApi } from 'api';
import axios from 'axios';
import DetailPresenter from './DetailPresenter';

function DetailContainer(props) {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [answer, setAnswer] = useState();
  const [popupOpen, setPopupOpen] = useState();
  const [password, setPassword] = useState('');
  const [invalid, setInvalid] = useState({ type: '', text: '' });

  const onChange = (value, name) => {
    if (name === 'password') {
      if (invalid.type === 'error') {
        setInvalid('');
      }
      setPassword(value);
    } else if (name === 'answer') {
      setAnswer(value);
    }
  };

  // admin login
  const [adminToken, setAdminToken] = useState();
  const Login = async () => {
    if (password) {
      const [info, error] = await userApi.questionApi.adminLogin({
        qId: id,
        password,
      });
      if (info) {
        setAdminToken(info.result.token);
        setPopupOpen('confirm');
      } else if (error.resCode === 400) {
        setInvalid({ type: 'error', text: '비밀번호가 일치하지 않습니다.' });
      }
    }
  };

  // admin submit
  const adminPost = async () => {
    axios.defaults.headers.common.Authorization = `Bearer ${adminToken}`;
    if (password) {
      const [info] = await userApi.questionApi.adminAnswer({
        qId: id,
        content: answer,
      });
      if (info) {
        setAdminToken();
        window.location.reload();
      } else {
        console.log('error');
      }
    }
  };

  // get detail id
  const [questionItem, setQuestionItem] = useState();
  const [answerItem, setAnswerItem] = useState();
  const getList = async () => {
    const [info] = await userApi.questionApi.detail(id);
    if (info.resCode === 200) {
      setAnswerItem(info.result.a);
      setQuestionItem(info.result.q);
      setLoading(false);
    }
  };

  useEffect(() => {
    getList();
    setLoading(false);
  }, []);

  return (
    <>
      <DetailPresenter
        setPopupOpen={setPopupOpen}
        navigate={navigate}
        questionItem={questionItem}
        answerItem={answerItem}
      />
      {popupOpen === 'confirm' && (
        <PopUp minWidth="320px !important">
          <Text type="h5" mType="h5" textAlign="center">
            알림
          </Text>
          <Text mt={10} type="b1" mType="b1" color="g500" textAlign="center">
            관리자 비밀번호가 인증되었습니다.
          </Text>
          <Button
            mt={20}
            label="확인"
            onClick={() => setPopupOpen('answer')}
            buttonType="capsule"
            width="100%"
            mWidth="100%"
            active
          />
        </PopUp>
      )}
      {popupOpen === 'submit' && (
        <PopUp minWidth="320px !important">
          <Text type="h5" mType="h5" textAlign="center">
            관리자 비밀번호 입력
          </Text>
          <Div mt={10}>
            <Input
              name="password"
              width={260}
              type="password"
              placeholder="비밀번호를 입력하세요"
              onChange={onChange}
              inputType="underline"
              invalid={invalid.type === 'error' ? 'error' : ''}
              invalidText={invalid.type === 'error' ? invalid.text : ''}
            />
          </Div>
          <Col gap={10} mt={30}>
            <Button
              label="확인"
              onClick={Login}
              buttonType="capsule"
              width="100%"
              mWidth="100%"
              active={password !== ''}
            />
            <Button
              label="취소"
              onClick={() => {
                setPopupOpen();
                setInvalid('');
              }}
              buttonType="capsule"
              backgroundColor="transparent"
              type="outline"
              width="100%"
              mWidth="100%"
              style={{ border: 'none' }}
            />
          </Col>
        </PopUp>
      )}
      {popupOpen === 'answer' && (
        <PopUp minWidth="320px !important">
          <Text type="h5" mType="h5" textAlign="center">
            문의 하기
          </Text>
          <Div position="relative" mt={10}>
            <Textarea
              name="answer"
              placeholder="내용을 입력하세요"
              height={172}
              maxLength={100}
              onChange={onChange}
            >
              <Text
                color="g300"
                type="b3"
                position="absolute"
                bottom={10}
                right={10}
              >
                {(!answer && '0') || answer.length} / 100
              </Text>
            </Textarea>
          </Div>
          <Col gap={10} mt={10}>
            <Button
              label="확인"
              onClick={() => setPopupOpen('answerSumbit')}
              buttonType="capsule"
              width="100%"
              mWidth="100%"
              active={answer !== ''}
            />
            <Button
              label="취소"
              onClick={() => setPopupOpen()}
              buttonType="capsule"
              backgroundColor="transparent"
              type="outline"
              width="100%"
              mWidth="100%"
              style={{ border: 'none' }}
            />
          </Col>
        </PopUp>
      )}
      {popupOpen === 'answerSumbit' && (
        <PopUp minWidth="320px !important">
          <Text type="h5" mType="h5" textAlign="center">
            알림
          </Text>
          <Text type="b1" mType="b1" color="g500" mt={10} textAlign="center">
            작성한 내용을 등록하시겠습니까?
          </Text>
          <Col gap={10} mt={20}>
            <Button
              label="확인"
              onClick={adminPost}
              buttonType="capsule"
              width="100%"
              mWidth="100%"
              active
            />
            <Button
              label="취소"
              onClick={() => setPopupOpen()}
              buttonType="capsule"
              backgroundColor="transparent"
              type="outline"
              width="100%"
              mWidth="100%"
              style={{ border: 'none' }}
            />
          </Col>
        </PopUp>
      )}
    </>
  );
}

export default DetailContainer;
