import React from 'react';
import { Div } from 'differoute-styles';
import './viewer.css';

function Viewer({ value }) {
  return value ? (
    <Div
      className="ck-content"
      color="white"
      dangerouslySetInnerHTML={{ __html: value }}
    />
  ) : (
    <></>
  );
}

export default Viewer;
