import AdminCard from 'components/Admin/AdminCard';
import { Button, Div, Row, Span, Text } from 'differoute-styles';
import AdminContainer from 'layouts/AdminContainer';
import moment from 'moment';
import React from 'react';
import Writer from 'components/Editor/Writer';

function DetailPresenter({
  navigate,
  deleteItem,
  answerItem,
  questionItem,
  onChange,
  editItem,
}) {
  return (
    questionItem && (
      <AdminContainer title="상세보기">
        <AdminCard padding="30px">
          <Div padding="0 20px">
            <Row padding="18px 0" gap={13} alignItems="center">
              <Div
                padding="4px 8px"
                borderRadius={6}
                backgroundColor={
                  (questionItem.isAnswered === 1 && 'primary') || 'g200'
                }
              >
                <Text
                  type="h8"
                  color={(questionItem.isAnswered === 1 && 'white') || 'g500'}
                >
                  {(questionItem.isAnswered === 1 && '답변완료') || '답변대기'}
                </Text>
              </Div>
              <Text type="b2" color="g400">
                <Span> · </Span>
                {moment(questionItem.createdDt).format('YYYY-MM-DD')}
              </Text>
            </Row>
            <Div padding="20px" borderTop={{ color: 'g200' }}>
              <Text style={{ wordBreak: 'break-word' }}>
                {questionItem.content}
              </Text>
            </Div>
          </Div>
        </AdminCard>
        <AdminCard mt={20} padding="30px">
          <Row
            padding="10px 10px 4px"
            mb={4}
            gap={4}
            borderBottom={{ color: 'g200' }}
          >
            <Text type="b3" color="g600">
              답변하기
            </Text>
            <Text type="b2" color="g400">
              <Span> · </Span>
              {moment(questionItem.createdDt).format('YYYY-MM-DD')}
            </Text>
          </Row>
          <Writer
            name="content"
            value={answerItem ? answerItem.content : ''}
            onChange={onChange}
          />
        </AdminCard>
        <Row mt={10} justifyContent="space-between">
          <Button
            label="목록으로"
            buttonType="capsule"
            type="outline"
            buttonColor="adminBlue"
            width={105}
            onClick={() => navigate(-1)}
            active
          />
          <Row gap={10}>
            <Button
              label="삭제"
              buttonType="capsule"
              onClick={deleteItem}
              type="outline"
              buttonColor="adminRed"
              width={105}
              active
            />
            <Button
              label={answerItem?.content ? '수정' : '확인'}
              onClick={
                answerItem && answerItem.content ? editItem : () => navigate(-1)
              }
              buttonType="capsule"
              buttonColor="lightGreen"
              width={105}
              active
            />
          </Row>
        </Row>
      </AdminContainer>
    )
  );
}

export default DetailPresenter;
