import React, { useState } from 'react';
import { Div, Span, Row, Text, Col } from 'differoute-styles';
import { FocusInText, FocusInSpan } from 'styles/Keyframes';
import list from 'data/lang/main/visual';
import { CustomImage } from 'styles/CustomStyles';
import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { useCookies } from 'react-cookie';

function VisualSlider(props) {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [cookies] = useCookies();

  return (
    <Div position="relative" width="100vw" height="100vh" mHeight="100dvh">
      <Div
        position="absolute"
        bottom="40px"
        mBottom="50px"
        left="50%"
        transform="translateX(-50%)"
        zIndex={10}
        maxWidth={1360}
        mMaxWidth="100%"
        width="100%"
        padding="0 30px"
      >
        <Row
          height={20}
          gap={10}
          justifyContent="flex-end"
          alignItems="center"
          mJustifyContent="center"
        >
          <Text color="g500" type="b1" mType="b1">
            <Span color="white">
              {currentSlide + 1 < 10
                ? `0${currentSlide + 1}`
                : currentSlide + 1}
            </Span>
            <Span margin="0 15px">/</Span>
            {list.length < 10 ? `0${list.length}` : list.length}
          </Text>
        </Row>
      </Div>
      <Swiper
        effect="fade"
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        speed={2000}
        modules={[Autoplay]}
        className="mySwiper"
        loop
        onSlideChange={(e) => setCurrentSlide(e.realIndex)}
      >
        {list.map((main, index) => (
          <SwiperSlide key={`main_${index}`}>
            <Div position="relative" height="100%">
              <CustomImage
                alt="visualImage"
                src={main.img}
                style={{ filter: 'brightness(50%)', objectFit: 'cover' }}
              />
              <Col
                gap={60}
                position="absolute"
                width="100%"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
              >
                <FocusInText
                  fontSize={90}
                  mFontSize={46}
                  lineHeight="120%"
                  color="g0"
                  textAlign="center"
                >
                  <FocusInSpan fontWeight={800} mFontSize={60}>
                    {main.title[cookies.language].bold}
                    <br />
                  </FocusInSpan>
                  {main.title[cookies.language].normal}
                </FocusInText>
                <FocusInText
                  fontSize={28}
                  mType="h4"
                  lineHeight="33.6px"
                  color="g0"
                  textAlign="center"
                >
                  {main.desc[cookies.language]}
                </FocusInText>
              </Col>
            </Div>
          </SwiperSlide>
        ))}
      </Swiper>
    </Div>
  );
}

export default VisualSlider;
