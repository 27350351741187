import { Div, Text } from 'differoute-styles';
import React from 'react';
import { useCookies } from 'react-cookie';
import boardLang from 'data/lang/main/board';

function Tag({ item }) {
  const [cookies] = useCookies();

  return (
    <Div
      borderRadius={6}
      padding="4px 8px"
      width="fit-content"
      height="fit-content"
      border={{ color: item === 1 ? 'primary' : 'g200' }}
      backgroundColor={item === 1 ? 'primary' : 'transparent'}
      color={item === 1 ? 'white' : 'g300'}
    >
      <Text type="b3">
        {item === 1
          ? boardLang[cookies.language].question_qna_complete_label
          : boardLang[cookies.language].question_qna_wait_label}
      </Text>
    </Div>
  );
}

export default Tag;
