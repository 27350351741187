import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useCookies } from 'react-cookie';
import { Div } from 'differoute-styles';

function MapComponent() {
  const ref = useRef();
  const [cookies] = useCookies();
  const [iframeContent, setIframeContent] = useState(null);

  const enUrl =
    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3235.29150445324!2d127.14205229999999!3d35.817323!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357025370b5004b5%3A0x2808c483b7517f4f!2z7KCE67aB7Ie87ZWR7Yq4656Y67iU65287Jq07KeA!5e0!3m2!1sen!2skr!4v1686905271527!5m2!1sen!2skr';
  const koUrl =
    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3235.29150445324!2d127.14205229999999!3d35.817323!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357025370b5004b5%3A0x2808c483b7517f4f!2z7KCE67aB7Ie87ZWR7Yq4656Y67iU65287Jq07KeA!5e0!3m2!1sko!2skr!4v1686906664623!5m2!1sko!2skr';
  const jpUrl =
    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3235.29150445324!2d127.14205229999999!3d35.817323!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357025370b5004b5%3A0x2808c483b7517f4f!2z7KCE67aB7Ie87ZWR7Yq4656Y67iU65287Jq07KeA!5e0!3m2!1sja!2skr!4v1686906748925!5m2!1sja!2skr';
  const cnUrl =
    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3235.29150445324!2d127.14205229999999!3d35.817323!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357025370b5004b5%3A0x2808c483b7517f4f!2z7KCE67aB7Ie87ZWR7Yq4656Y67iU65287Jq07KeA!5e0!3m2!1szh-CN!2skr!4v1686906809795!5m2!1szh-CN!2skr';

  const stopScroll = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const stop = () => {
    ref.current.addEventListener('wheel', stopScroll);
    return () => {
      ref.current.removeEventListener('wheel', stopScroll);
    };
  };

  useEffect(() => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    setIframeContent(`<iframe
          src="${
            (cookies.language === 'en' && enUrl) ||
            (cookies.language === 'ko' && koUrl) ||
            (cookies.language === 'jp' && jpUrl) ||
            (cookies.language === 'cn' && cnUrl)
          }"
          style="border:0; width:100%; height: ${
            (isMobile && '350px') || '506px'
          }"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>`);
  }, []);

  return (
    <Wrapper ref={ref} onMouseMove={stop} onMouseOver={stop}>
      {iframeContent && (
        <Div
          dangerouslySetInnerHTML={{
            __html: iframeContent,
          }}
        />
      )}
    </Wrapper>
  );
}
const Wrapper = styled.div`
  .map-container {
    width: 100%;
    height: 506px;
    border-radius: 6px;
  }

  @media ${({ theme }) => theme.device.mobile} {
    .map-container {
      width: 100%;
      height: 350px;
      border-radius: 6px;
    }
  }
`;

export default MapComponent;
