/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef, useState } from 'react';
import { Icon, Div, Text, Col, InputLabel, Row } from 'differoute-styles';
import styled from 'styled-components';

function AdminDropDown({
  label,
  labelPoint,
  list,
  placeholder,
  value,
  name,
  customItem,
  onChange,
  disabled,
  readOnly,
  position,
  inputType,
  ...props
}) {
  const [open, setOpen] = useState(false);
  const itemRef = useRef([]);

  const onChangeHandler = (selectItem) => {
    onChange(selectItem, name);
    setOpen(false);
  };

  const selected = (item) =>
    (customItem && customItem(item) === customItem(value)) || item === value;

  return (
    <>
      {label && <InputLabel label={label} labelPoint={labelPoint} />}
      <DropdownStyles position="relative" open={open} {...props}>
        <DropdownWrap>
          <label htmlFor={`dropdown-system-${name}`}>
            <Row
              className="input"
              disabled={disabled}
              onMouseDown={(e) => {
                e.stopPropagation();
                e.preventDefault();
                if (!disabled) {
                  setOpen(!open);
                }
              }}
            >
              <Text
                color={(value && 'g900') || 'g300'}
                type="b2"
                fontSize={(!value && 15) || null}
                fontWeight={(!value && 300) || 400}
              >
                {(customItem && customItem(value)) || value || placeholder}
              </Text>
              <Icon
                name="arrow"
                fill={(open && 'primary') || 'g300'}
                transform={(open && 'rotate(-90deg)') || 'rotate(90deg)'}
                width={23}
                height={23}
                mWidth={18}
                mHeight={18}
                top="50%"
              />
            </Row>
          </label>
        </DropdownWrap>
        <Col
          display={(open && 'flex') || 'none !important'}
          width={130}
          maxHeight={238}
          border={{ color: 'g300' }}
          padding="0.1rem"
          backgroundColor="white"
          position="absolute"
          top="calc(100% + 1px)"
          left={0}
          zIndex={10}
          gap={2}
        >
          {list?.map((item, index) => (
            <Div
              className="dropdown-item"
              key={`${name}-${index}`}
              padding="0.6rem 1rem"
              cursor="pointer"
              backgroundColor={(selected(item) && 'primaryOpacity20') || ''}
              onMouseDown={() => onChangeHandler(item)}
              setRef={(el) => (itemRef.current[index] = el)}
            >
              <Text color={(selected(item) && 'black') || 'gray8'}>
                {(customItem && customItem(item)) || item}
              </Text>
            </Div>
          ))}
        </Col>
      </DropdownStyles>
    </>
  );
}

const DropdownStyles = styled(Div)`
  width: 130px;
  padding: 10px;
  border-bottom: 1px solid
    ${({ theme, open }) => (open ? theme.colors.primary : theme.colors.g300)};

  .dropdown-item {
    @media (hover: hover) and (pointer: fine) {
      transition: all 150ms;
      &:hover {
        background-color: ${({ theme }) => theme.colors.primaryOpacity10};
      }
    }
  }
`;

const DropdownWrap = styled(Div)`
  position: relative;
  width: 100%;

  & > label > .hidden-input {
    border: 0;
    clip: rect(0 0 0 0);
    height: 0px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 0px;
  }

  & > label > div {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 10px;
    z-index: -1; /* IE8에서 label이 위치한 곳이 클릭되지 않는 것 해결 */
  }
`;
export default AdminDropDown;
