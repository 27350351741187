export const languageList = [
  { label: '한국어', value: 'ko' },
  { label: '영어', value: 'en' },
  { label: '중국어', value: 'cn' },
  { label: '일본어', value: 'jp' },
];

export const goodsCategory = [
  { label: '전북', value: 'jb' },
  { label: '기타', value: 'etc' },
];

export const tourCategory = [
  { label: '당일', value: 'daytrip' },
  { label: '숙박', value: 'lodge' },
];

export const adminRoutes = [
  {
    name: '커뮤니티',
    path: 'community',
    subRoutes: [
      {
        name: '공지사항',
        pathname: 'announcement',
      },
      {
        name: '보도자료',
        pathname: 'press',
      },
    ],
  },
  {
    name: '문의',
    subRoutes: [
      {
        name: '자주 묻는 질문',
        pathname: 'faq',
      },
      {
        name: 'Q&A',
        pathname: 'question',
      },
    ],
  },
  {
    name: '투어 & 상품',
    subRoutes: [
      {
        name: '여행',
        pathname: `tour/daytrip`,
      },
      {
        name: '굿즈',
        pathname: `goods/jb`,
      },
    ],
  },
];
