import {
  Button,
  Col,
  Div,
  Input,
  Loading,
  PopUp,
  Text,
  Textarea,
} from 'differoute-styles';
import React, { useState, useEffect } from 'react';
import { userApi } from 'api';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import boardLang from 'data/lang/main/board';
import QnaPresenter from './QnaPresenter';

function QnaContainer(props) {
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const [popupOpen, setPopupOpen] = useState();
  const [title, setTitle] = useState('');
  const [question, setQuestion] = useState('');
  const [params] = useSearchParams();
  const [cookies] = useCookies();

  // filter setting
  const [filters, setFilters] = useState({
    perPage: 10,
    page: params.get('page') || 1,
    lastRowNum: params.get('lastRowNum') || 0,
  });

  const [pages, setPages] = useState({
    currentCount: 0,
    currentPage: 1,
    perPage: 10,
    totalPages: 0,
    totalCount: 0,
  });

  // get api
  const [list, setList] = useState();
  const getList = async () => {
    const [info] = await userApi.questionApi.question(filters);
    if (info && info.resCode === 200) {
      const { result } = info;
      setPages({
        currentCount: result.currentCount,
        currentPage: result.currentPage,
        perPage: result.perPage,
        totalPages: result.totalPages,
        totalCount: result.totalCount,
      });
      setList(result.list);
      setLoading(false);
    } else {
      setLoading(false);
      console.log('error');
    }
  };

  // init setting
  useEffect(() => {
    if (filters) {
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set('page', `${filters.page}`);
      searchParams.set('lastRowNum', `${filters.lastRowNum}`);
      searchParams.set('perPage', 10);
      const goUrl = `?${searchParams.toString()}`;
      navigate(`${goUrl}`, {
        replace: true,
      });
      getList();
    }

    return () => setLoading(true);
  }, [filters]);

  const onChange = (value) => {
    setQuestion(value);
  };

  // detail
  const goDetail = (item) => {
    const goUrl = `?${params.toString()}`;
    navigate(`${item.id}${goUrl}`);
  };

  //  submit
  const submit = async () => {
    if (question && title) {
      const [info] = await userApi.questionApi.userQuestion({
        title,
        content: question,
      });
      if (info) {
        window.location.reload();
      } else {
        console.log('error');
      }
    }
  };
  return (
    <>
      <QnaPresenter
        navigate={navigate}
        location={location}
        setPopupOpen={setPopupOpen}
        goDetail={goDetail}
        list={list}
        pages={pages}
        filters={filters}
        setFilters={setFilters}
      />
      {popupOpen && (
        <PopUp minWidth="320px !important" padding="30px 0">
          {popupOpen === 'question' && (
            <>
              <Text type="h5" mType="h5" textAlign="center">
                {boardLang[cookies.language].question_qna_modal_title}
              </Text>
              <Div position="relative" mt={10}>
                <Input
                  placeholder={
                    boardLang[cookies.language]
                      .question_qna_modal_title_placeholder
                  }
                  mb={10}
                  value={title}
                  onChange={(value) => setTitle(value)}
                />
                <Textarea
                  placeholder={
                    boardLang[cookies.language]
                      .question_qna_modal_content_placeholder
                  }
                  height={172}
                  maxLength={1000}
                  onChange={onChange}
                >
                  <Text
                    color="g300"
                    type="b3"
                    position="absolute"
                    bottom={10}
                    right={10}
                  >
                    {(!question && '0') || question.length} / 1000
                  </Text>
                </Textarea>
              </Div>
              <Col gap={10} mt={10}>
                <Button
                  label={
                    boardLang[cookies.language].question_qna_modal_confirm_label
                  }
                  onClick={() => setPopupOpen('submit')}
                  buttonType="capsule"
                  width="100%"
                  mWidth="100%"
                  active={question !== ''}
                />
                <Button
                  label={
                    boardLang[cookies.language].question_qna_modal_cancel_label
                  }
                  onClick={() => setPopupOpen()}
                  buttonType="capsule"
                  backgroundColor="transparent"
                  type="outline"
                  width="100%"
                  mWidth="100%"
                  style={{ border: 'none' }}
                />
              </Col>
            </>
          )}
          {popupOpen === 'submit' && (
            <>
              <Text type="h5" mType="h5" textAlign="center">
                {boardLang[cookies.language].question_qna_modal_alarm}
              </Text>
              <Text type="b2" color="g500" mt={10} textAlign="center">
                {boardLang[cookies.language].question_qna_modal_recheck_label}
              </Text>
              <Col gap={10} mt={20}>
                <Button
                  label={
                    boardLang[cookies.language].question_qna_modal_confirm_label
                  }
                  onClick={submit}
                  buttonType="capsule"
                  width="100%"
                  mWidth="100%"
                  active
                />
                <Button
                  label={
                    boardLang[cookies.language].question_qna_modal_cancel_label
                  }
                  onClick={() => setPopupOpen()}
                  buttonType="capsule"
                  backgroundColor="transparent"
                  type="outline"
                  width="100%"
                  mWidth="100%"
                  style={{ border: 'none' }}
                />
              </Col>
            </>
          )}
        </PopUp>
      )}
      {loading && <Loading loading={loading} />}
    </>
  );
}

export default QnaContainer;
