import styled, { css } from 'styled-components';
import { Col, Row, Text, Div, ImageComponent } from 'differoute-styles';

export const IconStyle = styled(Div)`
  line-height: 0;
  padding: ${({ padding }) => (padding && padding) || '10px'};
  cursor: pointer;

  & > svg {
    vertical-align: top;
    display: inline-block;
    width: 100%;
    height: 100%;
    ${({ iconTransform }) =>
      (iconTransform && `transform:${iconTransform}`) || null};
    ${({ theme, fill }) =>
      (fill && `fill: ${theme.colors[fill] || fill};`) || ''}
    ${({ theme, stroke }) =>
      (stroke && `stroke: ${theme.colors[stroke] || stroke};`) || ''}
  }
  & > svg > path {
    ${({ theme, pathFill }) =>
      (pathFill && `fill: ${theme.colors[pathFill] || pathFill};`) || ''}
    ${({ theme, pathStroke }) =>
      (pathStroke && `stroke: ${theme.colors[pathStroke] || pathStroke};`) ||
      ''}
  }

  @media ${({ theme }) => theme.device.mobile} {
    & > svg > path {
      ${({ theme, mPathFill }) =>
        (mPathFill && `fill: ${theme.colors[mPathFill] || mPathFill};`) || ''}
    }
  }
`;

export const HoverImage = styled(ImageComponent)`
  transtion: all 0.3s ease-in-out;
  &:hover {
    filter: drop-shadow(0px 6px 24px rgba(68, 73, 77, 0.1));
  }
`;

export const CustomImage = styled.img`
  width: 100vw;
  height: 100dvh;

  @media ${({ theme }) => theme.device.mobile} {
    object-fit: cover;
  }
`;

export const Line = styled(Div)`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme && theme.colors.g300};
`;

export const MobileMenu = styled(Div)`
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    background-color: #e44b79;
    transition: all 0.5s ease;
  }

  ${({ selected }) =>
    selected &&
    css`
      &:before {
        width: 1px;
        height: 100%;
        transition-delay: 0.2s;
      }
      & > p {
        font-weight: 700;
      }
    `}
`;

export const Section = styled.section`
  position: relative;
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : '100vw')};
  background-color: ${({ backgroundColor, theme }) =>
    (backgroundColor && (theme.colors[backgroundColor] || backgroundColor)) ||
    null};
  padding: ${({ padding }) => padding || null};
  width: 100%;
  height: 100dvh;
  margin: 0 auto;

  @media ${({ theme }) => theme.device.mobile} {
    height: unset;
  }
`;

export const ColMenu = styled(Col)`
  width: 100%;
  height: 100%;
  max-width: 96px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 0;
    height: 1px;
    background-color: #e44b79;
    transition: all 0.5s ease;
  }

  &:hover {
    &:after {
      transition-delay: 0.2s;
      width: 100%;
    }
    & > p {
      transition: all 0.5s ease;
      font-weight: 700;
    }
  }


  ${({ selected }) =>
    selected &&
    css`
      &:after {
        width: 100%;
        transition-delay: 0.2s;
      }
      & > p {
        font-weight: 700;
      }
    `}

  @media ${({ theme }) => theme.device.mobile} {
    min-width: 84px;

   
     &:after {
      bottom: 0px;
    }
   
`;

export const TextMenu = styled(Text)`
  font-weight: 400;
  color: ${({ index, location }) =>
    index > 1 || location !== '/' ? 'black' : 'white'};
  transition: 0.2s ease-in;
`;

export const IconRow = styled(Row)`
  ${({ index }) =>
    index > 1 &&
    css`
      & > svg > path {
        fill: black;
      }
      & > svg > g > path {
        fill: black;
      }
    `}
`;

export const ColLang = styled(Col)`
  transition: 0.25s linear;

  & > p {
    &:after {
      content: '';
      position: absolute;
      bottom: -6px;
      left: 0;
      width: 0;
      height: 1px;
      background-color: ${({ theme }) => theme.colors.primary};
    }
  }

  ${({ selected }) =>
    selected &&
    css`
      & > p {
        &:after {
          content: '';
          position: absolute;
          bottom: -6px;
          left: 0;
          width: 100%;
          height: 1px;
          background-color: ${({ theme }) => theme.colors.primary};
        }
      }
    `}

  &:hover {
    & > p {
      color: ${({ theme }) => theme.colors.primary};
      font-weight: 700;

      &:after {
        width: 100%;
      }
    }
  }
`;

export const MobileHeaderDiv = styled(Div)`
  ${({ scrollPosition }) =>
    scrollPosition > 1 &&
    css`
      backdrop-filter: blur(20px);
      -webkit-backdrop-filter: blur(20px);
    `}
`;
