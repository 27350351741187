import React from 'react';
import styled from 'styled-components';
import { Icon, Row, hexToRGB } from 'differoute-styles';

function AdminPagination({ pages, filters, setFilters, ...props }) {
  const changePage = (i) => {
    if (i >= 0 && i <= pages.totalPages && pages.currentPage !== i) {
      setFilters({
        ...filters,
        page: i,
        lastRowNum: filters.perPage * (i - 1),
      });
    }
  };

  const pageNum = () => {
    const currentGroup = Math.floor((pages.currentPage - 1) / 5);
    const forArray = [];
    const lastIndex =
      pages.totalPages > currentGroup * 5 + 5
        ? currentGroup * 5 + 5
        : pages.totalPages;

    for (let i = currentGroup * 5; i < lastIndex; i += 1) {
      forArray.push(
        <PaginationButtonStyles
          key={i}
          active={i + 1 === pages.currentPage}
          onClick={() => {
            changePage(i + 1);
          }}
        >
          {i + 1}
        </PaginationButtonStyles>,
      );
    }

    return forArray;
  };

  return (
    (pages && (
      <PaginationStyles {...props}>
        {Math.floor((pages.currentPage - 1) / 5) !== 0 && pages.currentPage && (
          <Icon
            className="pagination-button"
            name="arrow"
            fill="g500"
            width={25}
            height={25}
            transform="rotate(180deg)"
            onClick={() =>
              changePage((Math.floor((pages.currentPage - 1) / 5) - 1) * 5 + 1)
            }
          />
        )}
        {pageNum()}
        {pages.totalPages > Math.floor((pages.currentPage - 1) / 5) * 5 + 5 &&
          pages.currentPage && (
            <Icon
              className="pagination-button"
              name="arrow"
              fill="g500"
              width={25}
              height={25}
              onClick={() =>
                changePage(Math.floor((pages.currentPage - 1) / 5) * 5 + 6)
              }
            />
          )}
      </PaginationStyles>
    )) || <></>
  );
}

const PaginationStyles = styled(Row)`
  gap: 10px;
  align-items: center;
  justify-content: center;
  text-align: center;

  & > .pagination-button {
    border-radius: 9px;
    cursor: pointer;
    border: 1px solid ${({ theme }) => theme.colors.g200};

    & > svg > path {
      width: 5px;
      height: 10px;
    }
    &:active {
      background-color: ${({ theme }) => theme.colors.b100};
      & > svg {
        fill: ${({ theme }) => theme.colors.primary};
      }
    }
  }
`;

const PaginationButtonStyles = styled.button`
  width: 25px;
  height: 25px;
  border-radius: 9px;
  color: ${({ theme }) => theme.colors.g300};

  &:hover {
    background-color: ${({ theme }) => hexToRGB(theme.colors.primary, 0.3)};
    color: white;
  }

  ${({ active, theme }) =>
    active &&
    ` 
    background-color: ${theme.colors.b100};
    color: ${theme.colors.primary};
`}
`;

export default AdminPagination;
