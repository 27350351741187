import styled from 'styled-components';
import { Row, hexToRGB } from 'differoute-styles';

export const PaginationStyles = styled(Row)`
  gap: 10px;
  align-items: center;
  justify-content: center;
  text-align: center;

  & > .pagination-button {
    border-radius: 9px;
    cursor: pointer;

    &:hover {
      background-color: ${({ theme }) => hexToRGB(theme.colors.primary, 0.3)};
      & > svg {
        fill: white;
      }
    }

    &:active {
      background-color: ${({ theme }) => theme.colors.primary};
      & > svg {
        fill: white;
      }
    }
  }
`;

export const PaginationButtonStyles = styled.button`
  width: 25px;
  height: 25px;
  border-radius: 9px;
  color: ${({ theme }) => theme.colors.g300};

  &:hover {
    background-color: ${({ theme }) => hexToRGB(theme.colors.primary, 0.3)};
    color: white;
  }

  ${({ active, theme }) =>
    active &&
    `
    background-color:${theme.colors.primary} ;
    color:  white;
  `}
`;
