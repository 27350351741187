import AdminCard from 'components/Admin/AdminCard';
import {
  Input,
  Row,
  Text,
  Button,
  Div,
  InputFile,
  Loading,
} from 'differoute-styles';
import { languageList, goodsCategory } from 'data/admin';
import Writer from 'components/Editor/Writer';
import AdminContainer from 'layouts/AdminContainer';
import React, { useState, useEffect } from 'react';
import AdminCheckbox from 'components/Admin/AdminCheckbox';
import { useLocation, useNavigate } from 'react-router-dom';
import AdminDropDown from 'components/Admin/AdminDropdown';
import { adminApi } from 'api';
import { isEmptyObjItems } from 'utils/checkData';

function EditorWrite(props) {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [values, setValues] = useState();
  const [loading, setLoading] = useState(false);

  // init values setting
  useEffect(() => {
    if (state && state === 'announcement') {
      setValues({
        lang: '',
        title: '',
        isMarked: '0',
        content: '',
        attachmentFile: null,
        attachmentFileName: null,
      });
    } else if (state === 'faq') {
      setValues({ lang: '', question: '', answer: '' });
    } else if (state === 'goods') {
      setValues({
        lang: '',
        title: '',
        category: '',
        content: '',
        price: '',
        attachmentFile: '',
        attachmentFileName: '',
      });
    }
  }, []);

  const onChange = (value, name, fileName) => {
    if (name === 'attachmentFile' && !value) {
      setValues({
        ...values,
        attachmentFile: '',
        attachmentFileName: '',
      });
    } else if (fileName) {
      setValues({
        ...values,
        [name]: value.fileUrl,
        attachmentFileName: fileName,
      });
    } else if (name === 'lang' || name === 'category') {
      setValues({ ...values, [name]: value.value });
    } else {
      setValues({ ...values, [name]: value });
    }
  };

  // img upload api
  const uploadFile = async (file) => {
    if (state === 'goods') {
      const [info] = await adminApi.ProductApi.goods.fileUpload(
        values.category,
        file,
      );
      return info ? info.result : false;
    }
  };

  // submit api
  const submit = async () => {
    setLoading(true);
    if (state === 'announcement') {
      // 공지사항
      const [info, error] = await adminApi.communityApi.notice.write(values);
      if (info && info.resCode === 200) {
        setLoading(false);
        navigate(-1);
      } else if (error.resCode === 401) {
        sessionStorage.removeItem('adminTokenKey');
        setLoading(false);
        navigate('/admin/login');
      } else {
        setLoading(false);
        console.log('error');
      }
    } else if (state === 'faq') {
      // 자주 묻는 질문
      const [info, error] = await adminApi.questionApi.faq.write(values);
      if (info && info.resCode === 200) {
        setLoading(false);
        navigate(-1);
      } else if (error.resCode === 401) {
        sessionStorage.removeItem('adminTokenKey');
        setLoading(false);
        navigate('/admin/login');
      } else {
        setLoading(false);
        console.log('error');
      }
    } else if (state === 'goods') {
      // 상품
      const [info, error] = await adminApi.ProductApi.goods.write(
        values.category,
        values,
      );
      if (info && info.resCode === 200) {
        setLoading(false);
        navigate(-1);
      } else if (error.resCode === 401) {
        sessionStorage.removeItem('adminTokenKey');
        setLoading(false);
        navigate('/admin/login');
      } else {
        setLoading(false);
        console.log('error');
      }
    }
  };

  // button active
  const [submitActive, setSubmitActive] = useState(false);
  useEffect(() => {
    if (state === 'announcement' && values) {
      if (!values.attachmentFile) {
        delete values.attachmentFile;
        delete values.attachmentFileName;
      }
      setSubmitActive(
        !isEmptyObjItems({
          ...values,
          isMarked: String(values.isMarked),
        }),
      );
    } else {
      setSubmitActive(!isEmptyObjItems({ ...values }));
    }
  }, [values]);

  return (
    values && (
      <>
        <AdminContainer title="새 글 등록하기">
          <AdminCard>
            <Row gap={10}>
              <Div>
                <AdminDropDown
                  label="언어"
                  placeholder="언어 선택"
                  name="lang"
                  list={languageList}
                  customItem={(el) => el?.label}
                  value={languageList?.find((el) => el?.value === values.lang)}
                  inputType="underline"
                  onChange={onChange}
                />
              </Div>
              {state === 'goods' && (
                <Div>
                  <AdminDropDown
                    label="타입"
                    placeholder="타입 선택"
                    name="category"
                    list={goodsCategory}
                    customItem={(el) => el?.label}
                    value={goodsCategory?.find(
                      (el) => el?.value === values.category,
                    )}
                    inputType="underline"
                    onChange={onChange}
                  />
                </Div>
              )}
              <Input
                label="제목"
                name={(state === 'faq' && 'question') || 'title'}
                placeholder="제목을 입력하세요"
                inputType="underline"
                onChange={onChange}
                width="100%"
              />
            </Row>
            {state === 'announcement' && (
              <Div width={77} margin="20px 0">
                <AdminCheckbox
                  checked={values?.isMarked === 1 || false}
                  name="isMarked"
                  value={values?.isMarked}
                  onChange={(value, name) => {
                    onChange((value === '0' && 1) || 0, name);
                  }}
                >
                  <Text type="h8" color="g500">
                    상단고정
                  </Text>
                </AdminCheckbox>
              </Div>
            )}
            {state === 'goods' && (
              <>
                <Input
                  label="가격"
                  name="price"
                  value={values?.price}
                  mt={20}
                  onChange={onChange}
                  inputType="underline"
                />
                <InputFile
                  label="썸네일"
                  value={values?.attachmentFileName || ''}
                  name="attachmentFile"
                  onChange={onChange}
                  placeholder="해당 파일을 첨부하세요"
                  fileUploadApi={uploadFile}
                  mt={20}
                  inputType="underline"
                />
              </>
            )}
            <Text
              type="b2"
              color="g900"
              mt={(state !== 'announcement' && 20) || null}
            >
              내용
            </Text>
            <Writer
              imagePath={
                (state === 'announcement' &&
                  'admin/announcement/upload/editor/file') ||
                null
              }
              state={(state === 'announcement' && 'announcement') || ''}
              name={(state === 'faq' && 'answer') || 'content'}
              value={(state === 'faq' && values.answer) || values.content}
              onChange={onChange}
            />
            <Row mt={10} justifyContent="space-between">
              <Button
                label="목록으로"
                buttonType="capsule"
                onClick={() => navigate(-1)}
                type="outline"
                buttonColor="adminBlue"
                width={105}
                active
              />
              <Row gap={10}>
                <Button
                  label="취소"
                  onClick={() => navigate(-1)}
                  buttonType="capsule"
                  buttonColor="g500"
                  type="outline"
                  border={{ width: 0 }}
                  width={105}
                  active
                />
                <Button
                  label="확인"
                  onClick={submit}
                  buttonType="capsule"
                  buttonColor="lightGreen"
                  width={105}
                  disabled={!submitActive}
                  active={submitActive}
                />
              </Row>
            </Row>
          </AdminCard>
        </AdminContainer>
        {loading && <Loading loading={loading} />}
      </>
    )
  );
}

export default EditorWrite;
