import React from 'react';
import { IconStyle } from 'styles/CustomStyles';
import { ReactComponent as FacebookIcon } from 'assets/icon/facebook.svg';
import { ReactComponent as KakaoIcon } from 'assets/icon/kakao.svg';
import { ReactComponent as InstaIcon } from 'assets/icon/insta.svg';
import { ReactComponent as ArrowIcon } from 'assets/icon/arrow.svg';
import { ReactComponent as LogoIcon } from 'assets/icon/footer_jact.svg';
import { ReactComponent as PpiIcon } from 'assets/icon/footer_ppi.svg';
import { ReactComponent as PinIcon } from 'assets/icon/pin.svg';
import { ReactComponent as DefaultArrowIcon } from 'assets/icon/default_arrow.svg';
import { ReactComponent as LangIcon } from 'assets/icon/lang.svg';
import { ReactComponent as TriangleIcon } from 'assets/icon/triangle.svg';
import { ReactComponent as AdminPinIcon } from 'assets/icon/admin_pin.svg';

function IconDiv({
  size,
  display,
  name,
  fill,
  pathFill,
  mPathFill,
  iconTransform,
  ...props
}) {
  return (
    <IconStyle
      minWidth={size}
      minHeight={size}
      width={size}
      height={size}
      fill={fill}
      pathFill={pathFill}
      mPathFill={mPathFill}
      display={display || 'inline-block'}
      iconTransform={iconTransform}
      {...props}
    >
      {name === 'adminPin' && <AdminPinIcon />}
      {name === 'lang' && <LangIcon />}
      {name === 'triangle' && <TriangleIcon />}
      {name === 'kakao' && <KakaoIcon />}
      {name === 'insta' && <InstaIcon />}
      {name === 'facebook' && <FacebookIcon />}
      {name === 'defaultArrow' && <DefaultArrowIcon />}
      {name === 'pin' && <PinIcon />}
      {name === 'logo' && <LogoIcon />}
      {name === 'ppi' && <PpiIcon />}
      {name === 'arrow' && <ArrowIcon />}
    </IconStyle>
  );
}

export default IconDiv;
