import React, { useEffect, useRef } from 'react';
import { Div, Button, Row } from 'differoute-styles';
import Dim from 'components/Modal/Dim';
import styled from 'styled-components';
import useMobile from 'hooks/useMobile';
import { useCookies } from 'react-cookie';
import goodsLang from 'data/lang/main/goods';

function PopUp({ children, cancel, confirm, link, modalOpen, type, ...props }) {
  const [cookies] = useCookies();
  const mobile = useMobile();
  const divRef = useRef();
  const stop = (e) => {
    e.stopPropagation();
  };
  useEffect(() => {
    if (divRef.current && !mobile) {
      divRef.current.addEventListener('wheel', stop);
    }
  }, [modalOpen]);

  return (
    <RefDiv ref={divRef} style={{ touchAction: 'none' }}>
      <Dim type={type}>
        <Div borderRadius={12} padding="20px" mPadding="20px 0" {...props}>
          <ScrollDiv
            maxHeight="80vh"
            mMaxHeight="80vh"
            mMinHeight="65vh"
            mHeight="80vh"
            height="80vh"
          >
            {children}
          </ScrollDiv>
          {(cancel || confirm || link) && (
            <Row
              justifyContent="center"
              width="100%"
              mt={20}
              margin="auto"
              gap={20}
            >
              {link && (
                <Button
                  mSize="medium"
                  width="100%"
                  maxWidth={100}
                  label={link.label}
                  buttonType="capsule"
                  onClick={confirm.onClick}
                  backgroundColor="transparent"
                  border
                  flex={1}
                  {...link}
                />
              )}
              {confirm && (
                <Button
                  mSize="medium"
                  width="100%"
                  maxWidth={100}
                  label={
                    confirm.label ||
                    goodsLang[cookies.language].closeButtonLabel.label
                  }
                  buttonType="capsule"
                  onClick={confirm.onClick}
                  backgroundColor="transparent"
                  border
                  flex={1}
                  {...confirm}
                />
              )}
              {cancel && (
                <Button
                  mt={10}
                  buttonColor="transparent"
                  color="g500"
                  size="large"
                  mSize="medium"
                  label={
                    cancel.label ||
                    goodsLang[cookies.language].cancelButtonLabel.label
                  }
                  onClick={cancel.onClick}
                  active
                  width="100%"
                  mWidth="100%"
                  flex={1}
                  {...cancel}
                />
              )}
            </Row>
          )}
        </Div>
      </Dim>
    </RefDiv>
  );
}

const RefDiv = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 9999;
`;

const ScrollDiv = styled(Div)`
&::-webkit-scrollbar {
  display:block !important;
`;

export default PopUp;
