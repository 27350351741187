import { Div, Text } from 'differoute-styles';
import React from 'react';

function AdminContainer({ title, children }) {
  return (
    <Div padding="40px 0" minWidth={990} minHeight="100vh">
      <Div mb={30} borderBottom={{ color: 'g200' }}>
        <Text type="h5" color="g600" padding="10px 0">
          {title}
        </Text>
      </Div>
      <Div>{children}</Div>
    </Div>
  );
}

export default AdminContainer;
