import { Col, ImageComponent, Text, Div } from 'differoute-styles';
import React, { useState } from 'react';
import popupImg from 'assets/popup/230426_popup_img.png';
import close from 'assets/icon/modal_close.svg';
import { useCookies } from 'react-cookie';
import styled from 'styled-components';
import goodsLang from 'data/lang/main/goods';
import AdminCheckbox from './Admin/AdminCheckbox';
import PopUp from './Modal/NonScrollPopUp';

function DayModal({ popup, popupList, setPopupList, idx }) {
  const [cookies, setCookie, removeCookie] = useCookies();
  const [hide, setHide] = useState(false);

  const onChange = (v, n, checked) => {
    setHide(checked);
  };
  const getExpiredDate = () => {
    const date = new Date();
    date.setHours(23, 59, 59);
    return date;
  };

  const closeModal = () => {
    const expires = getExpiredDate();
    setCookie(`hide_${popup.id}`, hide, {
      path: '/',
      expires,
    });

    popupList[idx].open = false;
    setPopupList(popupList);
  };

  const popupClick = () => {
    if (popup.url) {
      window.location = popup.url;
    }
  };

  return (
    <PopUp
      maxWidth={500}
      mWidth="calc(100% - 20px)"
      width="100%"
      mMinHeight="fit-content"
      mMaxHeight="fit-content"
      style={{
        backgroundColor: 'white',
      }}
      type="day"
    >
      <Col display="flex" justifyContent="space-between" height="100%">
        <Col alignItems="flex-end" mPadding="0 20px 20px" padding="0 0 20px 0">
          <Col
            width={27}
            height={27}
            backgroundColor="g200"
            borderRadius="50%"
            alignItems="center"
            justifyContent="center"
            cursor="pointer"
            onClick={closeModal}
          >
            <ImageComponent src={close} />
          </Col>
        </Col>
        <ScrollDiv height="auto" overflowY="overlay">
          {/*  <ImageComponent  */}
          {/*  src={popupImg}  */}
          {/*  width={400}  */}
          {/*  margin="auto"  */}
          {/*  cursor="pointer"  */}
          {/*  onClick={popupClick}  */}
          {/*  />  */}
          <Div
            dangerouslySetInnerHTML={{
              __html: popup.content,
            }}
            margin="auto"
            cursor="pointer"
            onClick={popupClick}
          />
        </ScrollDiv>
        <AdminCheckbox
          type="day"
          justifyContent="center"
          mt={20}
          name="hide"
          value={hide}
          checked={hide}
          onChange={onChange}
        >
          <Text type="h6" mType="h6" color={(hide && 'primary') || 'g300'}>
            {goodsLang[cookies.language].closeDayButtonLabel.label}
          </Text>
        </AdminCheckbox>
      </Col>
    </PopUp>
  );
}

const ScrollDiv = styled(Div)`
&::-webkit-scrollbar {
  display:block !important;
`;

export default DayModal;
