import React from 'react';
import { Icon } from 'differoute-styles';
import {
  PaginationStyles,
  PaginationButtonStyles,
} from 'styles/PaginationStyles';

function Pagination({ pages, filters, setFilters, ...props }) {
  const searchParams = new URLSearchParams(window.location.search);
  const changePage = (i) => {
    if (i >= 0 && i <= pages.totalPages && pages.currentPage !== i) {
      setFilters({
        ...filters,
        page: i,
        lastRowNum: filters.perPage * (i - 1),
      });
    }
  };

  const pageNum = () => {
    const currentGroup = Math.floor((pages.currentPage - 1) / 5);
    const forArray = [];
    const lastIndex =
      pages.totalPages > currentGroup * 5 + 5
        ? currentGroup * 5 + 5
        : pages.totalPages;

    for (let i = currentGroup * 5; i < lastIndex; i += 1) {
      forArray.push(
        <PaginationButtonStyles
          key={i}
          active={i + 1 === pages.currentPage}
          onClick={() => {
            changePage(i + 1);
          }}
        >
          {i + 1}
        </PaginationButtonStyles>,
      );
    }

    return forArray;
  };

  return (
    (pages && (
      <PaginationStyles {...props}>
        {Math.floor((pages.currentPage - 1) / 5) !== 0 && (
          <Icon
            className="pagination-button"
            name="arrow"
            fill="g500"
            width={25}
            height={25}
            transform="rotate(180deg)"
            onClick={() =>
              changePage((Math.floor((pages.currentPage - 1) / 5) - 1) * 5 + 1)
            }
          />
        )}
        {pageNum()}
        {pages.totalPages > Math.floor((pages.currentPage - 1) / 5) * 5 + 5 && (
          <Icon
            className="pagination-button"
            name="arrow"
            fill="g500"
            width={25}
            height={25}
            onClick={() =>
              changePage(Math.floor((pages.currentPage - 1) / 5) * 5 + 6)
            }
          />
        )}
      </PaginationStyles>
    )) || <></>
  );
}

export default Pagination;
