// 컨시어지 서비스
import service1st from 'assets/graphic/service/concierge/care.png';
import service2nd from 'assets/graphic/service/concierge/reservation.jpg';
import service3rd from 'assets/graphic/service/concierge/info.jpg';
// 라운지 서비스
import lounge1st from 'assets/graphic/service/lounge/luggage.png';
import lounge2nd from 'assets/graphic/service/lounge/cafe.png';
import lounge3rd from 'assets/graphic/service/lounge/room.png';
import lounge4th from 'assets/graphic/service/lounge/support.png';
import lounge5th from 'assets/graphic/service/lounge/interpret.png';
import lounge6th from 'assets/graphic/service/lounge/exhibit.png';

export const serviceTab = {
  ko: {
    title: '서비스',
    category1: '여행 컨시어지 서비스',
    category2: '라운지 서비스',
  },

  en: {
    title: 'Services',
    category1: 'Concierge Services',
    category2: 'Lounge Services',
  },

  cn: {
    title: '服务',
    category1: '旅游服务',
    category2: '休息室服务',
  },

  jp: {
    title: 'サービス',
    category1: '旅行コンシェルジュサービス',
    category2: 'ラウンジサービス',
  },
};

export const serviceLang = {
  concierge: {
    ko: [
      {
        title: `안심관광 케어 서비스`,
        img: service1st,
        link: 'https://www.easytravelcare.co.kr',
        linkText: '바로가기',
        subTitle: `여행자 안심보험 & 케어서비스 운영`,
        innerSubTitle: `"즐겁고 안전한 관광, 안심보험에서 안심여행까지 !"`,
        desc: [
          `외국인 관광객 니즈에 맞는 맞춤형 안심케어 서비스`,
          `각종 도난사고 보험케어 및 트래블 관련 케어 서비스`,
          `교통사고, 안전사고 등 맞춤형 케어 서비스\n(의료통역사 수배, 의료비 해결방안 모색, 여행자보험&의료지원 등 케어)`,
          `비자 및 긴급 항공권 케어 서비스`,
          `지갑, 여권 등 분실 시 지원 케어 서비스`,
          `여행 도중 긴급사항 발생 시 케어 서비스`,
          `여행자 안심보험\n(여행 중 발생할 수 있는 휴대품 손해, 배상책임, 사망까지)`,
        ],
      },
      {
        title: `교통 숙박 예약 서비스`,
        img: service2nd,
        subTitle: `공항 및 수도권 연계 교통 관광 상품 안내 및 예약`,
        innerSubTitle: `교통편을 취소해야 하나요? 교통편을 예약하지 못하셨나요?
        숙소를 변경하고 싶으신가요? 숙소 찾기가 어려우신가요?
        저희가 도와드립니다!" `,
        desc: [
          `여행객을 위한 인근 교통 및 숙박 정보 제공`,
          `ktx, 항공, 항만, 고속버스 등 대중교통 등 정보 안내 및 예약 지원 서비스`,
          `맞춤형 차량 수배 서비스 안내`,
          `숙박 예약 도움 제공 및 예약 정보 출력 서비스`,
          `숙박 제휴처 할인 혜택 제공`,
          `교통 & 숙박 예약 취소 및 환불 절차 통역 지원`,
        ],
      },
      {
        title: `관광안내 서비스`,
        img: service3rd,
        subTitle: `시티투어 상품 안내 및 판매 & 주변 연계 관광지 및 맛집 안내`,
        innerSubTitle: `무거운 짐 우리한테 맡기고 편안한 여행하세요.
        수하물을 안전하고 확실하게 보관해 드립니다."`,
        desc: [
          `시티 투어 상품 안내`,
          `전북특별자치도 축제 및 행사 정보 제공`,
          `전주 관광, 맛집 정보 제공`,
          `제휴처 할인 혜택 제공`,
          `전북특별자치도 관광기념품 및 지역 특화 상품 판매`,
          `맞춤형 코스 추천 및 투어 운영 지원`,
          `관광 안내 리플릿 배치`,
          `관광 안내 영상 송출`,
        ],
      },
    ],
    en: [
      {
        title: `Safety Tourism Care`,
        img: service1st,
        link: 'https://www.easytravelcare.co.kr',
        linkText: 'Details',
        subTitle: `Traveler Safety Insurance & Care Service`,
        innerSubTitle: `Enjoy safe travel with travel insurance!"`,
        desc: [
          `Insurance care service for stolen wallet, passport and cell phone`,
          `Care service for emergency situations during travel`,
          `Care service for visas and emergency airline tickets`,
          `Customized care service for traffic accidents, safety accidents
(medical interpreter arrangement, finding medical expenses solution,
medical support and care)`,
          `Traveler's worry-free insurance
(Injury, damage belongings, death etc)`,
        ],
      },
      {
        title: `Transportation and Accommodation Reservation`,
        img: service2nd,
        subTitle: `Travel products linked to airports and metropolitan areas`,
        innerSubTitle: `Struggling with booking lodging and transportation?
        We're here to help!"`,
        desc: [
          `Information and reservation support for public transportation 
such as KTX, Plane, Passenger ship, Express & intercity bus
( + printing service )`,
          `Assistance in booking transportation and accommodation`,
          `Interpretation support for cancellation and refund process`,
          `Discount information on affiliate accommodation and transportation`,
        ],
      },
      {
        title: `Tourist Information`,
        img: service3rd,
        subTitle: [
          `Tourist information on Jeonbuk State`,
          `City tour products of Jeonbuk State`,
        ],
        innerSubTitle: `All of Jeonbuk's tourism is here"`,
        desc: [
          `Tourist information on Jeonbuk State`,
          `Jeonbuk State city tour products <Travel Day>`,
          `Festivals and events of Jeonbuk State`,
          `Restaurants and store recommandation`,
          `Customized tour course recommandation`,
          `Discount information on affiliate store`,
          `Promotional tourism videos and leaflets of Jeonbuk State`,
        ],
      },
    ],
    cn: [
      {
        title: `安心观光服务`,
        img: service1st,
        link: 'https://www.easytravelcare.co.kr',
        linkText: '更多信息',
        subTitle: `提供旅行者安心保险服务`,
        innerSubTitle: `既愉快又安全, 从安心保险到安心旅游!"`,
        desc: [
          `旅行者安心保险(损失、赔偿责任、死亡等保险)`,
          `旅游途中发生紧急事项时, 提供各种服务`,
          `遗失钱包、护照等时, 提供支援服务`,
          `针对交通事故、安全事故等的支援服务
(提供医疗翻译、医疗费用解决方案、医疗支援等服务)`,
        ],
      },
      {
        title: `交通·住宿预订服务`,
        img: service2nd,
        subTitle: `提供交通旅游商品及预订指南服务`,
        innerSubTitle: `在预订交通和住宿方面, 遇到困难了吗？我们帮您!"`,
        desc: [
          `交通信息及预订指南(高速巴士、KTX、飞机等)`,
          `住宿信息及预订指南(可打印预订信息)`,
          `预订取消及退款时, 提供翻译服务`,
          `提供合作优惠`,
        ],
      },
      {
        title: `旅游咨询服务`,
        img: service3rd,
        subTitle: `提供旅游信息服务`,
        innerSubTitle: `在一个地方, 享受全北观光"`,
        desc: [
          `提供全北特别自治道City tour商品信息(Travel Day)`,
          `提供全北特别自治道庆典及活动信息`,
          `定制旅游路线指南(交通、住宿、餐厅等)`,
          `提供旅游指南手册及旅游宣传影像`,
          `提供合作优惠`,
        ],
      },
    ],
    jp: [
      {
        title: `安心観光ケアサービス`,
        img: service1st,
        link: 'https://www.easytravelcare.co.kr',
        linkText: 'こちら',
        subTitle: `旅行者安心保険&ケアサービスの運営`,
        innerSubTitle: `たのしく安全な観光、安心保険から安心旅行まで!"`,
        desc: [
          `外国観光客のニーズに合わせたオーダーメイド安心ケアサービス`,
          `各種盗難事故の保険ケア及びトラベル関連ケアサービス`,
          `交通事故、安全事故などのオーダーメイド型ケアサービス\n(医療通訳士の手配、医療費の解決策の模索、旅行者保険&医療支援などのケア)`,
          `ビザ及び緊急航空券ケアサービス`,
          `お財布、パスポート等の紛失した時のサポートケアサービス`,
          `旅行中に緊急事項が発生した場合のケアサービス`,
          `旅行者安心保険\n(旅行中に発生する可能性のある携帯品損害、賠償責任、死亡もで)`,
        ],
      },
      {
        title: `交通・宿泊の予約サービス`,
        img: service2nd,
        subTitle: `空港及び首都圏連携の交通観光商品のご案内及び予約`,
        innerSubTitle: `交通便をキャンセルしなければなりませんか。
        交通便を予約できませんでしたか。
        宿を変更したいですか。
        宿を探すのが難しいですか。
        私たちがお手伝いいたします。"`,
        desc: [
          `旅行者のための近隣交通及び宿泊情報の提供`,
          `ktx、航空、港湾、高速バスなど公共交通機関などの
情報案内及び予約支援サービス`,
          `オーダーメイド型車手配サービスのご案内`,
          `宿泊の予約支援提供及び予約情報の出力サービス`,
          `宿泊提携先に割引特典を提供`,
          `交通&宿泊予約のキャンセルと払い戻し手続きの通訳支援`,
        ],
      },
      {
        title: `観光案内サービス`,
        img: service3rd,
        subTitle: `シティツアー商品のご案内及び販売&周辺連携観光地やグルメ案内`,
        innerSubTitle: `全北の観光一箇所に収める"`,
        desc: [
          `シティツアー商品の案内`,
          `全北特別自治道の代表的な観光地及びツアーコースをおすすめ`,
          `全北特別自治道の祭り及びイベント情報提供`,
          `全州観光、グルメ情報提供`,
          `提携先に割引特典を提供`,
          `全北特別自治道観光記念品及地域特化商品の案内や販売`,
          `オーダーメイド型コースのおすすめとツアー運営支援`,
          `観光案内リーフレット及び映像送出`,
        ],
      },
    ],
  },
  lounge: {
    ko: [
      {
        title: `수하물 보관 서비스`,
        img: lounge1st,
        subTitle: `핸드백, 캐리어 등 여행객의 물품 보관 서비스 운영`,
        innerSubTitle: `무거운 짐, 우리한테 맡기고 편안한 여행하세요.
        수하물을 안전하고 확실하게 보관해 드립니다."`,
        desc: [
          `캐리어, 배낭, 핸드백 등 물품 보관 서비스 운영`,
          `수하물 택 부착으로 혼선 방지`,
          `도난방지 CCTV 작동 중`,
          `최대 28인치 캐리어까지 보관 가능`,
          `직원 수시 확인 및 관리`,
        ],
      },
      {
        title: `여행자 라운지 서비스`,
        img: lounge2nd,
        subTitle: `여행자들의 쉼터(라운지) 공간 운영`,
        innerSubTitle: `여행자들이 쉬어가는 곳"`,
        desc: [
          `약 20명 수용 가능한 라운지 홀`,
          `여행자 네트워킹 장소 (여행자들의 쉼터 제공)`,
          `무료 와이파이존`,
          `무료 휴대폰 충전`,
        ],
      },
      {
        title: `미팅룸 대여 서비스`,
        img: lounge3rd,
        subTitle: `회의실 대여, 노트북, 이동식tv등 회의용 기기 이용`,
        innerSubTitle: `프라이빗한 회의실 대여 서비스"`,
        desc: [
          `최대 8명 수용 가능`,
          `이동식 tv (노트북 연결 가능) 보유`,
          `노트북 대여 운영`,
          `콘센트 보유, 멀티탭 대여 가능`,
          `비즈니스 장소 대여`,
          `인터넷 이용 가능`,
          `무료 와이파이존`,
          `무료 휴대폰 충전`,
        ],
      },
      {
        title: `사무지원 서비스`,
        img: lounge4th,
        subTitle: `인터넷, 복사, 팩스 등 사무지원 서비스 운영`,
        innerSubTitle: `급한 업무가 필요할 땐? 라운지로 오세요."`,
        desc: [
          `노트북 대여 서비스`,
          `미팅룸 대여 서비스`,
          `복사, 스캔, 팩스 사무지원 서비스 운영`,
          `전화기 사용 가능`,
          `비즈니스 장소 대여`,
          `인터넷 이용 가능`,
          `무료 와이파이존`,
          `무료 휴대폰 충전`,
        ],
      },
      {
        title: `통역 서비스`,
        img: lounge5th,
        subTitle: `외국관광객의 언어 소통 불편 해소\n(영어, 중국어, 일본어 가능 직원 근무)`,
        innerSubTitle: `언어가 통하지 않아 불편함을 겪을 때 해결해드립니다."`,
        desc: [
          `영어, 중국어, 일본어 가능 직원 상시 근무`,
          `한국 여행 중 일어나는 언어 소통 불편 해소를 위한 통역서비스`,
          `쇼핑, 교통 등 환불 민원 지원`,
          `예약 서비스 등 문제 발생 시 해결 지원`,
          `비자 분실, 연장 등 재발급 문의 통역 서비스`,
          `지역 상권 연계 관광객 대응 온·오프라인 지원`,
          `기타 문의 및 어려움 발생 시 지원`,
        ],
      },
      {
        title: `이벤트 체험 서비스`,
        img: lounge6th,
        subTitle: `다양한 체험 이벤트 운영`,
        innerSubTitle: `신기하고 재미있는 것들로 가득 찬 전북의 놀이터"`,
        desc: [
          `인생샷을 찍을 수 있는 포토존 운영`,
          `기간별 컨셉 변경으로 다양한 볼거리 제공`,
          `소상공인 연계 체험 이벤트 운영`,
          `작품 전시회 운영 (소규모 공간 대여)`,
        ],
      },
    ],
    en: [
      {
        title: `Luggage Storage`,
        img: lounge1st,
        subTitle: `Free luggage storage service  such as backpacks, handbags and suitcases`,
        innerSubTitle: `Have a comfortable trip
        We will keep your luggage safe"`,
        desc: [
          `Free luggage storage service such as backpacks, handbags and suitcases
(up to 28 inch)`,
          `Anti-theft CCTV operating`,
          `Individual luggage tags`,
          `Staff checks and controls frequently`,
        ],
      },
      {
        title: `Traveler’s Lounge`,
        img: lounge2nd,
        subTitle: `Comfortable lounge where travelers can relax`,
        innerSubTitle: `A place for travelers to relax"`,
        desc: [
          `Comfortable lounge where you can relax (up up to 20)`,
          `Networking area (providing a shelter for travelers)`,
          `Cultural experience space
(last Saturday of even-numbered months)`,
          `Free Wi-Fi zone`,
          `Free cell phone charging`,
        ],
      },
      {
        title: `Meeting Room Rental`,
        img: lounge3rd,
        subTitle: [
          `Private meeting room`,
          `Other devices for business traveler`,
        ],
        innerSubTitle: `Private meeting room rental service"`,
        desc: [
          `Private meeting space (up to 8 people)`,
          `50 inch TV and HDMI cable (connection with laptop)`,
          `220V outlets(socket), power strips`,
          `Laptop rental service (Internal use only, additional charge)`,
          `Free Wi-Fi zone`,
          `Free cell phone charging`,
        ],
      },
      {
        title: `Office Support`,
        img: lounge4th,
        subTitle: `Office support services
(printing, copy, fax, scan etc.)`,
        innerSubTitle: `Do you have urgent work? Come to the lounge"`,
        desc: [
          `Office support services (printing, copy, fax, scan etc.)`,
          `Meeting Room Rental Service`,
          `Laptop rental service`,
          `Free Wi-Fi zone`,
          `Free cell phone charging`,
        ],
      },
      {
        title: `Interpretation Support`,
        img: lounge5th,
        subTitle: `Interpretation support for foreigners
(English, Chinese, and Japanese-speaking staffs)`,
        innerSubTitle: `When you in communication trouble
We will help you"`,
        desc: [
          `Interpretation support to relieve communication inconvenience
while traveling in Korea (English, Chinese, and Japanese-speaking staffs)`,
          `Interpretation support for reservation, cancellation and refund process`,
          `Interpretation support for loss belongings such as passport`,
          `Interpretation support for other inquiries and difficulties`,
        ],
      },
      {
        title: `Exhibition and Event`,
        img: lounge6th,
        subTitle: `Various event experiences`,
        innerSubTitle: `Capture the joyful moment!"`,
        desc: [
          `Photo zone for travelers (regularly updated space)`,
          `Cultural experience activities`,
          `Various events with small business owner`,
          `Exhibition space for Jeonju artists (small space rental)`,
        ],
      },
    ],
    cn: [
      {
        title: `行李寄存服务`,
        img: lounge1st,
        subTitle: `提供物品保管服务`,
        innerSubTitle: `把沉重的行李交给，您轻松出游"`,
        desc: [
          `物品保管服务(手提包、背包、行李箱等)`,
          `行李箱最大尺寸28寸`,
          `贴行李牌, 以防行李混淆`,
          `监控正在运行中`,
        ],
      },
      {
        title: `休息室服务`,
        img: lounge2nd,
        subTitle: `提供旅行者休息处`,
        innerSubTitle: `休息一会儿再走"`,
        desc: [
          `提供舒适的休息空间`,
          `可容纳20人的休息室大厅`,
          `免费Wi-Fi`,
          `免费手机充电`,
        ],
      },
      {
        title: `会议室服务`,
        img: lounge3rd,
        subTitle: `提供会议室租赁服务`,
        innerSubTitle: `为您准备的独立空间"`,
        desc: [
          `最多可容纳8人`,
          `拥有50寸电视机(可租赁笔记本电脑)`,
          `提供打印、复印、扫描、传真等服务`,
          `免费Wi-Fi、免费手机充电`,
        ],
      },
      {
        title: `商务中心服务`,
        img: lounge4th,
        subTitle: `提供复印、传真、免费Wi-Fi等服务`,
        innerSubTitle: `有急事的话，来休息室解决"`,
        desc: [
          `会议室租赁服务(可租赁笔记本电脑)`,
          `提供复印、扫描、传真等服务`,
          `免费Wi-Fi`,
          `免费手机充电`,
        ],
      },
      {
        title: `翻译服务`,
        img: lounge5th,
        subTitle: `解除海外游客的语言沟通问题`,
        innerSubTitle: `遇到沟通困难时，我们帮您"`,
        desc: [
          `提供英语、汉语、日语`,
          `韩国旅游途中遇到困难时, 提供翻译服务`,
          `预订取消及退款时, 提供翻译服务`,
          `通过线上/线下的方式提供支援服务`,
        ],
      },
      {
        title: `体验服务`,
        img: lounge6th,
        subTitle: `提供各种体验活动`,
        innerSubTitle: `神奇有趣的全北体验场"`,
        desc: [
          `为游客提供拍照区`,
          `根据活动定期更换主题`,
          `与工商业者合作并运营体验活动`,
          `运营作品展览会(可租赁小型活动空间)`,
        ],
      },
    ],
    jp: [
      {
        title: `手荷物保管サービス`,
        img: lounge1st,
        subTitle: `ハンドバッグ、キャリアなど旅行者の荷物預かりサービスの運営`,
        innerSubTitle: `重い荷物、私たちに預けて楽な旅をしてください。手荷物は安全かつ確実にお預かりいたします。"`,
        desc: [
          `キャリア、リュックサック、ハンドバッグなどの物品保管サービスの運営`,
          `手荷物タグの付着で混線防止`,
          `盗難防止CCTV作動中`,
          `最大28インチのキャリアまで保管可能`,
          `職員の随時確認及び管理`,
        ],
      },
      {
        title: `旅行者ラウンジサービス`,
        img: lounge2nd,
        subTitle: `旅行者の憩いの場(ラウンジ)空間の運営`,
        innerSubTitle: `旅行者が休む場所"`,
        desc: [
          `約20名収容可能なラウンジホール`,
          `旅行者ネットワーキング場所(旅行者の憩いの場の提供)`,
          `無料Wi-Fiゾン`,
          `無料携帯充電`,
        ],
      },
      {
        title: `ミーティングルームのレンタルサービス`,
        img: lounge3rd,
        subTitle: `会議室のレンタル、ノートパソコン、ポータブルテレビなどの会議用機器を利用`,
        innerSubTitle: `プライベートな会議室レンタルサービス"`,
        desc: [
          `最大8人収容可能`,
          `ポータブルTV(ノートパソコン接続可能)保有`,
          `ノートパソコンのレンタル運営`,
          `コンセント保有、マルチタップのレンタル可能`,
          `ビジネス場所のレンタル`,
          `無料Wi-Fiゾン`,
          `無料携帯充電`,
        ],
      },
      {
        title: `事務支援サービス`,
        img: lounge4th,
        subTitle: `インターネット、コピー、ファックスなどの事務支援サービス運営`,
        innerSubTitle: `急用が必要な時は?ラウンジに来てください。"`,
        desc: [
          `ノートパソコンのレンタルサービス`,
          `ミーティングルームのレンタルサービス`,
          `コピー、スキャン、ファックスなどの事務支援サービスの運営`,
          `ビジネス場所のレンタル`,
          `無料Wi-Fiゾン`,
          `無料携帯充電`,
        ],
      },
      {
        title: `通訳サービス`,
        img: lounge5th,
        subTitle: `外国観光客の言語コミュニケーションの不便解消\n(英語、中国語、日本語対応スタッフ勤務)`,
        innerSubTitle: `言語が通じなくて不便な時に解決いたします"`,
        desc: [
          `英語、中国語、日本語が可能な従業員は常時勤務`,
          `韓国観光中に発生する言語コミュニケーションの不便を解消するための通訳サービス`,
          `ショッピング、交通などの払い戻し苦情支援`,
          `予約サービスなどの問題発生時の解決支援`,
          `ビザの紛失、延長などの再発行に関するお問い合わせの通訳サービス`,
          `地域商圏と連携して観光客をON・OFFラインで支援`,
          `その他のお問い合わせや困難が発生した場合、サポート`,
        ],
      },
      {
        title: `イベント体験サービス`,
        img: lounge6th,
        subTitle: `様々な体験イベントの運営`,
        innerSubTitle: `不思議で面白いものでいっぱいの全北特別自治道の遊び場"`,
        desc: [
          `人生最高の写真が撮れるフォトゾーンの運営`,
          `期間別にコンセプトを変更して様々な見どころを提供`,
          `小商工人連携体験イベントの運営`,
          `作品展示会の運営(小規模の空間レンタル)`,
        ],
      },
    ],
  },
};
