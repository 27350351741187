const header = {
  ko: {
    home: '홈',
    about: '소개',
    service: '서비스',
    tour: '투어',
    goods: '상품',
    place: '위치',
    community: '커뮤니티',
    qna: '문의',
  },
  en: {
    home: 'Home',
    about: 'About us',
    service: 'Service',
    tour: 'Tour',
    goods: 'Product',
    place: 'Location',
    community: 'Community',
    qna: 'Inquiry',
  },
  cn: {
    home: '首页',
    about: '介绍',
    service: '服务',
    tour: '旅游',
    goods: '商品',
    place: '位置',
    community: '信息公开',
    qna: '咨询',
  },
  jp: {
    home: 'ホーム',
    about: '紹介',
    service: 'サービス',
    tour: 'ツアー',
    goods: '商品',
    place: '位置',
    community: 'コミュニティー',
    qna: 'お問い合わせ',
  },
};

export default header;
