import React from 'react';
import { Row, Text, Div } from 'differoute-styles';
import BorderImage from 'components/Common/BorderImage';

function MAbout2({ item, goDetail }) {
  return (
    <Div onClick={() => goDetail(item)}>
      <BorderImage src={item.img} mAspectRatio="59/48" />
      <Text
        mt={14}
        type="h7"
        mType="h7"
        mTextAlign="center"
        pb={14}
        borderBottom={{ color: 'g300' }}
      >
        {item.title}
      </Text>
      <Div mWidth="100%">
        {item.subTitle &&
          item.subTitle.map((title, idx) => (
            <Row key={`title_${idx}`} width="100%">
              <Text type="b3" mType="b2" color="g500" lineHeight="24px">
                -&nbsp;
              </Text>
              <Text type="b3" mType="b2" color="g500" lineHeight="24px">
                {title}
              </Text>
            </Row>
          ))}
      </Div>
    </Div>
  );
}

export default MAbout2;
