import about1st from 'assets/graphic/about/about1.png';
import about2nd from 'assets/graphic/about/about2.png';
import about3rd from 'assets/graphic/about/about3.png';
import about4th from 'assets/graphic/about/about4.png';
import about5th from 'assets/graphic/about/about5.png';
import { Span } from 'differoute-styles';

const about2 = {
  ko: [
    {
      title: '공간 소개',
      subTitle: '다양한 서비스 공간 제공',
      content: [
        {
          title: '전북관광토탈안내존',
          img: about1st,
          subTitle: [
            `주변 연계 관광지 및 맞춤형
            코스 안내`,
            '14개 시·군 시티투어 상품 판매',
            `공항 및 수도권 연계 교통 관광
            상품 운영`,
          ],
          innerSubTitle: `이곳 저곳 다니지 말고,
          전북 관광 여기서 한번에 해결하세요!"`,
          desc: [
            '여행객을 위한 인근 교통 및 숙박 정보 제공',
            '맞춤형 코스 추천 안내(전주 관광, 맛집)',
            `시티 투어 상품 안내`,
            `인천공항 ↔ 전북쇼핑트래블라운지 연계 교통 상품 안내`,
            `용산역 ↔ 전북쇼핑트래블라운지 연계 교통 상품 안내`,
            `제휴처 할인 혜택 제공`,
            `전북특별자치도 축제 및 행사 정보 제공`,
            `<트래블데이>와 연동한 여행 상품 판매`,
          ],
        },
        {
          title: '컨시어지존',
          img: about2nd,
          subTitle: [
            `편안한 지역 관광을 위한
            수하물 보관 서비스`,
            `여행자 안심 보험&
            케어서비스 운영`,
            `복사,팩스,인터넷 등
            비즈니스 컨시어지 서비스`,
          ],
          innerSubTitle: `교통편을 예약하지 못하셨나요? 숙소 찾기 어려우신가요?
          여행 중 무거운 짐이 힘드신가요? 저희가 도와드립니다!"`,
          desc: [
            `캐리어, 배낭, 핸드백 등 물품 보관 서비스`,
            `ktx, 항공, 항만, 고속버스 등 대중교통 예약 지원 서비스`,
            `교통 & 숙박 예약 도움 제공 및 예약 정보 출력 서비스`,
            `교통 & 숙박 & 쇼핑 예약 취소 및 환불 절차 통역 지원`,
            `비지니스 컨시어지 서비스<br/>(미팅룸 대여, 노트북 대여, 프린트, 복사 등 사무지원 서비스)`,
            `여행자 안심보험 / 트래블 관련 케어 서비스`,
          ],
        },
        {
          title: '여행자프리존',
          img: about3rd,
          subTitle: [
            `여행자들이 자유롭고 편안하게
            이용할 수 있는 공간`,
            `관광 홍보 및 다양한 이벤트
            활동 체험 공간`,
          ],
          innerSubTitle: `여행 중 지쳤다면 여행자쉼터에서 한 숨 쉬어가세요"`,
          desc: [
            `무료 와이파이존`,
            `무료 휴대폰 충전`,
            `소상공인 연계 체험 이벤트 기획 및 운영`,
            `여행자를 위한 네트워킹 공간 제공`,
            `편안한 라운지 홀 제공(약 20명 수용)`,
          ],
        },
        {
          title: '이벤트체험존',
          img: about4th,
          subTitle: [
            '관광객들을 위한 포토존 공간',
            `지역 소상공인과 함께하는 
            전시 및 이벤트 공간`,
            `한국의 전통놀이 및
            전통의상(한복) 체험 공간`,
          ],
          innerSubTitle: `즐거운 순간을 기록할 수 있는 이벤트체험존에서
          사진은 물론 전시와 공연 또한 즐겨보세요."`,
          desc: [
            `전통과 멋이 공존하는 공간`,
            `인생샷을 찍을 수 있는 포토존 공간 제공`,
            `작품 전시회 기획 및 운영`,
            `기간별 컨셉 변경으로 다양한 볼거리 제공`,
            `소규모 공연 공간 대여 서비스`,
            `포토존 이벤트 기획 및 운영`,
          ],
        },
        {
          title: '판매존',
          img: about5th,
          subTitle: [
            `전북특별자치도
            관광기념품 판매`,
            `지역 소상공인들의
            수공예 작품 및 상품 판매`,
            '대한민국 대표 인기 상품 판매',
            '자체 제작(굿즈) 상품 판매',
          ],
          innerSubTitle: `아직도 순창에 가 고추장을 사고, 임실에 가 치즈를 사시나요?
          해당 지역을 가지 않아도 지역 상품을 구매할 수 있도록 
          전북특별자치도 상품을 한 곳에 담았습니다."`,
          desc: [
            `전북특별자치도 관광기념품 전시·판매`,
            `지역 소상공인과 연계한 작품 및 상품 판매`,
            `자체제작 상품 판매`,
            `다양한 종류의 관광 기념품`,
            `외국 카드 사용 가능`,
            `관광객 니즈에 맞는 맞춤형 상품 추천`,
          ],
        },
      ],
    },
  ],
  en: [
    {
      title: 'About our spaces',
      subTitle: '“We provide various service spaces”',
      content: [
        {
          title: `Jeonbuk Tourism Information Zone`,
          img: about1st,
          subTitle: [
            'Tourist information on Jeonbuk State',
            'Sales of city tour products <Travel Day>',
            'Tourism product linked to airport and metropolitan areas',
          ],
          innerSubTitle: `All about Jeonbuk tourism here"`,
          desc: [
            'Customized travel course of Jeonju',
            'Assistance in booking transportation and accommodation',
            `Sales of City Tour Products <Travel Day>`,
            `Travel products linked to airports and metropolitan areas<br/>Incheon Airport · Yongsan Station ↔ Jeonbuk Shopping Travel Lounge`,
            `Discount information on affiliate store`,
            `Information on festivals and events in Jeonbuk State`,
          ],
        },
        {
          title: 'Concierge Zone',
          img: about2nd,
          subTitle: [
            'Luggage storage service',
            `Office support  services
            (printing, copy, fax, Internet, etc)`,
            `Traveler’s safety insurance
            (EASY TRAVEL CARE)`,
          ],
          innerSubTitle: `Struggling with booking lodging and transportation?
          Having trouble with heavy luggage during travel?
          We're here to help!"`,
          desc: [
            `Luggage storage service such as backpacks, handbags, etc`,
            `Information and reservation support for public transportation such as KTX, Plane, Passenger ship, Express & intercity bus`,
            `Information and reservation support for accommodation`,
            `Interpretation support for cancellation and refund process`,
            `Office support services<br/>(printing, copy, fax, scan, Internet, meeting room rental, laptop rental, etc.)`,
            `Traveler's safety insurance / Travel-related care service<br/>(EASY TRAVEL CARE)`,
          ],
        },
        {
          title: "Traveler's Free Zone",
          img: about3rd,
          subTitle: ['Comfortable lounge hall', 'Cultural experience space'],
          innerSubTitle: `If you're tired from traveling,
          take a breather at a traveler's free zone"`,
          desc: [
            `Comfortable lounge where you can relax (up to 20 people)`,
            `Networking area (providing a shelter for travelers)`,
            `Cultural experience space<br/>(last Saturday of even-numbered months)`,
            `Various events with small business owners`,
            `Free Wi-Fi zone`,
            `Free cell phone charging`,
          ],
        },
        {
          title: 'Event Zone',
          img: about4th,
          subTitle: [
            'Photo zone',
            'Exhibition and event space',
            `Korean traditional clothes
            and games experience`,
          ],
          innerSubTitle: `Capture a joyful moment in the event zone"`,
          desc: [
            `Photo zone for travelers (regularly updated space)`,
            `Cultural experience activities`,
            `Various events with small business owner`,
            `Exhibition space for Jeonju artists (small space rental)`,
          ],
        },
        {
          title: 'Sales Zone',
          img: about5th,
          subTitle: [
            'Sales of Jeonbuk State souvenirs',
            'Handicrafts by Jeonju artists',
            `Own brand products`,
          ],
          innerSubTitle: `All of Jeonbuk State’s signature products are here!"`,
          desc: [
            `Sales of various Jeonbuk State tourism souvenirs`,
            `Handicraft and souvenir sales by Jeonju artists`,
            `Own brand products and famous Korean products sales`,
            `International card available (VISA, MASTER, … etc)`,
            `Personalized product recommendations`,
          ],
        },
      ],
    },
  ],
  cn: [
    {
      title: '空间介绍',
      subTitle: '提供多种多样的服务空间',
      content: [
        {
          title: '全北旅游咨询区',
          img: about1st,
          subTitle: ['City tour商品信息', '交通旅游商品信息', '定制旅游信息'],
          innerSubTitle: `全北观光, 在这里一次性解决"`,
          desc: [
            '提供全北特别自治道City tour商品信息(Travel Day)',
            '提供全北特别自治道庆典及活动信息',
            `提供交通旅游商品信息(仁川机场，龙山站↔全北购物旅游休息室)`,
            `定制旅游路线指南(交通、住宿、餐厅等)`,
            `提供合作优惠`,
          ],
        },
        {
          title: '礼宾服务区',
          img: about2nd,
          subTitle: ['行李寄存服务', '旅行者安心保险服务', '商务中心服务'],
          innerSubTitle: `还没预订车票吗? 很难找到住宿吗?
          因沉重的行李很累吗? 我们帮您!"`,
          desc: [
            `物品保管服务(手提包、背包、行李箱等)`,
            `交通信息及预订指南(高速巴士、KTX、飞机等)`,
            `住宿信息及预订指南(可打印预订信息)`,
            `预订取消及退款时, 提供翻译服务`,
            `会议室租赁服务(可租赁笔记本电脑)`,
            `提供打印、复印、扫描、传真等服务`,
            `旅行者安心保险 / 旅游相关服务`,
          ],
        },
        {
          title: '旅行者区',
          img: about3rd,
          subTitle: ['旅行者休息处', '体验活动空间'],
          innerSubTitle: `如果在旅游中感到疲劳, 休息一会儿再走"`,
          desc: [
            `提供舒适的休息空间`,
            `可容纳20人的休息室大厅`,
            `与工商业者合作并运营体验活动`,
            `免费Wi-Fi、免费手机充电`,
          ],
        },
        {
          title: '体验活动区',
          img: about4th,
          subTitle: ['拍照区', '作品展览会空间', '韩国传统游戏及韩服'],
          innerSubTitle: `尽情享受展览、演出等各种活动"`,
          desc: [
            `为游客提供拍照区`,
            `根据活动定期更换主题`,
            `运营各种体验活动`,
            `运营作品展览会(可租赁小型活动空间)`,
          ],
        },
        {
          title: '销售区',
          img: about5th,
          subTitle: ['旅游纪念品', '韩国代表人气商品', '当地工商业者的作品'],
          innerSubTitle: `不用去当地也能买到商品"`,
          desc: [
            `销售全北特别自治道旅游纪念品`,
            `销售当地工商业者的作品`,
            `销售自制商品`,
            `为顾客推荐合适的商品`,
            `可用海外卡`,
          ],
        },
      ],
    },
  ],
  jp: [
    {
      title: 'サービスの紹介',
      subTitle: '様々なサービス空間の提供',
      content: [
        {
          title: `全北観光
          トータル案内ゾン`,
          img: about1st,
          subTitle: [
            `周辺連携観光地及び
            オーダーメイド型コースのご案内`,
            '14の市・郡シティツアー商品販売',
            `交通観光商品運営
            (空港及び首都圏連携)`,
          ],
          innerSubTitle: `あちこち通わず、全北観光をここで一度に解決してください。"`,
          desc: [
            '旅行客のための近隣の交通及び宿泊情報の提供',
            'オーダーメイド型コースのおすすめ案内(全州観光、グルメ)',
            `シティツアー商品の案内`,
            `仁川空港↔全北ショッピングトラベルラウンジ連携の交通商品案内`,
            `龍山駅↔全北ショッピングトラベルラウンジ連携の交通商品案内`,
            `提携先に割引特典を提供`,
            `全北特別自治道の市・郡・区の祭り及びイベント情報提供`,
            `<トラベルデー>と連動した旅行商品の販売`,
          ],
        },
        {
          title: 'コンシェルジュゾーン',
          img: about2nd,
          subTitle: [
            `快適な地域観光のための
            手荷物保管サービス`,
            '旅行者安心保険&ケアサービス',
            `コピー、ファックス、インターネット
            などのビジネスコンシェルジュ
            サービス`,
          ],
          innerSubTitle: `交通便を予約できませんでしたか。
          泊を探すのが難しいですか。
          旅行中に重い荷物が大変ですか。
          私たちがお手伝いいたします!`,
          desc: [
            `キャリア、リュックサック、ハンドバッグなどの物品保管サービス`,
            `ktx、航空、旅客船、高速バスなど公共交通の予約サービス`,
            `宿泊の予約支援提供及び予約情報の出力サービス`,
            `交通&宿泊&ショッピング予約のキャンセルと払い戻し手続きの通訳支援`,
            `ビジネスコンシェルジュサービス<br/>(ミーティングルームのレンタル、ノートパソコンのレンタル、プリント、コピーなどの事務支援サービス)`,
            `旅行者安心保険/トラベル関連ケアサービス`,
          ],
        },
        {
          title: '旅行者フリーゾン',
          img: about3rd,
          subTitle: [
            '旅行者が自由で快適に利用できる空間',
            `観光広報及び様
            々なイベント活動体験空間`,
          ],
          innerSubTitle: `旅行中に疲れたら旅行者の憩いの場で一息入れてください。"`,
          desc: [
            `無料Wi-Fiゾン`,
            `無料携帯充電`,
            `小商工人連携体験イベント企画及び運営`,
            `旅行者のためのネットワーキング空間の提供`,
            `快適なラウンジホールの提供(約20名収容)`,
          ],
        },
        {
          title: 'イベント体験ゾン',
          img: about4th,
          subTitle: [
            '観光客のためのフォトゾーン空間',
            `地域の小商工人と共にする展示
            及びイベント空間`,
            `韓国の伝統遊びや伝統衣装
            (韓服ハンボク)体験空間`,
          ],
          innerSubTitle: `楽しい瞬間を記録できるイベント体験ゾンで写真やもちろん、展示や公演もお楽しみください。"`,
          desc: [
            `伝統と趣が共存する空間`,
            `人生最高の写真が撮れるフォトゾーン空間の提供`,
            `作品展示会の企画及び運営`,
            `期間別にコンセプトを変更して様々な見どころを提供`,
            `小規模の公演空間レンタルサービス`,
            `フォトゾーンのイベント企画及び運営`,
          ],
        },
        {
          title: '販売ゾン',
          img: about5th,
          subTitle: [
            '全北特別自治道の観光記念品販売',
            '地域の小商工人の作品や商品販売',
            '韓国を代表する人気商品の販売',
            '自主制作(グッズ)商品販売',
          ],
          innerSubTitle: `まだ淳昌に行ってゴチュジヤンを買って、
          任実に行ってチーズを買いますか?
          該当地域に行かなくても地域商品を購入できるよう
          全北特別自治道の商品を一か所に入れました。"`,
          desc: [
            `全北特別自治道の観光記念品の展示・販売`,
            `地域の小商工人と連携した作品及び商品の販売`,
            `自体製作商品の販売`,
            `様々な種類の観光記念品`,
            `外国カード使用可能`,
            `観光客のニーズに合わせたオーダーメイド商品のおすすめ`,
          ],
        },
      ],
    },
  ],
};

export default about2;
