import React, { useState } from 'react';
import { Text, Div, Row } from 'differoute-styles';
import { ColLang } from 'styles/CustomStyles';
import IconDiv from 'components/Common/IconDiv';
import { useCookies } from 'react-cookie';

function Language({ type, index, scrollPosition, current }) {
  const [cookies, setCookie] = useCookies();
  const languageList = [
    { label: 'KR', language: 'ko' },
    { label: 'EN', language: 'en' },
    { label: 'CN', language: 'cn' },
    { label: 'JP', language: 'jp' },
  ];

  const [isOpen, setIsOpen] = useState(false);

  return (
    <Row
      gap={4}
      justifyContent="center"
      position="relative"
      cursor="pointer"
      onClick={() => setIsOpen((open) => !open)}
    >
      <IconDiv
        name="lang"
        padding="0"
        minWidth={21}
        mWidth={21}
        pathFill={
          index > 1 || scrollPosition > 1 || window.location.pathname !== '/'
            ? 'black'
            : 'white'
        }
      />
      <IconDiv
        minWidth={21}
        mWidth={21}
        name="triangle"
        transform={isOpen ? 'rotate(-180deg)' : 'rotate(0deg)'}
        transition="0.5s ease"
        padding="0"
        pathFill={
          index > 1 || scrollPosition > 1 || window.location.pathname !== '/'
            ? 'black'
            : 'white'
        }
      />
      <Div
        position="absolute"
        top={type === 'mobile' ? 25 : 45}
        right={0}
        backgroundColor="g800"
        borderRadius={6}
        width={46}
        height={isOpen ? 132 : 0}
        transition="height 0.3s ease"
        overflowY="hidden"
      >
        {languageList &&
          languageList.map((lang, index) => (
            <ColLang
              key={`lang_${index}`}
              height={33}
              alignItems="center"
              justifyContent="center"
              selected={cookies.language === lang.language}
              onClick={() => {
                setCookie(
                  'language',
                  (lang.language && lang.language) || 'ko',
                  {
                    path: '/',
                  },
                );
                window.location.reload();
              }}
            >
              <Text
                transition="0.25s linear"
                width="fit-content"
                position="relative"
                lineHeight="21px"
                color={
                  (cookies.language === lang.language && 'primary') || 'g0'
                }
                fontWeight={(cookies.language === lang.language && 700) || 400}
                textAlign="center"
              >
                {lang?.label}
              </Text>
            </ColLang>
          ))}
      </Div>
    </Row>
  );
}

export default Language;
