import { Div, Text, Col, ImageComponent } from 'differoute-styles';
import icon from 'assets/icon/content.svg';
import React from 'react';
import moment from 'moment';
import Tag from 'components/Tag';
import Pagination from 'components/Pagination';
import { useCookies } from 'react-cookie';
import boardLang from 'data/lang/main/board';

function QnaPresenter({
  list,
  pages,
  filters,
  setFilters,
  setPopupOpen,
  goDetail,
}) {
  const [cookies] = useCookies();

  return (
    <Div pt={20}>
      <Div>
        {list &&
          list.map((item) => (
            <Col
              key={item.id}
              borderBottom={{ color: 'g200' }}
              padding="17px 0"
              gap={10}
              cursor="pointer"
              onClick={() => goDetail(item)}
            >
              <Tag item={item.isAnswered} />
              <Text type="h5" mType="h6" maxLine={1}>
                {item.title}
              </Text>
              <Text type="b1" mType="b2" color="g400">
                {moment(item.createdDt).format('YYYY-MM-DD')}
              </Text>
            </Col>
          ))}
      </Div>
      <Pagination
        mt={40}
        pages={pages}
        filters={filters}
        setFilters={setFilters}
      />
      <Col
        cursor="pointer"
        boxShadow={{ color: '#44494D', y: 4, r: 12 }}
        gap={4}
        style={{
          borderRadius: '50%',
          background:
            ' linear-gradient(148.87deg, #E44B79 48.84%, #FAD6E1 120.46%)',
        }}
        position="fixed"
        right="12%"
        ml="50%"
        transform="translateX(90px)"
        mMl="0"
        mMr="50%"
        mRight="calc(-50% - -20px)"
        mTransform="unset"
        bottom={20}
        width={76}
        height={76}
        alignItems="center"
        justifyContent="center"
        onClick={() => setPopupOpen('question')}
        zIndex={10000}
      >
        <ImageComponent src={icon} />
        <Text type="h7" color="white">
          {boardLang[cookies.language].question_qna_modal_title}
        </Text>
      </Col>
    </Div>
  );
}

export default QnaPresenter;
