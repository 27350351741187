import Tag from 'components/Tag';
import { Div, Text, Button, Row } from 'differoute-styles';
import React from 'react';
import moment from 'moment';
import { useCookies } from 'react-cookie';
import boardLang from 'data/lang/main/board';

function DetailPresenter({ answerItem, questionItem, navigate, setPopupOpen }) {
  const [cookies] = useCookies();

  return (
    <Div maxWidth={1024} margin="auto">
      <Row
        pb={20}
        borderBottom={{ color: 'g200' }}
        alignItems="center"
        justifyContent="space-between"
      >
        <Row alignItems="center" gap={10}>
          <Tag item={questionItem?.isAnswered} />
          <Text type="h5" mType="h6" maxLine={1}>
            {questionItem?.title}
          </Text>
        </Row>
        <Text type="b1" mType="b2" color="g400">
          {moment(questionItem?.createdDt).format('YYYY-MM-DD')}
        </Text>
      </Row>
      <Div padding="20px 0 30px" mPadding="16px 0 40px">
        <Text color="g500">{questionItem?.content}</Text>
      </Div>
      {answerItem && (
        <Div
          backgroundColor="g100"
          padding="30px"
          mPadding="20px"
          borderRadius={10}
        >
          <Row
            pb={20}
            borderBottom={{ color: 'g200' }}
            justifyContent="space-between"
          >
            <Text type="h6">관리자</Text>
            <Text type="b1" color="g400">
              {moment(answerItem?.createdDt).format('YYYY-MM-DD')}
            </Text>
          </Row>
          <Div padding="20px 0 30px" mPadding="16px 0 20px">
            <Text dangerouslySetInnerHTML={{ __html: answerItem?.content }} />
          </Div>
        </Div>
      )}
      <Row justifyContent="space-between" mt={20} mMt={40}>
        <Button
          label={boardLang[cookies.language].question_qna_list_label}
          onClick={() => navigate(-1)}
          type="outline"
          buttonType="capsule"
          minWidth={100}
          height={40}
          active
        />
        {!answerItem && (
          <Button
            label="답변하기"
            onClick={() => setPopupOpen('submit')}
            buttonType="capsule"
            minWidth={100}
            height={40}
            active
          />
        )}
      </Row>
    </Div>
  );
}

export default DetailPresenter;
