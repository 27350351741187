import { Div, Text, Row, Button } from 'differoute-styles';
import AdminContainer from 'layouts/AdminContainer';
import React from 'react';
import AdminTableWrap from 'components/Admin/AdminWrap';
import AdminTable from 'components/Admin/AdminTable';
import moment from 'moment';
import AdminDropDown from 'components/Admin/AdminDropdown';
import AdminPagination from 'components/Admin/AdminPagination';

function QyestuinPresenter({
  navigate,
  list,
  pages,
  filters,
  setFilters,
  languageList,
  goDetail,
}) {
  return (
    <AdminContainer title="보도자료">
      <AdminDropDown
        name="lang"
        list={languageList}
        customItem={(el) => el?.label}
        value={languageList?.find((el) => el?.value === filters.lang)}
        inputType="underline"
        onChange={(value) => {
          setFilters({
            ...filters,
            perPage: 10,
            page: 1,
            lastRowNum: 0,
            lang: value.value,
          });
        }}
      />
      <AdminTableWrap mt={10} view={pages.totalCount}>
        <AdminTable
          list={list}
          keys={['rowNum', 'isAnswered', 'title', 'createdDt']}
          onClick={(item) => goDetail(item)}
          headers={{
            rowNum: '번호',
            isAnswered: '답변여부',
            title: '내용',
            createdDt: '등록일',
          }}
          headerStyles={{
            height: '45px',
            backgroundColor: 'rgba(0, 181, 239, 0.04)',
          }}
          headerTextStyles={{
            rowNum: { color: 'g600' },
            isAnswered: { color: 'g600' },
            title: { color: 'g600' },
            createdDt: { color: 'g600' },
          }}
          rowStyles={(index) => ({
            height: '45px',
            backgroundColor: (index % 2 === 1 && 'g100') || 'white',
            cursor: 'pointer',
          })}
          flex={{
            rowNum: 0.645,
            isAnswered: 0.8,
            title: 7.56,
            createdDt: 0.99,
          }}
          customElements={{
            title: (item) => (
              <Text width="100%" type="b2" color="g600">
                {item.title}
              </Text>
            ),
            isAnswered: (item) => (
              <Div
                padding="4px 8px"
                borderRadius={6}
                backgroundColor={(item.isAnswered === 1 && 'primary') || 'g200'}
              >
                <Text
                  type="h8"
                  color={(item.isAnswered === 1 && 'white') || 'g500'}
                >
                  {(item.isAnswered === 1 && '답변완료') || '답변대기'}
                </Text>
              </Div>
            ),
            createdDt: (item) => (
              <Text type="b2" mType="b3" color="g600">
                {moment(item.createdDt).format('YYYY-MM-DD')}
              </Text>
            ),
          }}
        />
        {pages.totalCount !== 0 && (
          <Div margin="40px auto 0" maxWidth={200}>
            <AdminPagination
              pages={pages}
              filters={filters}
              setFilters={setFilters}
            />
          </Div>
        )}
      </AdminTableWrap>
    </AdminContainer>
  );
}

export default QyestuinPresenter;
