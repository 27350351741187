import { Col } from 'differoute-styles';
import React from 'react';

function MainContainer({ children, ...props }) {
  return (
    <Col
      height="100dvh"
      mHeight="100%"
      justifyContent="center"
      width="100%"
      margin="auto"
      padding="60px 30px 0"
      mPadding="40px 20px"
      {...props}
    >
      {children}
    </Col>
  );
}

export default MainContainer;
