import React, { useState, useEffect } from 'react';
import { userApi } from 'api';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { Loading } from 'differoute-styles';
import FaqPresenter from './FaqPresenter';

function FaqContainer(props) {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const [params] = useSearchParams();
  const [filters, setFilters] = useState({
    perPage: 10,
    page: params.get('page') || 1,
    lastRowNum: params.get('lastRowNum') || 0,
  });
  const [pages, setPages] = useState({
    currentCount: 0,
    currentPage: 1,
    perPage: 10,
    totalPages: 0,
    totalCount: 0,
  });
  const [list, setList] = useState();

  const getList = async () => {
    const [info] = await userApi.faqApi.faq(filters);
    if (info && info.resCode === 200) {
      const { result } = info;
      setPages({
        currentCount: result.currentCount,
        currentPage: result.currentPage,
        perPage: result.perPage,
        totalPages: result.totalPages,
        totalCount: result.totalCount,
      });
      setList(result.list);
      setLoading(false);
    } else {
      setLoading(false);
      console.log('error');
    }
  };

  useEffect(() => {
    if (filters) {
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set('page', `${filters.page}`);
      searchParams.set('lastRowNum', `${filters.lastRowNum}`);
      searchParams.set('perPage', 10);
      const goUrl = `?${searchParams.toString()}`;
      navigate(`${goUrl}`, {
        replace: true,
      });
      getList();
    }

    return () => setLoading(true);
  }, [filters]);

  return (
    <>
      <FaqPresenter
        navigate={navigate}
        location={location}
        list={list}
        pages={pages}
        filters={filters}
        setFilters={setFilters}
      />
      {loading && <Loading loading={loading} />}
    </>
  );
}

export default FaqContainer;
