import { Col, Div, Row, ImageComponent, Text } from 'differoute-styles';
import logo from 'assets/icon/logo.svg';
import logoBlack from 'assets/icon/logo_black.svg';
import menu from 'assets/icon/menu.svg';
import close from 'assets/icon/close.svg';
import React, { useState } from 'react';
import { MobileHeaderDiv, MobileMenu } from 'styles/CustomStyles';
import Language from 'components/Common/Language';
import headerLang from 'data/lang/header';
import { useCookies } from 'react-cookie';
import Dim from 'components/Modal/Dim';

function MHeader({
  scrollPosition,
  setRef,
  goToMenu,
  currentPage,
  setCurrentPage,
}) {
  const [open, setOpen] = useState(false);
  const [cookies] = useCookies();
  return (
    <Div
      display="none"
      mDisplay="block"
      height={56}
      position="fixed"
      zIndex={9999}
      mWidth="100vw"
    >
      <MobileHeaderDiv scrollPosition={scrollPosition}>
        <Row height={56} mPadding="0 20px" justifyContent="space-between">
          <Col alignItems="center" justifyContent="center" cursor="pointer">
            <ImageComponent
              onClick={() => goToMenu('/', 0)}
              src={
                scrollPosition > 1 || window.location.pathname !== '/'
                  ? logoBlack
                  : logo
              }
              height={30}
              mHeight={44}
            />
          </Col>
          <Row gap={20} alignItems="center">
            <Language
              type="mobile"
              index={currentPage.index}
              scrollPosition={scrollPosition}
            />
            <Col
              position="relative"
              width={25}
              height="100%"
              alignItems="center"
              justifyContent="center"
              gap={5}
              onClick={() => {
                setOpen((open) => !open);
              }}
            >
              <ImageComponent src={menu} />
            </Col>
          </Row>
        </Row>
      </MobileHeaderDiv>
      {open && <Dim />}
      <Div
        position="fixed"
        width="100vw"
        top="0"
        maxWidth={295}
        right={(open && '0') || '-295px'}
        height="100vh"
        transition="all 0.4s ease"
        zIndex={10000}
        backgroundColor="white"
      >
        <Col padding="14px 20px" alignItems="flex-end">
          <ImageComponent
            width={19}
            src={close}
            onClick={() => {
              setOpen(false);
            }}
          />
        </Col>
        <MobileMenu
          selected={currentPage.index === 1}
          onClick={() => {
            goToMenu('/', 1);
            setCurrentPage({
              index: 1,
              positionY: 0,
            });
            window.scrollTo({
              top: 0,
              block: 'center',
              behavior: 'smooth',
            });
            setOpen(false);
          }}
        >
          <Text mType="h6" padding="16px 30px">
            {headerLang[cookies.language].home}
          </Text>
        </MobileMenu>
        <MobileMenu
          selected={currentPage.index === 2}
          onClick={() => {
            goToMenu('/', 2);
            setOpen(false);
            setTimeout(() => {
              setCurrentPage({
                index: 2,
                positionY: setRef.current.children[1].offsetTop - 56,
              });
              window.scrollTo({
                top: setRef.current.children[1].offsetTop - 56,
                block: 'center',
                behavior: 'smooth',
              });
            }, 350);
          }}
        >
          <Text mType="h6" padding="16px 30px">
            {headerLang[cookies.language].about}
          </Text>
        </MobileMenu>
        <MobileMenu
          selected={currentPage.index === 4}
          onClick={() => {
            goToMenu('/', 4);
            setTimeout(() => {
              setCurrentPage({
                index: 4,
                positionY: setRef.current.children[3].offsetTop - 56,
              });
              window.scrollTo({
                top: setRef.current.children[3].offsetTop - 56,
                block: 'center',
                behavior: 'smooth',
              });
            }, 350);
            setOpen(false);
          }}
        >
          <Text mType="h6" padding="16px 30px">
            {headerLang[cookies.language].service}
          </Text>
        </MobileMenu>
        <MobileMenu
          selected={currentPage.index === 5}
          onClick={() => {
            goToMenu('/', 5);
            setTimeout(() => {
              setCurrentPage({
                index: 5,
                positionY: setRef.current.children[4].offsetTop - 56,
              });
              window.scrollTo({
                top: setRef.current.children[4].offsetTop - 56,
                block: 'center',
                behavior: 'smooth',
              });
            }, 350);
            setOpen(false);
          }}
        >
          <Text mType="h6" padding="16px 30px">
            {headerLang[cookies.language].tour}
          </Text>
        </MobileMenu>
        <MobileMenu
          selected={currentPage.index === 6}
          onClick={() => {
            goToMenu('/', 6);
            setTimeout(() => {
              setCurrentPage({
                index: 6,
                positionY: setRef.current.children[5].offsetTop - 56,
              });
              window.scrollTo({
                top: setRef.current.children[5].offsetTop - 56,
                block: 'center',
                behavior: 'smooth',
              });
            }, 350);
            setOpen(false);
          }}
        >
          <Text mType="h6" padding="16px 30px">
            {headerLang[cookies.language].goods}
          </Text>
        </MobileMenu>
        <MobileMenu
          selected={currentPage.index === 7}
          onClick={() => {
            goToMenu('/', 7);
            setTimeout(() => {
              setCurrentPage({
                index: 7,
                positionY: setRef.current.children[6].offsetTop - 56,
              });
              window.scrollTo({
                top: setRef.current.children[6].offsetTop - 56,
                block: 'center',
                behavior: 'smooth',
              });
            }, 350);
            setOpen(false);
          }}
        >
          <Text mType="h6" padding="16px 30px">
            {headerLang[cookies.language].place}
          </Text>
        </MobileMenu>
        <MobileMenu
          selected={
            currentPage.index === 8 ||
            window.location.pathname.split('/')[1] === 'community'
          }
          onClick={() => {
            goToMenu('/community/announcement', 8);
            window.scrollTo(0, 0);
            setOpen(false);
          }}
        >
          <Text mType="h6" padding="16px 30px">
            {headerLang[cookies.language].community}
          </Text>
        </MobileMenu>
        <MobileMenu
          selected={
            currentPage.index === 9 ||
            window.location.pathname.split('/')[1] === 'question'
          }
          onClick={() => {
            goToMenu('/question/qna', 9);
            window.scrollTo(0, 0);
            setOpen(false);
          }}
        >
          <Text mType="h6" padding="16px 30px">
            {headerLang[cookies.language].qna}
          </Text>
        </MobileMenu>
      </Div>
    </Div>
  );
}

export default MHeader;
