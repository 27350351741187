import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { ScrollToTop, NotFound } from 'differoute-styles';
import { useCookies } from 'react-cookie';
import useTheme from 'styles/useTheme';
import GlobalStyle from 'styles/GlobalStyle';
import Main from 'routes/User/Main';
import UserLayout from 'layouts/UserLayout';
import Announcement from 'routes/User/Community/Announcement';
import CommunityOutlet from 'routes/User/Community/CommunityOutlet';
import AnnouncementDetail from 'routes/User/Community/Announcement/AnnouncementDetail';
import Press from 'routes/User/Community/Press';
import QuestionOutlet from 'routes/User/Question/QuestionOutlet';
import Qna from 'routes/User/Question/Qna';
import QnaDetail from 'routes/User/Question/Qna/Detail';
import Faq from 'routes/User/Question/Faq';
// admin
import AdminLayout from 'layouts/AdminLayout';
import AdminLogin from 'routes/Admin/Login';
import AdminAnnouncement from 'routes/Admin/Announcement';
import AdminAnnouncementDeatil from 'routes/Admin/Announcement/Detail';
import AdminPress from 'routes/Admin/Press';
import AdminPressDetail from 'routes/Admin/Press/Detail';
import AdminQuestion from 'routes/Admin/Question';
import AdminQuestionDetail from 'routes/Admin/Question/Detail';
import AdminFaq from 'routes/Admin/Faq';
import AdminFaqDetail from 'routes/Admin/Faq/Detail';
import AdminTour from 'routes/Admin/Tour';
import AdminTourDetail from 'routes/Admin/Tour/Detail';
import AdminGoods from 'routes/Admin/Goods';
import AdminGoodsDetail from 'routes/Admin/Goods/Detail';
import EditorWrite from 'routes/Admin/EditorWrite';
import EditorEdit from 'routes/Admin/EditorEdit';
import Write from 'routes/Admin/Write';
import Edit from 'routes/Admin/Edit';

function App() {
  // language querystring setting
  const [cookies, setCookie] = useCookies();
  const [loading, setLoading] = useState(true);
  const searchParams = new URLSearchParams(window.location.search);

  const params = cookies.language;
  const checkLangParams = () => {
    searchParams.set('lang', `${params}`);
    window.history.replaceState(
      {},
      document.title,
      `${window.location.pathname}?${searchParams.toString()}`,
    );
  };

  useEffect(() => {
    if (!window.location.pathname.includes('admin')) {
      setCookie('language', cookies.language || 'ko', { path: '/' });
      checkLangParams();
    }
    setLoading(false);
  }, [params]);

  return (
    !loading && (
      <ThemeProvider theme={useTheme}>
        <GlobalStyle />
        <BrowserRouter>
          <ScrollToTop>
            <Routes>
              <Route path="*" element={<NotFound />} />
              (cookies.language && (
              <Route element={<UserLayout />}>
                <Route path="/" element={<Main />} />
                <Route path="community" element={<CommunityOutlet />}>
                  <Route path="announcement" element={<Announcement />} />
                  <Route
                    path="announcement/:id"
                    element={<AnnouncementDetail />}
                  />
                  <Route path="press" element={<Press />} />
                </Route>
                <Route path="/question" element={<QuestionOutlet />}>
                  <Route path="qna" element={<Qna />} />
                  <Route path="qna/:id" element={<QnaDetail />} />
                  <Route path="faq" element={<Faq />} />
                </Route>
              </Route>
              )
              <Route path="/admin" element={<AdminLayout />}>
                <Route path="login" element={<AdminLogin />} />
                <Route path="announcement" element={<AdminAnnouncement />} />
                <Route
                  path="announcement/:id"
                  element={<AdminAnnouncementDeatil />}
                />
                <Route path="announcement/write" element={<EditorWrite />} />
                <Route path="announcement/:id/edit" element={<EditorEdit />} />
                <Route path="press" element={<AdminPress />} />
                <Route path="press/:id" element={<AdminPressDetail />} />
                <Route path="press/write" element={<Write />} />
                <Route path="press/:id/edit" element={<Edit />} />
                <Route path="question" element={<AdminQuestion />} />
                <Route path="question/:id" element={<AdminQuestionDetail />} />
                <Route path="faq" element={<AdminFaq />} />
                <Route path="faq/:id" element={<AdminFaqDetail />} />
                <Route path="faq/:id/edit" element={<EditorEdit />} />
                <Route path="faq/write" element={<EditorWrite />} />
                <Route path="tour/:category" element={<AdminTour />} />
                <Route
                  path="tour/:category/:id"
                  element={<AdminTourDetail />}
                />
                <Route path="tour/write" element={<Write />} />
                <Route path="tour/:category/:id/edit" element={<Edit />} />
                <Route path="goods/:category" element={<AdminGoods />} />
                <Route
                  path="goods/:category/:id"
                  element={<AdminGoodsDetail />}
                />
                <Route path="goods/write" element={<EditorWrite />} />
                <Route
                  path="goods/:category/:id/edit"
                  element={<EditorEdit />}
                />
              </Route>
            </Routes>
          </ScrollToTop>
        </BrowserRouter>
      </ThemeProvider>
    )
  );
}

export default App;
