const { Text, Div, Span } = require('differoute-styles');
const { default: styled, keyframes, css } = require('styled-components');

const focusInContract = keyframes`
0% {
    letter-spacing: 0.3em;
    -webkit-filter: blur(12px);
            filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
`;

const slideLeft = keyframes`
0% {
  -webkit-transform: translateX(50px);
          transform: translateX(50px);
          opacity: 0;
         
}
100% {
  -webkit-transform: translateX(0);
          transform: translateX(0);
          opacity: 1;
}
`;

const scaleUpCenter = keyframes`
0% {
  -webkit-transform: scale(0.5);
          transform: scale(0.5);
}
100% {
  -webkit-transform: scale(1);
          transform: scale(1);
}
`;

const fadeInAnim = keyframes`
0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }`;

const slideUpAnim = (position) => keyframes`
0% {
  opacity: 0; 
  transform: translateX(-50%) translateY(20px);
}100%{
  opacity: 1; 
  transform: translate(-50%,${(position === 'bottom' && '0') || '-50%'});
}`;

export const animations = {
  fadeIn: css`
    ${fadeInAnim} 0.35s cubic-bezier(0.39, 0.575, 0.565, 1) both
  `,
  slideUp: (position) => css`
    ${slideUpAnim(position)} 0.4s cubic-bezier(0.77, 0, 0.175, 1) forwards
  `,
};

export const FocusInText = styled(Text)`
  -webkit-animation: ${focusInContract} 1.2s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: ${focusInContract} 1.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
`;

export const FocusInSpan = styled(Span)`
  -webkit-animation: ${focusInContract} 1.2s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: ${focusInContract} 1.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
`;

export const SlideLeftCol = styled(Div)`
  ${({ slide, index, type }) =>
    slide === 'true' &&
    index &&
    type &&
    css`
      -webkit-animation: ${slideLeft} 0.6s
        ${({ index, type }) =>
          type === 'service' ? (index % 3) * 0.4 : index * 0.4}s
        cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      animation: ${slideLeft} 0.6s
        ${({ index, type }) =>
          type === 'service' ? (index % 3) * 0.4 : index * 0.4}s
        cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    `}
`;

export const ScaleUpCenterDiv = styled(Div)`
  ${({ scale }) =>
    scale === 'true' &&
    css`
      -webkit-animation: ${scaleUpCenter} 0.7s
        cubic-bezier(0.39, 0.575, 0.565, 1) both;
      animation: ${scaleUpCenter} 0.7s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    `}
`;
