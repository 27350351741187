import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useOutletContext } from 'react-router-dom';
import MainPresenter from './MainPresenter';
import { userApi } from '../../../api';

function MainContainer(props) {
  const { currentPage } = useOutletContext();
  const [cookies] = useCookies();

  const [popupList, setPopupList] = useState([]);
  const getList = async () => {
    const [info] = await userApi.popupApi.list({ lang: cookies.language });
    if (info && info.resCode === 200) {
      const { result } = info;

      result.forEach((item) => {
        item.open = !(
          cookies[`hide_${item.id}`] && cookies[`hide_${item.id}`] === 'true'
        );
        console.log(item.open);
      });

      setPopupList(result);
    } else {
      console.log('error');
    }
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <MainPresenter
      popupList={popupList}
      setPopupList={setPopupList}
      currentPage={currentPage}
    />
  );
}

export default MainContainer;
