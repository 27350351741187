import React from 'react';
import { Text, Div, Col } from 'differoute-styles';
import tourLang from 'data/lang/main/tour';
import BorderImage from 'components/Common/BorderImage';

function MTour({ item, index, cookies }) {
  return (
    <Div
      key={`item_${index}`}
      width="100%"
      onClick={() => window.open(`${item.url}`)}
    >
      <Col backgroundColor="white" borderRadius={10}>
        <BorderImage src={item.attachmentFile} />
      </Col>
      <Text type="h6" mType="h6" width="100%" mMt={14}>
        {item.title}
      </Text>
      <Text
        mMt={14}
        type="h6"
        mType="h6"
        width="100%"
        maxLine={2}
        mMinHeight={36}
        mTextAlign="center"
        color="g800"
      >
        {item.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        {tourLang[cookies.language].price}
      </Text>
    </Div>
  );
}

export default MTour;
