import { Span, Text } from 'differoute-styles';
import React from 'react';
import AdminCard from './AdminCard';

function AdminWrap({ children, view, ...props }) {
  return (
    <AdminCard {...props}>
      <Text type="b2" color="g600" mb={10}>
        전체글&nbsp;
        <Span color="primary" fontWeight={700}>
          {view}
        </Span>
        개
      </Text>
      {children}
    </AdminCard>
  );
}

export default AdminWrap;
