import React from 'react';
import about from 'assets/graphic/about/about.png';
import { Row, Div, Col, Text, Span, ImageComponent } from 'differoute-styles';
import { ScaleUpCenterDiv } from 'styles/Keyframes';
import { useCookies } from 'react-cookie';
import aboutLang from 'data/lang/main/about';
import MainContainer from 'layouts/MainContainer';
import BorderImage from 'components/Common/BorderImage';

function About({ currentIndex }) {
  const [cookies] = useCookies();

  return (
    <MainContainer maxWidth={1260} mMaxWidth="100%">
      <Row
        gap={105}
        mGap={20}
        width="100%"
        alignItems="center"
        justifyContent="space-between"
        mWidth="100%"
        mFlexDirection="column"
      >
        <Div width="100%">
          <Text color="primary" type="h4" mType="h6">
            {aboutLang[cookies.language]?.title}
          </Text>
          <Text type="h3" mType="h4" mt={10}>
            {aboutLang[cookies.language]?.subTitle}
          </Text>
          <Col mt={12} mMt={10} gap={27}>
            {aboutLang[cookies.language].desc.map((desc, index) => (
              <Text
                key={`map_${index}`}
                type="b1"
                fontSize={18}
                mType="b1"
                color="g500"
                style={{ wordBreak: 'break-all' }}
              >
                {desc.text &&
                  String(desc.text)
                    .split('\n')
                    .map((txt, idx) => (
                      <Span key={`tkey_${index}${idx}`}>
                        {txt}
                        <br />
                      </Span>
                    ))}
                {desc.textPoint && (
                  <Span type="b2" color="primary">
                    <br />
                    {desc.textPoint}
                  </Span>
                )}
                {desc.point && (
                  <Span type="b2" color="primary">
                    {desc.point}
                  </Span>
                )}
              </Text>
            ))}
          </Col>
        </Div>
        <ScaleUpCenterDiv
          scale={(currentIndex && currentIndex === 2 && 'true') || 'false'}
          width={560}
          height={430}
          mHeight="unset"
          mWidth="100%"
          aspectRatio="67/44"
        >
          <BorderImage src={about} mAspectRatio="67/44" />
        </ScaleUpCenterDiv>
      </Row>
    </MainContainer>
  );
}

export default About;
