import SideNavigation from 'components/Admin/SideNavigation';
import { Div, Row } from 'differoute-styles';
import { useLocation, useNavigate, Outlet } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';

function AdminLayout(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!sessionStorage.getItem('adminTokenKey')) {
      navigate('/admin/login');
    } else if (
      sessionStorage.getItem('adminTokenKey') &&
      (window.location.pathname === '/admin' ||
        window.location.pathname === '/admin/login')
    ) {
      navigate('/admin/announcement');
    } else if (
      sessionStorage.getItem('adminTokenKey') &&
      window.location.pathname.includes('admin')
    ) {
      axios.defaults.headers.common.Authorization = `Bearer ${sessionStorage.getItem(
        'adminTokenKey',
      )}`;
      setLoading(false);
    }
  }, []);

  return (
    <Row>
      {!location.pathname.includes('login') &&
        sessionStorage.getItem('adminTokenKey') && <SideNavigation />}
      <ScrollDiv
        ml={(!location.pathname.includes('login') && 180) || null}
        padding="0 40px"
        width="100%"
      >
        <Outlet />
      </ScrollDiv>
    </Row>
  );
}

const ScrollDiv = styled(Div)`
  overflow-x: overlay;
  &::-webkit-scrollbar {
    display: block !important;
    -webkit-appearance: none;
    width: 4px;
    height: 6px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${({ theme }) => theme.colors.g300};
  }
`;

export default AdminLayout;
