import React from 'react';
import { Col, Div, ImageComponent, Row } from 'differoute-styles';
import logo from 'assets/icon/logo.svg';
import Language from 'components/Common/Language';
import { ColMenu, IconRow, TextMenu } from 'styles/CustomStyles';
import { ReactComponent as KakaoIcon } from 'assets/icon/kakao.svg';
import { ReactComponent as InstaIcon } from 'assets/icon/insta.svg';
import { ReactComponent as FacebookIcon } from 'assets/icon/facebook.svg';
import { useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import headerLang from 'data/lang/header';

function Header({ setRef, goToMenu, currentPage, setCurrentPage }) {
  const location = useLocation();
  const [cookies] = useCookies();

  return (
    <Div
      mDisplay="none"
      height={60}
      width="100vw"
      padding="0 20px"
      position="fixed"
      transition="all .3s ease"
      zIndex={9999}
      style={{
        backdropFilter:
          (window.location.pathname === '/' && currentPage.index !== 1) ||
          window.location.pathname !== '/'
            ? 'blur(40px)'
            : null,
      }}
    >
      <Row
        maxWidth={1240}
        height="100%"
        margin="0 auto"
        alignItems="center"
        justifyContent="space-between"
      >
        <Col
          alignItems="center"
          justifyContent="center"
          cursor="pointer"
          visibility={
            (((window.location.pathname === '/' && currentPage.index !== 1) ||
              window.location.pathname !== '/') &&
              'hidden') ||
            'visible'
          }
        >
          <ImageComponent src={logo} height={48} />
        </Col>
        <Row
          width="61.94%"
          height="100%"
          justifyContent="center"
          mJustifyContent="flex-start"
        >
          <ColMenu
            onClick={() => {
              goToMenu('/', 1);
              setTimeout(() => {
                setCurrentPage({
                  index: 1,
                  positionY: 0,
                });
                setRef.current.scrollTo({
                  top: 0,
                  block: 'center',
                  behavior: 'smooth',
                });
              }, 300);
            }}
            selected={
              currentPage.index === 1 && window.location.pathname === '/'
            }
          >
            <TextMenu location={location.pathname} index={currentPage.index}>
              {headerLang[cookies.language].home}
            </TextMenu>
          </ColMenu>
          <ColMenu
            onClick={() => {
              goToMenu('/', 2);
              setTimeout(() => {
                setCurrentPage({
                  index: 2,
                  positionY: window.innerHeight,
                });
                setRef.current.scrollTo({
                  top: window.innerHeight,
                  block: 'center',
                  behavior: 'smooth',
                });
              }, 300);
            }}
            selected={
              (currentPage.index === 2 || currentPage.index === 3) &&
              window.location.pathname === '/'
            }
          >
            <TextMenu location={location.pathname} index={currentPage.index}>
              {headerLang[cookies.language].about}
            </TextMenu>
          </ColMenu>
          <ColMenu
            onClick={() => {
              goToMenu('/', 4);
              setTimeout(() => {
                setCurrentPage({
                  index: 4,
                  positionY: window.innerHeight * 3,
                });
                setRef.current.scrollTo({
                  top: window.innerHeight * 3,
                  block: 'center',
                  behavior: 'smooth',
                });
              }, 300);
            }}
            selected={
              currentPage.index === 4 && window.location.pathname === '/'
            }
          >
            <TextMenu location={location.pathname} index={currentPage.index}>
              {headerLang[cookies.language].service}
            </TextMenu>
          </ColMenu>
          <ColMenu
            onClick={() => {
              goToMenu('/', 5);
              setTimeout(() => {
                setCurrentPage({
                  index: 5,
                  positionY: window.innerHeight * 4,
                });
                setRef.current.scrollTo({
                  top: window.innerHeight * 4,
                  block: 'center',
                  behavior: 'smooth',
                });
              }, 300);
            }}
            selected={
              currentPage.index === 5 && window.location.pathname === '/'
            }
          >
            <TextMenu location={location.pathname} index={currentPage.index}>
              {headerLang[cookies.language].tour}
            </TextMenu>
          </ColMenu>
          <ColMenu
            onClick={() => {
              goToMenu('/', 6);
              setTimeout(() => {
                setCurrentPage({
                  index: 6,
                  positionY: window.innerHeight * 5,
                });
                setRef.current.scrollTo({
                  top: window.innerHeight * 5,
                  block: 'center',
                  behavior: 'smooth',
                });
              }, 300);
            }}
            selected={
              currentPage.index === 6 && window.location.pathname === '/'
            }
          >
            <TextMenu location={location.pathname} index={currentPage.index}>
              {headerLang[cookies.language].goods}
            </TextMenu>
          </ColMenu>
          <ColMenu
            onClick={() => {
              goToMenu('/', 7);
              setTimeout(() => {
                setCurrentPage({
                  index: 7,
                  positionY: window.innerHeight * 6,
                });
                setRef.current.scrollTo({
                  top: window.innerHeight * 6,
                  block: 'center',
                  behavior: 'smooth',
                });
              }, 300);
            }}
            selected={
              currentPage.index === 7 && window.location.pathname === '/'
            }
          >
            <TextMenu location={location.pathname} index={currentPage.index}>
              {headerLang[cookies.language].place}
            </TextMenu>
          </ColMenu>
          <ColMenu
            selected={location.pathname.includes('community')}
            onClick={() => {
              goToMenu('/community/announcement', 8);
            }}
          >
            <TextMenu location={location.pathname} index={currentPage.index}>
              {headerLang[cookies.language].community}
            </TextMenu>
          </ColMenu>
          <ColMenu
            selected={location.pathname.includes('question')}
            onClick={() => {
              goToMenu('/question/qna', 9);
            }}
          >
            <TextMenu location={location.pathname} index={currentPage.index}>
              {headerLang[cookies.language].qna}
            </TextMenu>
          </ColMenu>
        </Row>
        <Row gap={20} width="12.82%" height="100%">
          <IconRow
            index={currentPage.index}
            gap={10}
            alignItems="center"
            cursor="pointer"
          >
            <KakaoIcon
              onClick={() => window.open('http://pf.kakao.com/_MTBaxj')}
            />
            <InstaIcon
              onClick={() =>
                window.open('https://www.instagram.com/shopping_travel_lounge/')
              }
            />
            <FacebookIcon
              onClick={() =>
                window.open('https://www.facebook.com/shoppingtravellounge')
              }
            />
          </IconRow>
          <Language index={currentPage.index} />
        </Row>
      </Row>
    </Div>
  );
}

export default Header;
