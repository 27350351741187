import { theme } from 'differoute-styles';

const useTheme = {
  ...theme,
  colors: {
    ...theme.colors,
    g600: '#63666B',
    primary: '#E44B79',
    lightGreen: '#71B32B',
    blue: '#078ACA',
    adminBlue: '#3EB9FF',
    adminRed: '#FE7272',
    primaryOpacity40: `rgba(228, 75, 121, 0.04)`,
    primaryOpacity20: `rgba(228, 75, 121, 0.2)`,
    primaryOpacity10: `rgba(228, 75, 121, 0.1)`,
    lightGreenOpacity40: 'rgba(113, 179, 43, 0.04)',
    blueOpacity40: 'rgba(7, 138, 202, 0.04)',
    b100: 'rgba(0, 181, 239, 0.04)',
  },
  deviceSizes: {
    ...theme.deviceSizes,
    mobile: '1024px',
  },
  device: {
    ...theme.device,
    mobile: `only screen and (max-width: 1024px)`,
  },
};

export default useTheme;
