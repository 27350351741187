import AdminCard from 'components/Admin/AdminCard';
import { Button, Div, ImageComponent, Row, Text } from 'differoute-styles';
import AdminContainer from 'layouts/AdminContainer';
import moment from 'moment';
import React from 'react';

function DetailPresenter({ navigate, deleteItem, item, goEdit }) {
  return (
    item && (
      <AdminContainer title="상세보기">
        <AdminCard padding="30px 30px 20px">
          <Div padding="0 20px 20px">
            <Text color="g600" type="h5">
              {item.title}
            </Text>
            <Row mt={10} gap={4}>
              <Text type="h8" color="g500">
                {item.price}
              </Text>
              <Text type="b2" color="g400">
                ·
              </Text>
              <Text type="h8" color="g500">
                {(item.category === 'daytrip' && '당일') || '숙박'}
              </Text>
              <Text type="b2" color="g400">
                ·
              </Text>
              <Text type="h8" color="g500">
                {item.lang === 'ko'
                  ? '한국어'
                  : item.lang === 'en'
                  ? '영어'
                  : item.lang === 'cn'
                  ? '중국어'
                  : '일본어'}
              </Text>
              <Text type="b2" color="g400">
                ·
              </Text>
              <Text type="b2" color="g400">
                {moment(item.createdDt).format('YYYY-MM-DD')}
              </Text>
            </Row>
          </Div>
          <Div borderTop={{ color: 'g200' }} padding="12px 30px">
            <Text
              color="g600"
              type="b2"
              cursor="pointer"
              onClick={() => window.open(`${item.url}`)}
            >
              {item.url}
            </Text>
            <ImageComponent
              src={item.attachmentFile}
              mt={12}
              width="100%"
              maxWidth={236}
            />
          </Div>
        </AdminCard>
        <Row mt={10} justifyContent="space-between">
          <Button
            label="목록으로"
            buttonType="capsule"
            type="outline"
            buttonColor="adminBlue"
            width={105}
            onClick={() => navigate(-1)}
            active
          />
          <Row gap={10}>
            <Button
              label="삭제"
              buttonType="capsule"
              onClick={deleteItem}
              type="outline"
              buttonColor="adminRed"
              width={105}
              active
            />
            <Button
              label="수정"
              onClick={() => goEdit(item)}
              buttonType="capsule"
              buttonColor="lightGreen"
              width={105}
              active
            />
          </Row>
        </Row>
      </AdminContainer>
    )
  );
}

export default DetailPresenter;
