import AdminCard from 'components/Admin/AdminCard';
import IconDiv from 'components/Common/IconDiv';
import AdminContainer from 'layouts/AdminContainer';
import { Text, Row, Div, ImageComponent, Button } from 'differoute-styles';
import React from 'react';
import moment from 'moment';

function DetailPresenter({ navigate, deleteItem, item, goEdit }) {
  return (
    item && (
      <AdminContainer title="상세보기">
        <AdminCard padding="30px">
          <Text color="g600" type="h5">
            {item.title}
          </Text>
          <Row margin="10px 0 30px" gap={4}>
            {item.isMarked === 1 && (
              <>
                <IconDiv name="adminPin" padding="0 4.5px" />{' '}
                <Text type="h8" color="g500">
                  상단 고정
                </Text>
                <Text type="b2" color="g400">
                  ·
                </Text>
              </>
            )}
            <Text type="h8" color="g500">
              {item.lang === 'ko'
                ? '한국어'
                : item.lang === 'en'
                ? '영어'
                : item.lang === 'cn'
                ? '중국어'
                : '일본어'}
            </Text>
            <Text type="b2" color="g400">
              ·
            </Text>
            <Text type="b2" color="g400">
              {moment(item.createdDt).format('YYYY-MM-DD')}
            </Text>
          </Row>
          <Div borderTop={{ color: 'g200' }} padding="12px 30px">
            <Text cursor="pointer" onClick={() => window.open(`${item.url}`)}>
              {item.url}
            </Text>
            <ImageComponent src={item.attachmentFile} width="100%" mt={12} />
          </Div>
        </AdminCard>
        <Row mt={10} justifyContent="space-between">
          <Button
            label="목록으로"
            onClick={() => navigate(-1)}
            buttonType="capsule"
            type="outline"
            buttonColor="adminBlue"
            width={105}
            active
          />
          <Row gap={10}>
            <Button
              label="삭제"
              onClick={deleteItem}
              buttonType="capsule"
              type="outline"
              buttonColor="adminRed"
              width={105}
              active
            />
            <Button
              label="수정"
              onClick={() => goEdit(item)}
              buttonType="capsule"
              buttonColor="lightGreen"
              width={105}
              active
            />
          </Row>
        </Row>
      </AdminContainer>
    )
  );
}

export default DetailPresenter;
