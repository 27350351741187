import MainContainer from 'layouts/MainContainer';
import { SlideLeftCol } from 'styles/Keyframes';
import { Div, Grid, Text, ImageComponent, Row, Span } from 'differoute-styles';
import React, { useState } from 'react';
import PopUp from 'components/Modal/PopUp';
import { Line } from 'styles/CustomStyles';
import aboutLang from 'data/lang/main/about2';
import { useCookies } from 'react-cookie';
import MSlider from './Sliders/MSlider';

function About2({ currentIndex }) {
  const [cookies] = useCookies();

  // detail modal
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const goDetail = (about) => {
    setModalOpen(true);
    setSelectedItem(about);
  };

  const languageList = aboutLang[cookies.language];
  const moLanguageList = aboutLang[cookies.language].map((el) => el.content);

  return (
    <Div backgroundColor="lightGreenOpacity40" width="100vw">
      <MainContainer maxWidth={1300} mMaxWidth="100%" mPadding="40px 0">
        {languageList &&
          languageList.map((about, index) => (
            <Div key={`about_${index}`}>
              <Text type="h4" mType="h6" color="lightGreen" textAlign="center">
                {about.title}
              </Text>
              <Text type="h3" mType="h4" textAlign="center">
                {about.subTitle}
              </Text>
              <Line margin="40px 0" mMargin="30px 0" />
              <Grid cols={5} gap={20} mDisplay="none">
                {about.content.map((con, idx) => (
                  <SlideLeftCol
                    type="about"
                    cursor="pointer"
                    onClick={() => goDetail(con)}
                    mDisplay="none"
                    key={`con_${idx}`}
                    slide={(currentIndex === 3 && 'true') || 'false'}
                    index={idx}
                  >
                    <ImageComponent src={con.img} width="100%" />
                    <Text mt={20} type="h4" textAlign="center">
                      {con.title}
                    </Text>
                    <Div
                      width="100%"
                      height={1}
                      backgroundColor="g300"
                      margin="20px 0"
                    />
                    <Div maxHeight={170} overflowY="hidden">
                      {con.subTitle.map((sub, i) => (
                        <Row key={`desc_${i}`}>
                          <Text type="b2" color="g500" lineHeight="24px">
                            -&nbsp;
                          </Text>
                          <Text type="b2" color="g500" lineHeight="24px">
                            {sub &&
                              String(sub)
                                .split('\n')
                                .map((txt, idx) => (
                                  <Span key={`tkey_${index}${idx}`}>
                                    {txt}
                                    <br />
                                  </Span>
                                ))}
                          </Text>
                        </Row>
                      ))}
                    </Div>
                  </SlideLeftCol>
                ))}
              </Grid>
            </Div>
          ))}
        <Div display="none" mDisplay="block">
          <MSlider
            type="about2"
            list={moLanguageList[0]}
            goDetail={goDetail}
            color="lightGreen"
          />
        </Div>
      </MainContainer>
      {modalOpen && (
        <PopUp
          maxWidth={1190}
          width="100%"
          mWidth="100%"
          confirm={{ onClick: () => setModalOpen(false) }}
        >
          <Row gap={30} mGap={20} mFlexDirection="column" mPadding="0 20px">
            <ImageComponent src={selectedItem.img} flex={5} />
            <Div flex={5}>
              <Text type="h4" mType="h5" color="white">
                {selectedItem.title &&
                  String(selectedItem.title)
                    .split('\n')
                    .map((txt, idx) => (
                      <Span key={`tkey_${idx}`}>
                        {txt}
                        <br />
                      </Span>
                    ))}
              </Text>
              <Row mt={15} padding="0 10px">
                <Text type="h5" mType="h6" color="white">
                  "
                </Text>
                <Text type="h5" mType="h6" color="white">
                  {selectedItem.innerSubTitle &&
                    String(selectedItem.innerSubTitle)
                      .split('\n')
                      .map((txt, idx) => (
                        <Span key={`tkey_${idx}`}>
                          {txt}
                          <br />
                        </Span>
                      ))}
                </Text>
              </Row>
              <Div mt={20} mMt={16}>
                {selectedItem.desc.map((desc, idx) => (
                  <Row key={`desc_${idx}`}>
                    <Text type="b1" mType="b3" color="white">
                      -&nbsp;
                    </Text>
                    <Div>
                      {desc.split('<br/>').map((text, idx2) => (
                        <Text
                          key={`idx2_${idx2}`}
                          type="b1"
                          mType="b3"
                          color="white"
                        >
                          {text}
                        </Text>
                      ))}
                    </Div>
                  </Row>
                ))}
              </Div>
            </Div>
          </Row>
        </PopUp>
      )}
    </Div>
  );
}

export default About2;
