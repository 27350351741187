import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';

function Dim({ onClick, children, type, ...props }) {
  useEffect(() => {
    document.body.style.cssText = `
    overflow:hidden; 
    touch-action:none;`;
    return () => {
      document.body.style.cssText = ``;
    };
  }, []);

  return <DimCol type={type}>{children}</DimCol>;
}

const DimCol = styled.div`
  display: flex;
  position: fixed;
  width: 100vw;
  height: 100dvh;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: rgba(25, 25, 25, 0.68);
  align-items: center;
  justify-content: center;
  touch-action: none;

  ${({ type }) =>
    type !== 'day' &&
    css`
      backdrop-filter: blur(20px);
    `}
`;

export default Dim;
