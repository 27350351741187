import React from 'react';
import { Row, Text, Div, Col } from 'differoute-styles';
import IconDiv from 'components/Common/IconDiv';
import BorderImage from 'components/Common/BorderImage';

function MService({ item, index, goDetail }) {
  return (
    <Div
      key={`item_${index}`}
      width="100%"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        goDetail(item);
      }}
    >
      <Text color="blue" type="h4" mType="h3">
        {String(index + 1).padStart(2, '0')}
      </Text>
      <Div mWidth="100%">
        <Col
          mWidth="100%"
          backgroundColor="white"
          borderRadius={10}
          mMinHeight={240}
          mJustifyContent="center"
          overflowX="hidden"
          overflowY="hidden"
        >
          <BorderImage
            src={item.img}
            mAspectRatio="59/48"
            objectFit={(item.link && 'contain') || 'cover'}
          />
        </Col>
        <Text type="h6" mType="h6" width="100%" mMt={14}>
          {item.title}
        </Text>
        <Row mMt={14}>
          <Text type="b2" mType="b2" color="g500" mMinHeight={36}>
            -&nbsp;
          </Text>
          <Text type="b2" mType="b2" width="100%" mMinHeight={36} color="g500">
            {item.subTitle}
          </Text>
        </Row>
        {item.link && (
          <Row
            mMt={14}
            onClick={(e) => {
              e.stopPropagation();
              window.open(`${item.link}`);
            }}
            maxWidth={99}
            height={37}
            borderRadius={999}
            width="100%"
            alignItems="center"
            justifyContent="center"
            gap={6}
            border={{ color: 'blue' }}
            cursor="pointer"
            hover
          >
            <Text type="h8" color="blue">
              item.linkText
            </Text>
            <IconDiv
              name="defaultArrow"
              pathFill="blue"
              padding="3px 6.5px"
              width={20}
            />
          </Row>
        )}
      </Div>
    </Div>
  );
}

export default MService;
