/* eslint-disable guard-for-in */
export const invalidObjectCheck = (obj) => {
  // invalid Object 값에 true가 있는지 찾아내는 함수
  // 객체 타입체크
  if (obj.constructor !== Object) {
    return false;
  }

  // property 체크
  for (const prop in obj) {
    if (obj[prop].invalid === true) {
      return true;
    }
  }

  return false;
};

export const isEmptyObjItems = (obj) => {
  // Object 안에 key값이 존재하나 value값이 없는 것을 찾아내는 함수
  // 객체 타입체크
  if (obj.constructor !== Object) {
    return false;
  }

  // property 체크
  for (const prop in obj) {
    if (!obj[prop] || obj[prop].length === 0) {
      return true;
    }
  }

  return false;
};

export const emptyAllInvalids = (values, invalids) => {
  // 빈 항목을 찾아 invalid 세팅해주는 함수
  let tempInvalidKeyArray = [];

  for (const key of Object.keys(values)) {
    if (!values[key] || values[key].length === 0) {
      tempInvalidKeyArray = [...tempInvalidKeyArray, key];
    }
  }

  let tempInvalidArray = {};
  tempInvalidKeyArray.forEach(
    (el) =>
      (tempInvalidArray = {
        ...tempInvalidArray,
        [el]: {
          invalid: 'error',
          text: invalids[el]?.text ? invalids[el]?.text : '항목을 입력해주세요',
        },
      }),
  );

  return {
    ...invalids,
    ...tempInvalidArray,
  };
};

export const isEmptyObj = (obj) => {
  // Object 가 빈값인지 체크하는 함수 {}
  // {}일 때 true 반환

  // 객체 타입체크
  if (obj.constructor !== Object) {
    return false;
  }

  // property 체크
  for (const prop in obj) {
    if (obj[prop]) {
      return false;
    }
  }

  return true;
};
