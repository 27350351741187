import { Div, Text, ImageComponent, Col } from 'differoute-styles';
import React from 'react';
import pin from 'assets/icon/pin.svg';
import moment from 'moment';
import Pagination from 'components/Pagination';

function AnnouncementPresenter({ list, pages, filters, setFilters, goDetail }) {
  return (
    <>
      <Div
        mt={20}
        maxWidth={1024}
        margin="auto"
        minHeight="calc(100vh - 782px)"
      >
        {list &&
          list.map((item) => (
            <Div key={item.id}>
              <Col
                borderBottom={{ color: 'g200' }}
                padding="17px 0"
                gap={10}
                cursor="pointer"
                onClick={() => goDetail(item)}
              >
                <ImageComponent
                  src={pin}
                  width={15}
                  visibility={
                    (String(item.isMarked) === '1' && 'visible') || 'hidden'
                  }
                />
                <Text type="h5" mType="h6" maxLine={1}>
                  {item.title}
                </Text>
                <Text type="b1" mType="b2" color="g400">
                  {moment(item.createdDt).format('YYYY-MM-DD')}
                </Text>
              </Col>
            </Div>
          ))}
      </Div>
      <Pagination
        mt={40}
        pages={pages}
        filters={filters}
        setFilters={setFilters}
      />
    </>
  );
}

export default AnnouncementPresenter;
