const place = {
  ko: {
    name: '운영정보',
    place: '위치',
    address: '전북특별자치도 전주시 완산구 충경로 25, 1층 전북쇼핑트래블라운지',
    info: '운영시간 · 연락처',
    hours: '월 - 일 (연중무휴) 10:00 - 19:00',
    channelName: '운영채널',
    channelEmail: '이메일 : ',
    channelInstagram: 'Instagram : ',
    channelFacebook: 'Facebook : ',
    channelKakao: '카카오톡 : ',
  },
  en: {
    name: 'Operation Information',
    place: 'Location',
    address:
      '1st floor Jeonbuk Shopping Travel Lounge, 25, Chunggyeong-ro, Wansan-gu, Jeonju-si, Jeonbuk State',
    info: 'Operating Hour · Contact',
    hours: 'Mon ~ Sun (all year round) 10:00 - 19:00',
    channelName: 'Contact',
    channelEmail: 'E-mail : ',
    channelInstagram: 'Instagram : ',
    channelFacebook: 'Facebook : ',
    channelKakao: 'KakaoTalk : ',
  },
  cn: {
    name: '运营信息',
    place: '地址',
    address: '全北特别自治道 全州市 完山区 忠景路 25, 1楼 全北购物旅游休息室',
    info: '营业时间 ∙ 电话',
    hours: '每天(全年无休) 10:00 - 19:00',
    channelName: '官方频道',
    channelEmail: 'E-mail : ',
    channelInstagram: 'Instagram : ',
    channelFacebook: 'Facebook : ',
    channelKakao: 'KakaoTalk : ',
  },
  jp: {
    name: '運営情報',
    place: '位置',
    address:
      '全北特別自治道 全州市 完山区 忠景路 25, 1階 全北ショッピングトラベルラウンジ',
    info: '運営時間・連絡先',
    hours: '月~日(年中無休) 10:00 - 19:00',
    channelName: '全北ショッピングトラベルラウンジの公式アカウント',
    channelEmail: 'E-mail : ',
    channelInstagram: 'Instagram : ',
    channelFacebook: 'Facebook : ',
    channelKakao: 'KakaoTalk : ',
  },
};

export default place;
