import { Button, Div, Row, Text } from 'differoute-styles';
import AdminContainer from 'layouts/AdminContainer';
import React from 'react';
import AdminWrap from 'components/Admin/AdminWrap';
import AdminTable from 'components/Admin/AdminTable';
import moment from 'moment';
import AdminDropDown from 'components/Admin/AdminDropdown';
import AdminPagination from 'components/Admin/AdminPagination';

function GoodsPresenter({
  list,
  pages,
  filters,
  setFilters,
  languageList,
  goodsCategory,
  goDetail,
  category,
  goCategory,
  navigate,
}) {
  return (
    category && (
      <AdminContainer title="굿즈">
        <Row justifyContent="space-between" alignItems="center">
          <Row>
            <AdminDropDown
              name="lang"
              list={languageList}
              customItem={(el) => el?.label}
              value={languageList?.find((el) => el?.value === filters.lang)}
              inputType="underline"
              onChange={(value) => {
                setFilters({
                  ...filters,
                  perPage: 10,
                  page: 1,
                  lastRowNum: 0,
                  lang: value.value,
                });
              }}
            />
            <AdminDropDown
              name="category"
              list={goodsCategory}
              customItem={(el) => el?.label || category.label}
              value={goodsCategory?.find((el) => el?.value === category.value)}
              inputType="underline"
              onChange={(value) => goCategory(value)}
            />
          </Row>
          <Button
            label="새 글 등록"
            onClick={() => navigate('/admin/goods/write', { state: 'goods' })}
            buttonType="capsule"
            buttonColor="lightGreen"
            width={105}
            height={37}
            active
          />
        </Row>
        <AdminWrap view={pages.totalCount} mt={10}>
          <AdminTable
            list={list}
            keys={['rowNum', 'title', 'price', 'createdDt']}
            onClick={(item) => goDetail(item)}
            headers={{
              rowNum: '번호',
              title: '제목',
              price: '가격',
              createdDt: '등록일',
            }}
            headerStyles={{
              height: '45px',
              backgroundColor: 'rgba(0, 181, 239, 0.04)',
            }}
            headerTextStyles={{
              rowNum: { color: 'g600' },
              title: { color: 'g600' },
              isTop: { color: 'g600' },
              createdDt: { color: 'g600' },
            }}
            rowStyles={(index) => ({
              height: '45px',
              backgroundColor: (index % 2 === 1 && 'g100') || 'white',
              cursor: 'pointer',
            })}
            flex={{
              rowNum: 0.645,
              title: 7,
              price: 1.36,
              createdDt: 0.99,
            }}
            customElements={{
              title: (item) => (
                <Text type="b2" color="g600" width="100%">
                  {item.title}
                </Text>
              ),
              price: (item) => (
                <Text type="b2" color="g600" maxLine={1}>
                  {item.price.toUpperCase().replace('WON', '')}
                </Text>
              ),
              createdDt: (item) => (
                <Text type="b2" mType="b3" color="g600">
                  {moment(item.createdDt).format('YYYY-MM-DD')}
                </Text>
              ),
            }}
          />
          {pages.totalCount !== 0 && (
            <Div margin="40px auto 0" maxWidth={200}>
              <AdminPagination
                pages={pages}
                filters={filters}
                setFilters={setFilters}
              />
            </Div>
          )}
        </AdminWrap>
      </AdminContainer>
    )
  );
}

export default GoodsPresenter;
