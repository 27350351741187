import React, { useState, useEffect, useRef } from 'react';
import { Row, Text, Col } from 'differoute-styles';
import IconDiv from 'components/Common/IconDiv';
import { Navigation, Grid } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/grid';
import { SlideLeftCol } from 'styles/Keyframes';
import { HoverImage } from 'styles/CustomStyles';
import styled from 'styled-components';

function ServiceSlider({
  list,
  goDetail,
  contentItem,
  currentIndex,
  detailItem,
}) {
  const swiperRef = useRef();
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    if (contentItem && contentItem.tab) {
      swiperRef.current.swiper.slideTo(0);
    }
  }, [contentItem]);

  const [isView, setIsView] = useState(false);
  useEffect(() => {
    if (list && currentIndex === 4) {
      setIsView(true);
      setTimeout(() => {
        setIsView(false);
      }, [2000]);
    }
  }, [list, currentIndex]);

  return (
    <StyleSwiper
      ref={swiperRef}
      effect="fade"
      speed={1000}
      modules={[Navigation, Grid]}
      className="mySwiper"
      initialSlide={
        list && detailItem && list.findIndex((i) => i.id === detailItem.id)
      }
      onSlideChange={(e) => {
        setCurrentSlide(e.realIndex);
        setIsView(true);
        setTimeout(() => {
          setIsView(false);
        }, [2000]);
      }}
      slidesPerView={1}
      grid={{
        rows: 3,
      }}
      navigation={{
        prevEl: '.prev',
        nextEl: '.next',
      }}
    >
      {list.map((item, index) => (
        <SwiperSlide
          key={`item${index}`}
          animate={{
            opacity: currentSlide ? 1 : 0,
            scale: currentSlide ? 1 : 0.5,
          }}
        >
          <SlideLeftCol
            type="service"
            key={`item_${index}`}
            index={index}
            padding="20px 0"
            onClick={() => goDetail(item)}
            cursor="pointer"
            slide={(currentIndex === 4 && isView && 'true') || 'false'}
          >
            <Row gap={30} mFlexDirection="column">
              <Text color="blue" type="h2">
                {String(index + 1).padStart(2, '0')}
              </Text>
              <HoverImage
                src={item.img}
                minWidth={280}
                height={168}
                objectFit="cover"
                mHeight="auto"
                mWidth={295}
                borderRadius={10}
              />
              <Col gap={10} justifyContent="center">
                <Text type="h4" mWidth={295}>
                  {item.title}
                </Text>
                <Col mWidth={295}>
                  {(Array.isArray(item.subTitle) &&
                    item.subTitle.map((txt) => (
                      <Row>
                        <Text type="b1" color="g500">
                          -&nbsp;
                        </Text>
                        <Text type="b1" color="g500" whiteSpace="pre-wrap">
                          {txt}
                        </Text>
                      </Row>
                    ))) || (
                    <Row>
                      <Text type="b1" color="g500">
                        -&nbsp;
                      </Text>
                      <Text type="b1" color="g500" whiteSpace="pre-wrap">
                        {item.subTitle}
                      </Text>
                    </Row>
                  )}
                </Col>
                {item.link && (
                  <Row
                    onClick={(e) => {
                      e.stopPropagation();
                      window.open(`${item.link}`);
                    }}
                    maxWidth={99}
                    height={37}
                    borderRadius={999}
                    width="100%"
                    alignItems="center"
                    justifyContent="center"
                    gap={6}
                    border={{ color: 'blue' }}
                    cursor="pointer"
                    hover
                  >
                    <Text type="h8" color="blue">
                      {item.linkText}
                    </Text>
                    <IconDiv
                      name="defaultArrow"
                      pathFill="blue"
                      padding="3px 6.5px"
                    />
                  </Row>
                )}
              </Col>
            </Row>
          </SlideLeftCol>
        </SwiperSlide>
      ))}
      <Row gap={10} mt={20} justifyContent="flex-end">
        <IconDiv
          className="prev"
          name="arrow"
          pathFill={(currentSlide === 0 && 'g200') || 'blue'}
          hover
        />
        <IconDiv
          className="next"
          name="arrow"
          pathFill={(currentSlide + 1 === list.length / 3 && 'g200') || 'blue'}
          transform="rotate(180deg)"
          hover
        />
      </Row>
    </StyleSwiper>
  );
}
const StyleSwiper = styled(Swiper)`
  .swiper-wrapper {
    height: 624px;
  }
  .swiper-slide,
  .swiper-slide-active {
    height: 208px !important;
  }
`;
export default ServiceSlider;
