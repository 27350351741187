import axios from 'axios';

const makeRequest = (type, path, params) => {
  if (type === 'get') {
    return axios.get(`${process.env.REACT_APP_API_HOST}/${path}`, {
      params: {
        ...params,
      },
    });
  } else if (type === 'post') {
    return axios.post(`${process.env.REACT_APP_API_HOST}/${path}`, {
      ...params,
    });
  } else if (type === 'patch') {
    return axios.patch(`${process.env.REACT_APP_API_HOST}/${path}`, {
      ...params,
    });
  } else if (type === 'delete') {
    return axios.delete(`${process.env.REACT_APP_API_HOST}/${path}`, {
      params: {
        ...params,
      },
    });
  } else if (type === 'fileUpload') {
    // upload file
    return axios.post(`${process.env.REACT_APP_API_HOST}/${path}`, params);
  } else if (type === 'excelDownload' || type === 'zipDownload') {
    // download file
    return axios.get(`${process.env.REACT_APP_API_HOST}/${path}`, {
      type:
        type === 'excel'
          ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          : '.zip,.rar,.7zip',
      responseType: 'blob',
    });
  }
};

const getResults = async (type, path, params = {}) => {
  try {
    const {
      data: { results },
      data,
    } = await makeRequest(type, path, params);
    return [results || data, null];
  } catch (e) {
    console.error(e);
    return [null, e.response?.data, e.response?.status];
  }
};

export const userApi = {
  popupApi: {
    list: (params) => getResults('get', `client/popup`, params),
  },
  announcementApi: {
    list: (params) => getResults('get', `client/announcement`, params),
    detail: (id) => getResults('get', `client/announcement/${id}`),
  },
  pressApi: {
    press: (params) => getResults('get', `client/press`, params),
  },
  questionApi: {
    question: (params) => getResults('get', `client/question`, params),
    userQuestion: (params) => getResults('post', `client/question`, params),
    detail: (id) => getResults('get', `client/question/${id}`),
    adminLogin: (params) => getResults('post', `client/auth/answer`, params),
    adminAnswer: (params) => getResults('post', `client/answer`, params),
  },
  faqApi: {
    faq: (params) => getResults('get', `client/faq`, params),
  },
  tourApi: {
    tour: (category, language) =>
      getResults('get', `client/travel/${category}?lang=${language}`),
  },
  goodsApi: {
    goods: (category, language) =>
      getResults('get', `client/goods/${category}?lang=${language}`),
    detail: (category, id) =>
      getResults('get', `client/goods/${category}/${id}`),
  },
};

export const adminApi = {
  loginApi: {
    login: (params) => getResults('post', `admin/account/login`, params),
  },
  communityApi: {
    notice: {
      list: (params) => getResults('get', `admin/announcement`, params),
      detail: (id) => getResults('get', `admin/announcement/${id}`),
      write: (params) => getResults('post', `admin/announcement`, params),
      edit: (noticeId, params) =>
        getResults('patch', `admin/announcement/${noticeId}`, params),
      delete: (noticeId) =>
        getResults('delete', `admin/announcement/${noticeId}`),
      fileUpload: () =>
        getResults('fileUpload', `admin/announcement/upload/file`),
    },
    news: {
      list: (params) => getResults('get', `admin/press`, params),
      detail: (id) => getResults('get', `admin/press/${id}`),
      write: (params) => getResults('post', `admin/press`, params),
      edit: (pressId, params) =>
        getResults('patch', `admin/press/${pressId}`, params),
      delete: (pressId) => getResults('delete', `admin/press/${pressId}`),
      fileUpload: (params) =>
        getResults('fileUpload', `admin/press/upload/file`, params),
    },
  },
  questionApi: {
    qna: {
      list: (params) => getResults('get', `admin/question`, params),
      detail: (id) => getResults('get', `admin/question/${id}`),
      answer: (params) => getResults('post', `admin/answer`, params),
      delete: (id) => getResults('delete', `admin/question/${id}`),
    },
    faq: {
      list: (params) => getResults('get', `admin/faq`, params),
      detail: (id) => getResults('get', `admin/faq/${id}`),
      write: (params) => getResults('post', `admin/faq`, params),
      edit: (id, params) => getResults('patch', `admin/faq/${id}`, params),
      delete: (id) => getResults('delete', `admin/faq/${id}`),
    },
  },
  ProductApi: {
    goods: {
      list: (category, params) =>
        getResults('get', `admin/goods/${category}`, params),
      detail: (category, id) =>
        getResults('get', `admin/goods/${category}/${id}`),
      write: (category, params) =>
        getResults('post', `admin/goods/${category}`, params),
      edit: (category, id, params) =>
        getResults('patch', `admin/goods/${category}/${id}`, params),
      delete: (category, id) =>
        getResults('delete', `admin/goods/${category}/${id}`),
      fileUpload: (category, params) =>
        getResults('fileUpload', `admin/goods/${category}/upload/file`, params),
    },
    tour: {
      list: (category, params) =>
        getResults('get', `admin/travel/${category}`, params),
      detail: (category, id) =>
        getResults('get', `admin/travel/${category}/${id}`),
      write: (category, params) =>
        getResults('post', `admin/travel/${category}`, params),
      edit: (category, id, params) =>
        getResults('patch', `admin/travel/${category}/${id}`, params),
      delete: (category, id) =>
        getResults('delete', `admin/travel/${category}/${id}`),
      fileUpload: (category, params) =>
        getResults(
          'fileUpload',
          `admin/travel/${category}/upload/file`,
          params,
        ),
    },
  },
};
