import React, { useEffect, useRef } from 'react';
import { Row, Div, Col, Button, Text } from 'differoute-styles';
import IconDiv from 'components/Common/IconDiv';
import styled from 'styled-components';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import Viewer from 'components/Editor/Viewer';
import BorderImage from 'components/Common/BorderImage';
import { useCookies } from 'react-cookie';
import goodsLang from '../../../../data/lang/main/goods';

function DetailModal({
  list,
  detailItem,
  setModalOpen,
  currentSlide,
  setCurrentSlide,
}) {
  const [cookies] = useCookies();
  const swiperRef = useRef();

  useEffect(() => {
    if (list && detailItem) {
      setCurrentSlide(list.findIndex((i) => i.id === detailItem.id));
    }
  }, [list, detailItem]);

  return (
    <Div width="100%" mPadding="0 20px">
      <Col
        position="absolute"
        top={50}
        right={100}
        mPosition="unset"
        mMb={30}
        mAlignItems="flex-end"
      >
        <Button
          label={goodsLang[cookies.language].buttonLabel.label}
          type="outline"
          backgroundColor="transparent"
          mWidth={90}
          onClick={() => setModalOpen('all')}
        />
      </Col>
      <StyleSwiper
        ref={swiperRef}
        speed={500}
        modules={[Navigation]}
        className="mySwiper"
        onSlideChange={(e) => setCurrentSlide(e.realIndex)}
        spaceBetween={30}
        centeredSlides
        slidesPerView={1}
        navigation={{
          prevEl: '.prev',
          nextEl: '.next',
        }}
        initialSlide={
          list && detailItem && list.findIndex((i) => i.id === detailItem.id)
        }
        style={{ position: 'relative' }}
      >
        <IconDiv
          position="absolute"
          cursor="pointer"
          left={0}
          top="50%"
          mTop={137.5}
          padding="0"
          width={30}
          height={30}
          mWidth={20}
          mHeight={20}
          className="prev"
          name="arrow"
          zIndex={10}
          display={(currentSlide === 0 && 'none') || 'block'}
          hover
        />
        <IconDiv
          position="absolute"
          padding="0"
          right={0}
          top="50%"
          mTop={137.5}
          width={30}
          height={30}
          mWidth={20}
          mHeight={20}
          className="next"
          name="arrow"
          iconTransform="rotate(180deg)"
          zIndex={10}
          display={(currentSlide + 1 === list.length && 'none') || 'block'}
          hover
        />
        {list.map((item) => (
          <SwiperSlide key={item.id}>
            <Row
              gap={30}
              display="flex !important"
              mFlexDirection="column"
              maxWidth={1000}
              mPadding="0 30px"
              margin="auto"
            >
              <Div flex={5}>
                <BorderImage src={item.attachmentFile} />
              </Div>
              <Div flex={5} width="50%" mWidth="100%">
                <Text type="h4" mType="h5" color="white">
                  {item.title}
                </Text>
                <Div mt={20} mMt={16}>
                  <Row alignItems="flex-start">
                    {/* <Text type="b1" mType="b2" color="white">
                      -&nbsp;
                    </Text> */}
                    {/* <Text type="b1" mType="b2" color="white">
                      {item.content}
                    </Text> */}
                    <Viewer value={item.content} />
                  </Row>
                  <Text type="b1" mType="b2" color="white" mt={16}>
                    {item.price}
                  </Text>
                </Div>
              </Div>
            </Row>
          </SwiperSlide>
        ))}
      </StyleSwiper>
    </Div>
  );
}

const StyleSwiper = styled(Swiper)`
  .swiper-button-prev:after,
  .swiper-rtl .swiper-button-next:after,
  .swiper-button-next:after,
  .swiper-rtl .swiper-button-prev:after {
    content: '';
  }
`;

export default DetailModal;
