import { Div, Text, ImageComponent, Col, Row } from 'differoute-styles';
import React from 'react';
import pin from 'assets/icon/pin.svg';
import moment from 'moment';
import thumnail from 'assets/graphic/thumnail.jpg';
import Pagination from 'components/Pagination';

function PressPresenter({ list, pages, filters, setFilters }) {
  return (
    <>
      <Div maxWidth={1024} margin="auto" minHeight="calc(100vh - 782px)">
        {list &&
          list.map((item) => (
            <Row
              key={item.id}
              gap={10}
              justifyContent="space-between"
              cursor="pointer"
              mt={20}
              alignItems="center"
            >
              <Col
                padding="17px 0"
                mPadding="0"
                gap={10}
                cursor="pointer"
                onClick={() => window.open(`${item.url}`)}
              >
                <ImageComponent
                  src={pin}
                  width={15}
                  visibility={(item.isMarked && 'visible') || 'hidden'}
                />
                <Text type="h5" mType="h6" maxLine={1}>
                  {item.title}
                </Text>
                <Text type="b1" mType="b2" color="g400">
                  {moment(item.createDt).format('YYYY-MM-DD')}
                </Text>
              </Col>
              <ImageComponent
                src={(item.attachmentFile && item.attachmentFile) || thumnail}
                borderRadius={10}
                maxWidth={200}
                mMaxWidth={113}
                height={120}
                mHeight={100}
                mObjectFit="cover"
                width="100%"
              />
            </Row>
          ))}
      </Div>
      <Pagination
        mt={40}
        pages={pages}
        filters={filters}
        setFilters={setFilters}
      />
    </>
  );
}

export default PressPresenter;
