import AdminCard from 'components/Admin/AdminCard';
import AdminCheckbox from 'components/Admin/AdminCheckbox';
import { Input, InputFile, Text, Row, Button, Div } from 'differoute-styles';
import AdminContainer from 'layouts/AdminContainer';
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import AdminAlert from 'components/Admin/AdminAlert';
import { adminApi } from 'api';
import { isEmptyObjItems } from 'utils/checkData';

function Edit(props) {
  const { id } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [values, setValues] = useState();
  const [alert, setAlert] = useState();
  const [loading, setLoading] = useState(false);

  // init values setting
  useEffect(() => {
    if (state && state.type === 'press') {
      setValues({
        lang: state.item.lang,
        title: state.item.title,
        isMarked: state.item.isMarked,
        url: state.item.url,
        attachmentFile: state.item.attachmentFile,
        attachmentFileName: state.item.attachmentFileName,
      });
    } else if (state.type === 'tour') {
      setValues({
        // lang: state.item.lang,
        category: state.item.category,
        title: state.item.title,
        url: state.item.url,
        price: state.item.price,
        attachmentFile: state.item.attachmentFile,
        attachmentFileName: state.item.attachmentFileName,
      });
    }
  }, []);

  const onChange = (value, name, fileName) => {
    if (name === 'attachmentFile' && !value) {
      setValues({
        ...values,
        attachmentFile: '',
        attachmentFileName: '',
      });
    } else if (fileName) {
      setValues({
        ...values,
        [name]: value.fileUrl,
        attachmentFileName: fileName,
      });
    } else if (name === 'lang' || name === 'category') {
      setValues({ ...values, [name]: value.value });
    } else {
      setValues({ ...values, [name]: value });
    }
  };

  // img upload api
  const uploadFile = async (file) => {
    if (state.type === 'press') {
      const [info] = await adminApi.communityApi.news.fileUpload(file);
      return info ? info.result : false;
    } else if (state.type === 'tour') {
      const [info] = await adminApi.ProductApi.tour.fileUpload(
        values.category,
        file,
      );
      return info ? info.result : false;
    }
  };

  const editApi = async () => {
    setLoading(true);

    if (state.type === 'press') {
      const [info, error] = await adminApi.communityApi.news.edit(id, values);
      if (info && info.resCode === 200) {
        setLoading(false);
        setAlert({
          title: '알림',
          content: '수정 완료되었습니다.',
          customButton: {
            buttonColor: 'lightGreen',
            borderRadius: '50rem',
            color: 'white',
            backgroundColor: 'lightGreen',
            label: '확인',
            onClick: () => navigate(-1),
          },
        });
      } else if (error.resCode === 401) {
        sessionStorage.removeItem('adminTokenKey');
        setLoading(false);
        navigate('/admin/login');
      } else {
        setLoading(false);
        console.log('error');
      }
    } else if (state.type === 'tour') {
      const [info, error] = await adminApi.ProductApi.tour.edit(
        state.category,
        id,
        values,
      );
      if (info && info.resCode === 200) {
        setLoading(false);
        setAlert({
          title: '알림',
          content: '수정 완료되었습니다.',
          customButton: {
            buttonColor: 'lightGreen',
            borderRadius: '50rem',
            color: 'white',
            backgroundColor: 'lightGreen',
            label: '확인',
            onClick: () => navigate(-1),
          },
        });
      } else if (error.resCode === 401) {
        sessionStorage.removeItem('adminTokenKey');
        setLoading(false);
        navigate('/admin/login');
      } else {
        setLoading(false);
        console.log('error');
      }
    }
  };

  const editItem = () => {
    setAlert({
      title: '알림',
      content: '수정하시겠습니까?',
      customButton: {
        buttonColor: 'lightGreen',
        borderRadius: '50rem',
        color: 'white',
        backgroundColor: 'lightGreen',
        label: '확인',
        onClick: editApi,
      },
      cancel: { onClick: () => setAlert('') },
    });
  };

  const goList = () => {
    if (state.type === 'press') {
      navigate('/admin/press');
    } else if (state.type === 'tour') {
      navigate(`/admin/product/tour/${values.category}`);
    }
  };

  // button active
  const [submitActive, setSubmitActive] = useState(false);
  useEffect(() => {
    if (state.type === 'press' && values) {
      setSubmitActive(
        !isEmptyObjItems({
          ...values,
          isMarked: String(values.isMarked),
        }),
      );
    } else {
      const tempValues = { ...values };
      delete tempValues.category;
      setSubmitActive(!isEmptyObjItems(tempValues));
    }
  }, [values]);

  return (
    values && (
      <>
        <AdminContainer title="수정하기">
          <AdminCard>
            <Input
              label="제목"
              name="title"
              value={values?.title}
              onChange={onChange}
              inputType="underline"
            />
            {state.type === 'press' && (
              <Div width={77} margin="20px 0">
                <AdminCheckbox
                  checked={values?.isMarked === 1 || false}
                  name="isMarked"
                  value={values?.isMarked}
                  onChange={(value, name) => {
                    onChange((value === '0' && 1) || 0, name);
                  }}
                >
                  <Text type="h8" color="g500">
                    상단고정
                  </Text>
                </AdminCheckbox>
              </Div>
            )}
            <Input
              label="URL"
              name="url"
              mt={(state.type === 'tour' && 20) || null}
              value={values?.url}
              onChange={onChange}
              inputType="underline"
            />
            {state.type === 'tour' && (
              <Input
                label="가격"
                name="price"
                mt={20}
                value={values?.price}
                onChange={onChange}
                inputType="underline"
              />
            )}
            <InputFile
              label="썸네일"
              name="attachmentFile"
              value={values?.attachmentFileName || ''}
              fileUploadApi={uploadFile}
              onChange={onChange}
              mt={20}
              inputType="underline"
            />
          </AdminCard>
          <Row mt={10} justifyContent="space-between">
            <Button
              label="목록으로"
              buttonType="capsule"
              onClick={goList}
              type="outline"
              buttonColor="adminBlue"
              width={105}
              active
            />
            <Row gap={10}>
              <Button
                label="취소"
                onClick={() => navigate(-1)}
                buttonType="capsule"
                buttonColor="g500"
                type="outline"
                border={{ width: 0 }}
                width={105}
                active
              />
              <Button
                label="확인"
                disabled={!submitActive}
                onClick={editItem}
                buttonType="capsule"
                buttonColor="lightGreen"
                width={105}
                active={submitActive}
              />
            </Row>
          </Row>
        </AdminContainer>
        {alert && <AdminAlert alert={alert} />}
      </>
    )
  );
}

export default Edit;
