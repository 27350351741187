import {
  Row,
  Div,
  Text,
  Col,
  Button,
  ImageComponent,
  Grid,
  Loading,
} from 'differoute-styles';
import React, { useState, useEffect } from 'react';
import { Line } from 'styles/CustomStyles';
import PopUp from 'components/Modal/PopUp';
import { useCookies } from 'react-cookie';
import goodsLang from 'data/lang/main/goods';
import { userApi } from 'api';
import MainContainer from 'layouts/MainContainer';
import GoodsSlider from './Sliders/Goods/GoodsSlider';
import DetailModal from './Sliders/Goods/DetailModal';
import MSlider from './Sliders/MSlider';

function Goods({ currentIndex }) {
  const [cookies] = useCookies();
  const [loading, setLoading] = useState(true);

  const [filters, setFilters] = useState({
    category: goodsLang[cookies.language].category1.type,
    categoryLabel: goodsLang[cookies.language].category1.name,
    lang: cookies.language,
  });

  // list api
  const [list, setList] = useState();
  const getList = async () => {
    const [info] = await userApi.goodsApi.goods(filters.category, filters.lang);
    if (info && info.resCode === 200) {
      const { result } = info;
      setList(result.list);
      setLoading(false);
    } else {
      setLoading(false);
      console.log('error');
    }
  };

  useEffect(() => {
    getList();

    return () => setLoading(true);
  }, [filters]);

  // all modal
  const allItems = () => {
    setModalOpen('all');
    setSelectedItem(list);
  };

  // detail modal
  const [modalOpen, setModalOpen] = useState();
  const [selectedItem, setSelectedItem] = useState();

  const goDetail = async (item) => {
    setModalOpen('detail');
    getDetail(item.id);
  };

  const [detailItem, setDetailItem] = useState();
  const getDetail = async (id) => {
    const [info] = await userApi.goodsApi.detail(filters.category, id);
    if (info && info.resCode === 200) {
      const { result } = info;
      setDetailItem(result);
      setSelectedItem(list);
      setLoading(false);
    } else {
      setLoading(false);
      console.log('error');
    }
  };
  const [currentSlide, setCurrentSlide] = useState(0);

  return (
    <>
      <Div backgroundColor="lightGreenOpacity40" width="100vw">
        <MainContainer maxWidth={1260} mMaxWidth="100%" mPadding="40px 0">
          <Row
            mFlexDirection="column"
            alignItems="center"
            justifyContent="space-between"
          >
            <Col
              mOverflowX="hidden"
              width="24.58%"
              mWidth="100vw"
              position="relative"
            >
              <Text mTextAlign="center" color="lightGreen" type="h4" mType="h6">
                {goodsLang[cookies.language].title}
              </Text>
              <Line
                display="none"
                mDisplay="block"
                width="calc(100vw - 40px) !important"
                position="absolute"
                top={98}
                left={20}
              />
              <Div width="100%" overflowX="hidden" padding="0 20px">
                <Col
                  gap={30}
                  mGap={60}
                  mb={20}
                  mFlexDirection="row"
                  transition="0.3s ease-in"
                  mTransform={
                    (filters.category === 'jb' && 'translateX(38%)') ||
                    'trnaslateX(-38%)'
                  }
                >
                  <Row
                    mt={10}
                    cursor="pointer"
                    alignItems="center"
                    mFlexDirection="column"
                    justifyContent="space-between"
                    mMinWidth="fit-content"
                    transition="0.3s ease-in"
                  >
                    <Div
                      mPadding="14px 0"
                      onClick={() => {
                        setFilters({
                          ...filters,
                          category: goodsLang[cookies.language].category1.type,
                        });
                      }}
                    >
                      <Text
                        type="h3"
                        mType="h4"
                        color={(filters.category === 'jb' && 'g800') || 'g300'}
                        transition="0.2s ease-in"
                      >
                        {goodsLang[cookies.language].category1.name}
                      </Text>
                    </Div>
                    <Button
                      mDisplay="none"
                      mMt={30}
                      mWidth={90}
                      label={goodsLang[cookies.language].buttonLabel.label}
                      buttonType="capsule"
                      backgroundColor="transparent"
                      active={filters.category === 'jb'}
                      type="outline"
                      buttonColor="lightGreen"
                      disabled={filters.category !== 'jb'}
                      onClick={() => allItems(list)}
                    />
                  </Row>
                  <Line mDisplay="none" />
                  <Row
                    mt={10}
                    cursor="pointer"
                    alignItems="center"
                    mFlexDirection="column"
                    justifyContent="space-between"
                    mMinWidth="fit-content"
                    transition="0.3s ease-in"
                    position="relative"
                  >
                    <Div
                      mPadding="14px 0"
                      onClick={() => {
                        setFilters({
                          ...filters,
                          category: goodsLang[cookies.language].category2.type,
                        });
                      }}
                    >
                      <Text
                        type="h3"
                        mType="h4"
                        color={(filters.category === 'etc' && 'g800') || 'g300'}
                        transition="0.2s ease-in"
                      >
                        {goodsLang[cookies.language].category2.name}
                      </Text>
                    </Div>
                    <Button
                      mDisplay="none"
                      mMt={30}
                      mWidth={90}
                      label={goodsLang[cookies.language].buttonLabel.label}
                      buttonType="capsule"
                      backgroundColor="transparent"
                      active={filters.category === 'etc'}
                      disabled={filters.category !== 'etc'}
                      type="outline"
                      buttonColor="lightGreen"
                      onClick={() => allItems(list)}
                    />
                  </Row>
                </Col>
              </Div>
              <Button
                display="none"
                mDisplay="block"
                margin="auto"
                mWidth={90}
                label={goodsLang[cookies.language].buttonLabel.label}
                buttonType="capsule"
                active
                type="outline"
                buttonColor="lightGreen"
                backgroundColor="transparent"
                onClick={() => allItems(list)}
              />
            </Col>
            <Div
              width="66.67%"
              mWidth="100%"
              position="relative"
              overflowX="hidden"
              mOverflowX="unset"
            >
              <Div mDisplay="none">
                <GoodsSlider
                  list={list}
                  goDetail={goDetail}
                  filters={filters}
                  currentSlide={currentSlide}
                  setCurrentSlide={setCurrentSlide}
                />
              </Div>
              <Div display="none" mDisplay="block" mMt={20}>
                <MSlider
                  type="goods"
                  color="lightGreen"
                  list={list}
                  goDetail={goDetail}
                  category={filters && filters.category}
                  currentSlide={currentSlide}
                  setCurrentSlide={setCurrentSlide}
                />
              </Div>
            </Div>
          </Row>
        </MainContainer>
        {modalOpen && (
          <PopUp
            maxWidth={(modalOpen === 'all' && 1314) || 1180}
            mPadding="0 !important"
            width="100%"
            confirm={{
              onClick: () => {
                setDetailItem('');
                setModalOpen('');
              },
            }}
            modalOpen={modalOpen}
            currentIndex={currentIndex}
          >
            {modalOpen === 'all' && (
              <Div padding="0 17px">
                <Grid
                  cols={5}
                  mCols={2}
                  colGap={20}
                  mColGap={23}
                  rowGap={40}
                  mWidth="100%"
                >
                  {selectedItem.map((item, index) => (
                    <Col
                      key={`item_${index}`}
                      gap={10}
                      cursor="pointer"
                      onClick={() => {
                        setDetailItem('');
                        goDetail(item);
                      }}
                    >
                      <ImageComponent
                        src={item.attachmentFile}
                        width="100%"
                        height={100}
                        objectFit="cover"
                        borderRadius={10}
                      />
                      <Text
                        type="h6"
                        color="white"
                        maxLine={1}
                        wordBreak="break-word"
                      >
                        {item.title}
                      </Text>
                      <Text type="b2" mType="b2" color="white" maxLine={1}>
                        {item.price}
                      </Text>
                    </Col>
                  ))}
                </Grid>
              </Div>
            )}
            {modalOpen === 'detail' && detailItem && (
              <DetailModal
                list={selectedItem}
                detailItem={detailItem}
                setModalOpen={setModalOpen}
                currentSlide={currentSlide}
                setCurrentSlide={setCurrentSlide}
              />
            )}
          </PopUp>
        )}
      </Div>
      <Loading loading={loading} />
    </>
  );
}

export default Goods;
