import React from 'react';
import { Text, Div, Col, ImageComponent } from 'differoute-styles';
import BorderImage from 'components/Common/BorderImage';

function MGoods({ item, index, goDetail }) {
  return (
    <Div key={`item_${index}`} width="100%" onClick={() => goDetail(item)}>
      <Col backgroundColor="white">
        <BorderImage src={item.attachmentFile} mAspectRatio="59/48" />
      </Col>
      <Text type="h6" mType="h6" width="100%" mMt={14}>
        {item.title}
      </Text>
      <Text
        mMt={14}
        type="b2"
        mType="b2"
        width="100%"
        maxLine={2}
        mMinHeight={36}
        color="g500"
      >
        {item.price}
      </Text>
    </Div>
  );
}

export default MGoods;
