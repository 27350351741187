import { Div, Row, Text } from 'differoute-styles';
import React from 'react';
import { useNavigate, Outlet, useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import boardLang from 'data/lang/main/board';

function CommunityOutlet(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [cookies] = useCookies();

  const goToMenu = (menu) => {
    const searchParams = new URLSearchParams(window.location.search);
    const lang = searchParams.get('lang');
    const goUrl = `${menu}?lang=${lang}`;
    navigate(goUrl);
  };
  return (
    <>
      <Div
        maxWidth={1024}
        minHeight="calc(100vh - 463px)"
        mMinHeight="calc(100vh - 452px)"
        padding="120px 30px 60px"
        mPadding="96px 20px 40px"
        margin="auto"
      >
        <Row
          display={(location.pathname.split('/')[3] && 'none') || 'flex'}
          mDisplay={(location.pathname.split('/')[3] && 'none') || 'flex'}
          justifyContent="space-between"
          mFlexDirection="column"
        >
          <Text type="h3" mType="h4">
            {boardLang[cookies.language].community_title}
          </Text>
          <Row
            position="relative"
            height={54}
            mHeight={40}
            mMt={40}
            alignItems="center"
            justifyContent="center"
            width="fit-content"
            mWidth="100%"
          >
            <Div
              position="absolute"
              width="50%"
              height="100%"
              top={0}
              left={0}
              backgroundColor="primary"
              borderRadius={999}
              zIndex={-1}
              transform={
                (location.pathname.includes('announcement') &&
                  `translateX(0)`) ||
                `translate(100%)`
              }
              transition="all 0.3s linear"
            />
            <Div
              width={110}
              mWidth="50%"
              cursor="pointer"
              onClick={() => {
                goToMenu('announcement');
              }}
            >
              <Text
                type="h5"
                mType="h6"
                textAlign="center"
                color={
                  (location.pathname.includes('announcement') && 'white') ||
                  'g300'
                }
                transition="all 0.5s ease-out"
              >
                {boardLang[cookies.language].community_notice}
              </Text>
            </Div>
            <Div
              width={110}
              mWidth="50%"
              cursor="pointer"
              onClick={() => {
                goToMenu('press');
              }}
            >
              <Text
                type="h5"
                mType="h6"
                textAlign="center"
                color={
                  (location.pathname.includes('press') && 'white') || 'g300'
                }
                transition="all 0.5s ease-out"
              >
                {boardLang[cookies.language].community_reference}
              </Text>
            </Div>
          </Row>
        </Row>
        <Outlet />
      </Div>
    </>
  );
}

export default CommunityOutlet;
