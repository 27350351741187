import React from 'react';
import { Row, Div, Text, A, ImageComponent } from 'differoute-styles';
import { Line } from 'styles/CustomStyles';
import MainContainer from 'layouts/MainContainer';
import { useCookies } from 'react-cookie';
import placeLang from 'data/lang/main/place';
import EmailIcon from 'assets/icon/email_icon.png';
import InstagramIcon from 'assets/icon/instagram_icon.png';
import FacebookIcon from 'assets/icon/facebook_icon.png';
import KakaoIcon from 'assets/icon/kakaotalk_icon.png';
import MapComponent from './MapComponent';

function Place(props) {
  const [cookies] = useCookies();

  return (
    <MainContainer maxWidth={1260}>
      <Row
        mGap={20}
        width="100%"
        alignItems="center"
        justifyContent="space-between"
        mFlexDirection="column"
      >
        <Div width="44.58%" mWidth="100%">
          <Text color="blue" type="h4" mType="h6">
            {placeLang[cookies.language].name}
          </Text>
          <Text type="h3" mType="h4">
            {placeLang[cookies.language].place}
          </Text>
          <Div mt={10}>
            <Row>
              <Text type="b1" color="g500">
                -&nbsp;
              </Text>
              <Text type="b1" fontSize={18} mType="b1" color="g500">
                {placeLang[cookies.language].address}
              </Text>
            </Row>
          </Div>
          <Line margin="30px 0" />
          <Text type="h3" mType="h4">
            {placeLang[cookies.language].info}
          </Text>
          <Div mt={10}>
            <Row>
              <Text type="b1" color="g500">
                -&nbsp;
              </Text>
              <Text type="b1" fontSize={18} mType="b1" color="g500">
                {placeLang[cookies.language].hours}
              </Text>
            </Row>
            <Row>
              <Text type="b1" color="g500">
                -&nbsp;
              </Text>
              <Text type="b1" fontSize={18} mType="b1" color="g500">
                Tel. 063) 283-8880 | 070) 4262-9900
              </Text>
            </Row>
            <Row>
              <Text type="b1" color="g500">
                -&nbsp;
              </Text>
              <Text type="b1" fontSize={18} mType="b1" color="g500">
                Fax. 063) 283-8881
              </Text>
            </Row>
          </Div>
          <Line margin="30px 0" />
          <Text type="h3" mType="h4">
            {placeLang[cookies.language].channelName}
          </Text>
          <Div mt={10}>
            <Row>
              <Row>
                <Text type="b1" color="g500">
                  -&nbsp;
                </Text>
                <Text
                  type="b1"
                  fontSize={18}
                  mType="b1"
                  color="g500"
                  mWhiteSpace="pre"
                >
                  {placeLang[cookies.language].channelEmail}&nbsp;
                </Text>
              </Row>
              <Text type="b1" fontSize={18} mType="b1" color="g500">
                <A href="mailto:shoppinglounge@tothepp.com">
                  shoppinglounge@tothepp.com
                </A>
              </Text>
            </Row>
            <Row mFlexDirection="column">
              <Row>
                <Text type="b1" color="g500">
                  -&nbsp;
                </Text>
                <Text
                  type="b1"
                  fontSize={18}
                  mType="b1"
                  color="g500"
                  mWhiteSpace="pre"
                >
                  {placeLang[cookies.language].channelInstagram}&nbsp;
                </Text>
              </Row>
              <Text type="b1" fontSize={18} mType="b1" color="g500">
                <ImageComponent
                  src={InstagramIcon}
                  width={25}
                  onClick={() =>
                    window.open(
                      'https://www.instagram.com/shopping_travel_lounge',
                    )
                  }
                />
              </Text>
            </Row>
            <Row mFlexDirection="column">
              <Row>
                <Text type="b1" color="g500">
                  -&nbsp;
                </Text>
                <Text
                  type="b1"
                  fontSize={18}
                  mType="b1"
                  color="g500"
                  mWhiteSpace="pre"
                >
                  {placeLang[cookies.language].channelFacebook}&nbsp;
                </Text>
              </Row>
              <Text type="b1" fontSize={18} mType="b1" color="g500">
                <ImageComponent
                  src={FacebookIcon}
                  width={25}
                  onClick={() =>
                    window.open('https://www.facebook.com/shoppingtravellounge')
                  }
                />
              </Text>
            </Row>
            <Row mFlexDirection="column">
              <Row>
                <Text type="b1" color="g500">
                  -&nbsp;
                </Text>
                <Text
                  type="b1"
                  fontSize={18}
                  mType="b1"
                  color="g500"
                  mWhiteSpace="pre"
                >
                  {placeLang[cookies.language].channelKakao}&nbsp;
                </Text>
              </Row>
              <Text type="b1" fontSize={18} mType="b1" color="g500">
                <ImageComponent
                  src={KakaoIcon}
                  width={25}
                  onClick={() => window.open('https://pf.kakao.com/_MTBaxj')}
                />
              </Text>
            </Row>
          </Div>
        </Div>
        <Div width="44.58%" mWidth="100%" height={505} mHeight={350}>
          <MapComponent />
        </Div>
      </Row>
    </MainContainer>
  );
}

export default Place;
