import { Row, Div, Text, Col, ImageComponent, Span } from 'differoute-styles';
import React, { useState } from 'react';
import useTabs from 'hooks/useTabs';
import { Line } from 'styles/CustomStyles';
import { useCookies } from 'react-cookie';
import PopUp from 'components/Modal/PopUp';
import { serviceLang, serviceTab } from 'data/lang/main/service';
import MainContainer from 'layouts/MainContainer';
import MSlider from './Sliders/MSlider';
import ServiceSlider from './Sliders/ServiceSlider';

function Service({ currentIndex }) {
  const [cookies] = useCookies();
  const tabTitle = serviceTab[cookies.language];
  const contentList = [
    { tab: 1, content: serviceLang.concierge[cookies.language] },
    { tab: 2, content: serviceLang.lounge[cookies.language] },
  ];

  const detailBtnLabel = serviceLang.concierge[cookies.language][0].linkText;

  const { contentItem, contentChange } = useTabs(0, contentList);

  // detail modal
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const goDetail = (item) => {
    setModalOpen(true);
    setSelectedItem(item);
  };

  return (
    <Div backgroundColor="blueOpacity40" width="100vw">
      <MainContainer maxWidth={1260} mMaxWidth="100%" mPadding="40px 0">
        <Row
          width="100%"
          height="100%"
          alignItems="center"
          justifyContent="space-between"
          mFlexDirection="column"
        >
          <Div mWidth="100%" mBorderBottom={{ color: 'g200' }}>
            <Text
              color="blue"
              mTextAlign="center"
              mType="h6"
              type="h4"
              mb={10}
              mMb={0}
            >
              {tabTitle.title}
            </Text>
            <Div width="100%" overflowX="hidden">
              <Col
                gap={30}
                mPadding="14px 0"
                mFlexDirection="row"
                mFlexWrap="nowrap"
                transition="0.3s ease-in"
                mTransform={
                  (contentItem.tab === 1 && 'translateX(22%)') ||
                  'translateX(-38%)'
                }
              >
                <Div
                  minWidth="100%"
                  mMinWidth="fit-content"
                  cursor="pointer"
                  onClick={() => {
                    contentChange(0);
                  }}
                >
                  <Text
                    type="h3"
                    mType="h4"
                    color={(contentItem.tab === 1 && 'g800') || 'g300'}
                    transition="0.3s ease-in"
                    whiteSpace="nowrap"
                    mTextAlign="center"
                  >
                    {tabTitle.category1}
                  </Text>
                </Div>
                <Line mDisplay="none" />
                <Div
                  minWidth="100%"
                  mMinWidth="fit-content"
                  cursor="pointer"
                  onClick={() => {
                    contentChange(1);
                  }}
                >
                  <Text
                    type="h3"
                    mType="h4"
                    color={(contentItem.tab === 2 && 'g800') || 'g300'}
                    transition="0.3s ease-in"
                    whiteSpace="nowrap"
                    mTextAlign="center"
                  >
                    {tabTitle.category2}
                  </Text>
                </Div>
              </Col>
            </Div>
          </Div>
          <Line display="none" mDisplay="block" mb={40} />
          <Div width="66.67%" mWidth="100%" position="relative" mMt={30}>
            <Div mDisplay="none">
              <ServiceSlider
                contentItem={contentItem}
                list={contentItem.content}
                goDetail={goDetail}
                currentIndex={currentIndex}
              />
            </Div>
            <Div display="none" mDisplay="block">
              <MSlider
                type="service"
                contentItem={contentItem}
                list={contentItem.content}
                goDetail={goDetail}
                currentIndex={currentIndex}
                color="blue"
              />
            </Div>
          </Div>
        </Row>
      </MainContainer>
      {modalOpen && (
        <PopUp
          maxWidth={1190}
          width="100%"
          confirm={{ onClick: () => setModalOpen(false) }}
          link={
            selectedItem.link && {
              label: detailBtnLabel,
              onClick: () => window.open(`${selectedItem.link}`),
            }
          }
        >
          <Row gap={30} mGap={20} mFlexDirection="column" mPadding="0 20px">
            <ImageComponent
              src={selectedItem.img}
              width="50%"
              mWidth="100%"
              borderRadius={10}
              flex={5}
            />
            <Div flex={5}>
              <Text type="h4" mType="h5" color="white">
                {selectedItem.title}
              </Text>
              <Row mt={15}>
                <Text type="h5" mType="h6" color="white">
                  "
                </Text>
                <Text type="h5" mType="h6" color="white">
                  {selectedItem.innerSubTitle &&
                    String(selectedItem.innerSubTitle)
                      .split('\n')
                      .map((txt, idx) => (
                        <Span key={`tkey_${idx}`}>
                          {txt}
                          <br />
                        </Span>
                      ))}
                </Text>
              </Row>
              <Div mt={20} mMt={16}>
                {selectedItem.desc &&
                  selectedItem.desc.map((desc, idx) => (
                    <Row key={`desc_${idx}`}>
                      <Text type="b1" mType="b3" color="white">
                        -&nbsp;
                      </Text>
                      <Div>
                        <Text
                          type="b1"
                          mType="b3"
                          color="white"
                          whiteSpace="pre-wrap"
                        >
                          {desc}
                        </Text>
                      </Div>
                    </Row>
                  ))}
              </Div>
            </Div>
          </Row>
        </PopUp>
      )}
    </Div>
  );
}

export default Service;
