const tour = {
  ko: {
    title: '투어',
    category1: { name: '당일 여행', type: 'daytrip' },
    category2: { name: '숙박 여행', type: 'lodge' },
    price: '원 / 1인',
    buttonLabel: '상품 전체보기',
  },
  en: {
    title: 'Tour Information',
    category1: { name: 'One day tour', type: 'daytrip' },
    category2: { name: 'Overnight tour', type: 'lodge' },
    price: 'won / per person',
    buttonLabel: 'View all',
  },
  cn: {
    title: '旅游',
    category1: { name: '一日游', type: 'daytrip' },
    category2: { name: '过夜旅游', type: 'lodge' },
    price: '韩币 / 1人',
    buttonLabel: '查看全部',
  },
  jp: {
    title: 'ツアー紹介',
    category1: { name: '日帰り旅行', type: 'daytrip' },
    category2: { name: '宿泊旅行', type: 'lodge' },
    price: 'ウォン / 一人',
    buttonLabel: '詳しくはこちら',
  },
};

export default tour;
