import React, { useState, useEffect } from 'react';
import { adminApi } from 'api';
import AdminAlert from 'components/Admin/AdminAlert';
import { useParams, useNavigate } from 'react-router-dom';
import DetailPresenter from './DetailPresenter';

function DetailContainer(props) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [alert, setAlert] = useState();
  const [loading, setLoading] = useState(true);

  // get detail id
  const [questionItem, setQuestionItem] = useState();
  const [answerItem, setAnswerItem] = useState();
  const getList = async () => {
    const [info, error] = await adminApi.questionApi.qna.detail(id);
    if (info && info.resCode === 200) {
      setQuestionItem(info.result.q);
      setAnswerItem(info.result.a);
      setLoading(false);
    } else if (error.resCode === 401) {
      sessionStorage.removeItem('adminTokenKey');
      setLoading(false);
      navigate('/admin/login');
    } else {
      setLoading(false);
      console.log('error');
    }
  };

  useEffect(() => {
    getList();
    setLoading(false);
  }, []);

  const onChange = (value, name) => {
    setAnswerItem({ ...answerItem, [name]: value });
  };

  const deleteItem = () => {
    setAlert({
      title: '알림',
      content: '삭제하시겠습니까?',
      customButton: {
        buttonColor: 'lightGreen',
        borderRadius: '50rem',
        color: 'white',
        backgroundColor: 'lightGreen',
        label: '확인',
        onClick: deleteApi,
      },
      cancel: { onClick: () => setAlert('') },
    });
  };

  const deleteApi = async () => {
    setLoading(true);
    const [info, error] = await adminApi.questionApi.qna.delete(
      questionItem.id,
    );
    if (info && info.resCode === 200) {
      setLoading(false);
      setAlert({
        title: '알림',
        content: '삭제가 완료되었습니다.',
        customButton: {
          buttonColor: 'lightGreen',
          borderRadius: '50rem',
          color: 'white',
          backgroundColor: 'lightGreen',
          label: '확인',
          onClick: () => {
            setAlert('');
            navigate(-1);
          },
        },
      });
    } else if (error.resCode === 401) {
      sessionStorage.removeItem('adminTokenKey');
      setLoading(false);
      navigate('/admin/login');
    } else {
      setLoading(false);
      console.log('error');
    }
  };

  const submit = async () => {
    const params = { qId: questionItem.id, content: answerItem.content };
    const [info, error] = await adminApi.questionApi.qna.answer(params);
    if (info && info.resCode === 200) {
      setLoading(false);
      setAlert({
        title: '알림',
        content: '수정 완료되었습니다.',
        customButton: {
          buttonColor: 'lightGreen',
          borderRadius: '50rem',
          color: 'white',
          backgroundColor: 'lightGreen',
          label: '확인',
          onClick: () => navigate(-1),
        },
      });
    } else if (error.resCode === 401) {
      sessionStorage.removeItem('adminTokenKey');
      setLoading(false);
      navigate('/admin/login');
    } else {
      setLoading(false);
      console.log('error');
    }
  };

  const editItem = () => {
    setAlert({
      title: '알림',
      content: '수정하시겠습니까?',
      customButton: {
        buttonColor: 'lightGreen',
        borderRadius: '50rem',
        color: 'white',
        backgroundColor: 'lightGreen',
        label: '확인',
        onClick: submit,
      },
      cancel: { onClick: () => setAlert('') },
    });
  };

  return (
    <>
      <DetailPresenter
        navigate={navigate}
        deleteItem={deleteItem}
        questionItem={questionItem}
        answerItem={answerItem}
        onChange={onChange}
        editItem={editItem}
      />
      {alert && <AdminAlert alert={alert} />}
    </>
  );
}

export default DetailContainer;
