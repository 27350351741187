import mainImg1 from 'assets/graphic/main/main_1.jpg';
import mainImg2 from 'assets/graphic/main/main_2.jpg';
import mainImg3 from 'assets/graphic/main/main_3.jpg';

const visual = [
  {
    id: 1,
    img: mainImg1,
    title: {
      ko: { bold: `SHOPPING TRAVEL`, normal: `LOUNGE` },
      en: { bold: `SHOPPING TRAVEL`, normal: `LOUNGE` },
      cn: { bold: `SHOPPING TRAVEL`, normal: `LOUNGE` },
      jp: { bold: `SHOPPING TRAVEL`, normal: `LOUNGE` },
    },
    desc: {
      ko: `전북 쇼핑트래블 라운지`,
      en: `Jeonbuk Shopping Travel Lounge`,
      cn: `全北购物旅游休息室`,
      jp: `全北ショッピングトラベルラウンジ`,
    },
  },
  {
    id: 2,
    img: mainImg2,
    title: {
      ko: { bold: `SHOPPING TRAVEL`, normal: `LOUNGE` },
      en: { bold: `SHOPPING TRAVEL`, normal: `LOUNGE` },
      cn: { bold: `SHOPPING TRAVEL`, normal: `LOUNGE` },
      jp: { bold: `SHOPPING TRAVEL`, normal: `LOUNGE` },
    },
    desc: {
      ko: `전북 쇼핑트래블 라운지`,
      en: `Jeonbuk Shopping Travel Lounge`,
      cn: `全北购物旅游休息室`,
      jp: `全北ショッピングトラベルラウンジ`,
    },
  },
  {
    id: 3,
    img: mainImg3,
    title: {
      ko: { bold: `SHOPPING TRAVEL`, normal: `LOUNGE` },
      en: { bold: `SHOPPING TRAVEL`, normal: `LOUNGE` },
      cn: { bold: `SHOPPING TRAVEL`, normal: `LOUNGE` },
      jp: { bold: `SHOPPING TRAVEL`, normal: `LOUNGE` },
    },
    desc: {
      ko: `전북 쇼핑트래블 라운지`,
      en: `Jeonbuk Shopping Travel Lounge`,
      cn: `全北购物旅游休息室`,
      jp: `全北ショッピングトラベルラウンジ`,
    },
  },
];

export default visual;
