const goods = {
  ko: {
    title: '판매 상품',
    category1: { name: '전북 굿즈', type: 'jb' },
    category2: { name: '기타 굿즈', type: 'etc' },
    buttonLabel: { label: '한 눈에 보기' },
    closeButtonLabel: { label: '닫기' },
    cancelButtonLabel: { label: '취소' },
    closeDayButtonLabel: { label: '오늘 하루 열지 않음' },
  },
  en: {
    title: 'Product',
    category1: { name: 'Jeonbuk goods', type: 'jb' },
    category2: { name: 'Other goods', type: 'etc' },
    buttonLabel: { label: 'View all' },
    closeButtonLabel: { label: 'Close' },
    cancelButtonLabel: { label: 'Cancel' },
    closeDayButtonLabel: { label: "Don't open today" },
  },
  cn: {
    title: '销售商品',
    category1: { name: '全北商品', type: 'jb' },
    category2: { name: '其他商品', type: 'etc' },
    buttonLabel: { label: '查看全部' },
    closeButtonLabel: { label: '取消' },
    cancelButtonLabel: { label: 'Cancel' },
    closeDayButtonLabel: { label: '今天一天不打开' },
  },
  jp: {
    title: '販売商品',
    category1: { name: '全北グッズ', type: 'jb' },
    category2: { name: 'その他のグッズ', type: 'etc' },
    buttonLabel: { label: '一目で見る' },
    closeButtonLabel: { label: '閉じる' },
    cancelButtonLabel: { label: 'Cancel' },
    closeDayButtonLabel: { label: '今日一日開かず' },
  },
};

export default goods;
