import React, { useState, useEffect, useRef } from 'react';
import { Row, Div } from 'differoute-styles';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import IconDiv from 'components/Common/IconDiv';
import MAbout2 from './MAbout2';
import MService from './MService';
import MTour from './MTour';
import MGoods from './MGoods';

function MSlider({
  list,
  goDetail,
  color,
  type,
  contentItem,
  cookies,
  category,
}) {
  const swiperRef = useRef();
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    if (contentItem?.tab && swiperRef.current) {
      swiperRef.current.swiper.slideTo(0);
    }
  }, [contentItem?.tab]);

  useEffect(() => {
    if (category && swiperRef.current) {
      swiperRef.current.swiper.slideTo(0);
    }
  }, [category]);

  return (
    list && (
      <Swiper
        ref={swiperRef}
        speed={500}
        modules={[Navigation]}
        className="mySwiper"
        onSlideChange={(e) => setCurrentSlide(e.realIndex)}
        spaceBetween={20}
        centeredSlides
        slidesPerView={(type === 'tour' && 1) || 1.2}
        navigation={{
          prevEl: '.prev',
          nextEl: '.next',
        }}
      >
        {list.map((item, index) => (
          <SwiperSlide key={`item${index}`}>
            <Div key={`item_${index}`} margin="auto">
              {type === 'about2' && <MAbout2 item={item} goDetail={goDetail} />}
              {type === 'service' && (
                <MService item={item} index={index} goDetail={goDetail} />
              )}
              {type === 'tour' && (
                <MTour item={item} index={index} cookies={cookies} />
              )}
              {type === 'goods' && (
                <MGoods item={item} index={index} goDetail={goDetail} />
              )}
            </Div>
          </SwiperSlide>
        ))}
        <Row
          gap={10}
          mMt={20}
          justifyContent="space-between"
          mPadding={(type === 'tour' && '0') || '0 12px'}
        >
          <IconDiv
            className="prev"
            name="arrow"
            pathFill={(currentSlide === 0 && 'g200') || color}
            hover
          />
          <IconDiv
            className="next"
            name="arrow"
            pathFill={(currentSlide + 1 === list.length && 'g200') || color}
            transform="rotate(180deg)"
            hover
          />
        </Row>
      </Swiper>
    )
  );
}

export default MSlider;
