import React, { useState, useEffect } from 'react';
import { Row, Text, Col } from 'differoute-styles';
import { SlideLeftCol } from 'styles/Keyframes';
import tourLang from 'data/lang/main/tour';
import { Line, HoverImage } from 'styles/CustomStyles';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import IconDiv from 'components/Common/IconDiv';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

function TourSlider({
  mobile,
  list,
  currentIndex,
  cookies,
  filters,
  setFilters,
}) {
  const next = () => {
    if (!mobile) {
      setFilters({
        ...filters,
        category: tourLang[cookies.language].category2.type,
        categoryLabel: tourLang[cookies.language].category2.name,
      });
    }
  };

  const prev = () => {
    if (!mobile) {
      setFilters({
        ...filters,
        category: tourLang[cookies.language].category1.type,
        categoryLabel: tourLang[cookies.language].category1.name,
      });
    }
  };

  const [isView, setIsView] = useState(false);
  useEffect(() => {
    if (list && currentIndex === 5) {
      setIsView(true);
      setTimeout(() => {
        setIsView(false);
      }, [2000]);
    }
  }, [list, currentIndex]);

  return (
    <>
      <Row justifyContent="space-between" mDisplay="none">
        <IconDiv
          name="arrow"
          onClick={prev}
          pathFill={
            (filters.categoryLabel ===
              tourLang[cookies.language].category1.name &&
              'g200') ||
            'primary'
          }
          hover
        />
        <Text type="h3" mType="h4" margin="10px 0 20px" textAlign="center">
          {filters.categoryLabel}
        </Text>
        <IconDiv
          name="arrow"
          onClick={next}
          transform="rotate(180deg)"
          pathFill={
            (filters.categoryLabel ===
              tourLang[cookies.language].category2.name &&
              'g200') ||
            'primary'
          }
          hover
        />
      </Row>
      <Line mb={40} />
      {list && (
        <Swiper
          effect="fade"
          speed={2000}
          spaceBetween={20}
          modules={[Navigation]}
          className="mySwiper"
          slidesPerView={list.length}
          style={{ padding: '0 20px' }}
        >
          {list.map((item, index) => (
            <SwiperSlide key={`con_${index}`}>
              <SlideLeftCol
                type="tour"
                className="SlideLeft"
                index={index}
                cursor="pointer"
                onClick={() => window.open(`${item.url}`)}
                slide={
                  (currentIndex && currentIndex === 5 && isView && 'true') ||
                  'false'
                }
              >
                <Col minHeight={443} justifyContent="center">
                  <HoverImage
                    src={item.attachmentFile}
                    width="100%"
                    borderRadius={10}
                  />
                  <Text mt={20} type="h5" textAlign="center">
                    {item.price
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    {tourLang[cookies.language].price}
                  </Text>
                </Col>
              </SlideLeftCol>
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </>
  );
}

export default TourSlider;
