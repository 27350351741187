import Footer from 'components/Common/Footer';
import About from 'components/Main/About';
import About2 from 'components/Main/About2';
import Goods from 'components/Main/Goods';
import Place from 'components/Main/Place/Place';
import Service from 'components/Main/Service';
import VisualSlider from 'components/Main/Sliders/VisualSlider';
import Tour from 'components/Main/Tour';
import DayModal from 'components/DayModal';
import React from 'react';

function MainPresenter({ popupList, setPopupList, currentPage }) {
  return (
    <>
      <VisualSlider />
      <About currentIndex={currentPage.index} />
      <About2 currentIndex={currentPage.index} />
      <Service currentIndex={currentPage.index} />
      <Tour currentIndex={currentPage.index} />
      <Goods currentIndex={currentPage.index} />
      <Place currentIndex={currentPage.index} />
      <Footer />
      {popupList.map(
        (popup, idx) =>
          popup &&
          popup.open && (
            <DayModal
              key={`key_${popup.id}`}
              popup={popup}
              popupList={popupList}
              setPopupList={setPopupList}
              idx={idx}
            />
          ),
      )}
    </>
  );
}

export default MainPresenter;
