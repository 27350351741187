import AdminCard from 'components/Admin/AdminCard';
import { Row, Input, Text, InputFile, Button, Div } from 'differoute-styles';
import { languageList, tourCategory } from 'data/admin';
import AdminContainer from 'layouts/AdminContainer';
import React, { useState, useEffect } from 'react';
import AdminDropDown from 'components/Admin/AdminDropdown';
import AdminCheckbox from 'components/Admin/AdminCheckbox';
import { useLocation, useNavigate } from 'react-router-dom';
import { adminApi } from 'api';
import { isEmptyObjItems } from 'utils/checkData';

function Write(props) {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [values, setValues] = useState();

  useEffect(() => {
    if (state && state === 'press') {
      setValues({
        title: '',
        url: '',
        isMarked: 0,
        attachmentFile: '',
        attachmentFileName: '',
      });
    } else if (state === 'tour') {
      setValues({
        title: '',
        price: '',
        lang: '',
        url: '',
        category: '',
        attachmentFile: '',
        attachmentFileName: '',
      });
    }
  }, []);

  const onChange = (value, name, fileName) => {
    if (name === 'attachmentFile' && !value) {
      setValues({
        ...values,
        attachmentFile: '',
        attachmentFileName: '',
      });
    } else if (fileName) {
      setValues({
        ...values,
        [name]: value.fileUrl,
        attachmentFileName: fileName,
      });
    } else if (name === 'lang' || name === 'category') {
      setValues({ ...values, [name]: value.value });
    } else {
      setValues({ ...values, [name]: value });
    }
  };

  // img upload api
  const uploadFile = async (file) => {
    if (state === 'press') {
      const [info] = await adminApi.communityApi.news.fileUpload(file);
      return info ? info.result : false;
    } else if (state === 'tour') {
      const [info] = await adminApi.ProductApi.tour.fileUpload(
        values.category,
        file,
      );
      return info ? info.result : false;
    }
  };

  // submit api
  const [loading, setLoading] = useState(false);
  const submit = async () => {
    setLoading(true);

    if (state === 'press') {
      // 보도자료
      const [info, error] = await adminApi.communityApi.news.write(values);
      if (info && info.resCode === 200) {
        setLoading(false);
        navigate(-1);
      } else if (error.resCode === 401) {
        sessionStorage.removeItem('adminTokenKey');
        setLoading(false);
        navigate('/admin/login');
      } else {
        setLoading(false);
        console.log('error');
      }
    } else if (state === 'tour') {
      // 투어
      const [info, error] = await adminApi.ProductApi.tour.write(
        values.category,
        values,
      );
      if (info && info.resCode === 200) {
        setLoading(false);
        navigate(-1);
      } else if (error.resCode === 401) {
        sessionStorage.removeItem('adminTokenKey');
        setLoading(false);
        navigate('/admin/login');
      } else {
        setLoading(false);
        console.log('error');
      }
    }
  };
  const [submitActive, setSubmitActive] = useState(false);

  useEffect(() => {
    if (state === 'press' && values) {
      setSubmitActive(
        !isEmptyObjItems({
          ...values,
          isMarked: String(values.isMarked),
        }),
      );
    } else {
      setSubmitActive(!isEmptyObjItems({ ...values }));
    }
  }, [values]);

  return (
    values && (
      <AdminContainer title="새 글 등록하기">
        <AdminCard>
          <Row gap={10}>
            <Div>
              <AdminDropDown
                label="언어"
                placeholder="언어 선택"
                name="lang"
                list={languageList}
                customItem={(el) => el?.label}
                value={languageList?.find((el) => el?.value === values?.lang)}
                inputType="underline"
                onChange={onChange}
              />
            </Div>
            {state === 'tour' && (
              <Div>
                <AdminDropDown
                  label="타입"
                  placeholder="타입 선택"
                  name="category"
                  list={tourCategory}
                  customItem={(el) => el?.label}
                  value={tourCategory?.find(
                    (el) => el?.value === values.category,
                  )}
                  inputType="underline"
                  onChange={onChange}
                />
              </Div>
            )}
            <Input
              label="제목"
              name="title"
              placeholder="제목을 입력하세요"
              value={values.title}
              onChange={onChange}
              inputType="underline"
              width="100%"
            />
          </Row>
          {state === 'press' && (
            <Div width={77} margin="20px 0">
              <AdminCheckbox
                checked={values?.isMarked === 1}
                name="isMarked"
                value={values?.isMarked}
                onChange={(value, name) => {
                  onChange((value === '0' && 1) || 0, name);
                }}
              >
                <Text type="h8" color="g500">
                  상단고정
                </Text>
              </AdminCheckbox>
            </Div>
          )}
          <Input
            label="URL"
            name="url"
            value={values?.url}
            mt={(state && 20) || null}
            onChange={onChange}
            inputType="underline"
          />
          {state === 'tour' && (
            <Input
              label="가격"
              name="price"
              value={values?.price}
              mt={20}
              onChange={onChange}
              inputType="underline"
            />
          )}
          <InputFile
            label="썸네일"
            value={values?.attachmentFileName || ''}
            name="attachmentFile"
            onChange={onChange}
            placeholder="해당 파일을 첨부하세요"
            fileUploadApi={uploadFile}
            mt={20}
            inputType="underline"
          />
        </AdminCard>
        <Row mt={10} justifyContent="space-between">
          <Button
            label="목록으로"
            onClick={() => navigate(-1)}
            buttonType="capsule"
            type="outline"
            buttonColor="adminBlue"
            width={105}
            active
          />
          <Row gap={10}>
            <Button
              label="취소"
              onClick={() => navigate(-1)}
              buttonType="capsule"
              buttonColor="g500"
              type="outline"
              border={{ width: 0 }}
              width={105}
              active
            />
            <Button
              label="확인"
              onClick={submit}
              buttonType="capsule"
              buttonColor="lightGreen"
              width={105}
              disabled={!submitActive}
              active={submitActive}
            />
          </Row>
        </Row>
      </AdminContainer>
    )
  );
}

export default Write;
