import { Row } from 'differoute-styles';
import styled, { css } from 'styled-components';

const CheckboxStyles = styled(Row)`
  & > .hidden-checkbox {
    border: 0;
    clip: rect(0 0 0 0);
    height: 0px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 0px;
  }

  & > .styled-checkbox {
    transition: all 150ms ease;
    position: relative;
    width: 18px;
    height: 18px;
    background-color: ${({ checked, theme, type }) =>
      (type && 'transparent') || theme.colors[(checked && 'b100') || 'g100']};
    border-radius: 6px;
    border: ${({ type, checked, theme }) =>
      type && checked
        ? `1px solid ${theme.colors.primary}`
        : type && !checked
        ? `1px solid ${theme.colors.g300}`
        : null};

    & > svg {
      content: ' ';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      transform: translate(-50%, -50%);

      fill: ${({ checked, theme }) =>
        theme.colors[(checked && 'primary') || 'g500']};
    }
  }
`;

export default CheckboxStyles;
