import { Div, Text, Row, Button } from 'differoute-styles';
import AdminContainer from 'layouts/AdminContainer';
import React from 'react';
import AdminTableWrap from 'components/Admin/AdminWrap';
import AdminTable from 'components/Admin/AdminTable';
import moment from 'moment';
import AdminDropDown from 'components/Admin/AdminDropdown';
import AdminPagination from 'components/Admin/AdminPagination';

function FaqPresenter({
  navigate,
  list,
  pages,
  filters,
  setFilters,
  languageList,
  goDetail,
}) {
  return (
    <AdminContainer title="자주 묻는 질문">
      <Row justifyContent="space-between" alignItems="center">
        <AdminDropDown
          name="lang"
          list={languageList}
          customItem={(el) => el?.label}
          value={languageList?.find((el) => el?.value === filters.lang)}
          inputType="underline"
          onChange={(value) => {
            setFilters({
              ...filters,
              perPage: 10,
              page: 1,
              lastRowNum: 0,
              lang: value.value,
            });
          }}
        />
        <Button
          label="새 글 등록"
          onClick={() => navigate('write', { state: 'faq' })}
          buttonType="capsule"
          buttonColor="lightGreen"
          width={105}
          height={37}
          active
        />
      </Row>
      <AdminTableWrap view={pages.totalCount} mt={10}>
        <AdminTable
          list={list}
          keys={['rowNum', 'question', 'createdDt']}
          onClick={(item) => goDetail(item)}
          headers={{
            rowNum: '번호',
            question: '제목',
            createdDt: '등록일',
          }}
          headerStyles={{
            height: '45px',
            backgroundColor: 'rgba(0, 181, 239, 0.04)',
          }}
          headerTextStyles={{
            rowNum: { color: 'g600' },
            question: { color: 'g600' },
            createdDt: { color: 'g600' },
          }}
          rowStyles={(index) => ({
            height: '45px',
            backgroundColor: (index % 2 === 1 && 'g100') || 'white',
          })}
          maxWidth={{ rowNum: '6.45%', title: '80%', createdDt: '13.55%' }}
          flex={{
            rowNum: 0.645,
            title: 8.365,
            createdDt: 0.99,
          }}
          customElements={{
            question: (item) => (
              <Text type="b2" mType="b3" color="g600" width="100%">
                {item.question}
              </Text>
            ),
            createdDt: (item) => (
              <Text type="b2" mType="b3" color="g600">
                {moment(item.createdDt).format('YYYY-MM-DD')}
              </Text>
            ),
          }}
        />
        {pages.totalCount !== 0 && (
          <Div margin="40px auto 0" maxWidth={200}>
            <AdminPagination
              pages={pages}
              filters={filters}
              setFilters={setFilters}
            />
          </Div>
        )}
      </AdminTableWrap>
    </AdminContainer>
  );
}

export default FaqPresenter;
