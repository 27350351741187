import AdminCard from 'components/Admin/AdminCard';
import IconDiv from 'components/Common/IconDiv';
import { Button, Div, Row, Text } from 'differoute-styles';
import AdminContainer from 'layouts/AdminContainer';
import moment from 'moment';
import React from 'react';

function DetailPresenter({ navigate, deleteItem, item, goEdit }) {
  return (
    item && (
      <AdminContainer title="상세보기">
        <AdminCard padding="30px">
          <Text color="g600" type="h5">
            {item.title}
          </Text>
          <Row margin="10px 0 30px" gap={4}>
            {item.isMarked === 1 && (
              <>
                <IconDiv name="adminPin" padding="0 4.5px" />{' '}
                <Text type="h8" color="g500">
                  상단 고정
                </Text>
                <Text type="b2" color="g400">
                  ·
                </Text>
              </>
            )}
            <Text type="h8" color="g500">
              {item.lang === 'ko'
                ? '한국어'
                : item.lang === 'en'
                ? '영어'
                : item.lang === 'cn'
                ? '중국어'
                : '일본어'}
            </Text>
            <Text type="b2" color="g400">
              ·
            </Text>
            <Text type="b2" color="g400">
              {moment(item.createdDt).format('YYYY-MM-DD')}
            </Text>
          </Row>
          <Div borderTop={{ color: 'g200' }} padding="20px">
            <Div
              dangerouslySetInnerHTML={{ __html: item.content }}
              width="100%"
            ></Div>
          </Div>
        </AdminCard>
        <Row mt={10} justifyContent="space-between">
          <Button
            label="목록으로"
            buttonType="capsule"
            type="outline"
            buttonColor="adminBlue"
            width={105}
            onClick={() => navigate(-1)}
            // onClick={() => navigate('/admin/community/notice')}
            active
          />
          <Row gap={10}>
            <Button
              label="삭제"
              buttonType="capsule"
              onClick={deleteItem}
              type="outline"
              buttonColor="adminRed"
              width={105}
              active
            />
            <Button
              label="수정"
              onClick={() => goEdit(item)}
              buttonType="capsule"
              buttonColor="lightGreen"
              width={105}
              active
            />
          </Row>
        </Row>
      </AdminContainer>
    )
  );
}

export default DetailPresenter;
